define("ember-svg-jar/inlined/safari-pinned-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M71.6 1.5C37.3 8.4 11.7 32.4 2.8 66L.5 74.5v551l2.3 8.5c8.3 31.5 31.7 54.9 63.2 63.2l8.5 2.3h551l8.5-2.3c31.5-8.3 54.9-31.7 63.2-63.2l2.3-8.5v-551l-2.3-8.5c-4.3-16.3-12.5-30.3-24.3-41.6-11.2-10.6-23.1-17.3-38.4-21.6l-8-2.3-274-.2C124 .2 77.4.4 71.6 1.5zM284 237.4c20.8 2 42 12.4 57.8 28.4l8.2 8.3 8.3-8.3c16.7-17 36.8-26.4 61.2-28.7 5-.5 9.2-.7 9.4-.5.4.4-8.6 42.3-9.6 45.1-.4 1 4.8 1.3 24 1.3 26.6 0 29.6.5 33.6 5.6 4.4 5.6 4.6 8.4 2.2 37.1-1.2 14.7-2.5 27.1-2.7 27.5-.3.4-2.3.8-4.5.8H468v43.2c0 28.1-.4 44.7-1.1 47.3-1.4 5-4.2 8.2-9.4 10.6-3.8 1.8-9 1.9-107.5 1.9s-103.7-.1-107.5-1.9c-5.2-2.4-8-5.6-9.4-10.6-.7-2.6-1.1-19.2-1.1-47.3V354h-3.9c-2.2 0-4.2-.4-4.5-.8-.2-.4-1.5-12.8-2.7-27.5-2.4-28.7-2.2-31.5 2.2-37.1 4-5.1 7-5.6 33.6-5.6 22.8 0 24.4-.1 23.9-1.8-1-3.4-9.6-42.6-9.6-43.9 0-.9 1.1-1.2 3.8-.9 2 .3 6.2.8 9.2 1z\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "933.333",
      "height": "933.333",
      "viewBox": "0 0 700 700"
    }
  };
});