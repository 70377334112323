define("@additive-apps/ui/utils/date-util", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseDate = void 0;
  /**
   * parses a date to a valid date-fns date object
   *
   * @function parseDate
   * @param {String|Date} date
   * @return {Date} parsed date in the current time zone
   */
  const parseDate = date => {
    if ((0, _dateFns.isValid)(date)) {
      return date;
    } else if (typeof date === 'string') {
      return (0, _dateFns.parseISO)(date);
    } else if (typeof date === 'object' && date.toISOString) {
      return parseDate(date.toISOString());
    } else {
      throw new Error('Date must be of type String (ISO) or Object (Date)');
    }
  };
  _exports.parseDate = parseDate;
});