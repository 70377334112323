define("@additive-apps/utils/helpers/toggle", ["exports", "@ember/component/helper", "@ember/object", "@ember/utils"], function (_exports, _helper, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toggle = toggle;
  /**
   * Returns the next index based on the current index and a maximum length
   *
   * @param {Int} length, the maximum length
   * @param {Int} currentIdx, the current index
   * @function nextIndex
   * @private
   */
  const nextIndex = (length, currentIdx) => {
    if (currentIdx === -1 || currentIdx + 1 === length) {
      return 0;
    }
    return currentIdx + 1;
  };

  /**
   * Toggles a boolean property of a given object. By default the property is treated
   * as boolean. If additional values are provided, the property is toggled along the
   * provided value.
   *
   * @param {String} prop, the name of the property to be toggled
   * @param {Object} obj, the object
   * @param {Any} values, values to be toggled instead of standard true and false
   * @function toggle
   * @public
   */
  function toggle(_ref) {
    let [prop, obj, ...values] = _ref;
    return function () {
      const currentValue = obj[prop];
      if ((0, _utils.isPresent)(values)) {
        const currentIdx = values.indexOf(currentValue);
        const nextIdx = nextIndex(values.length, currentIdx);
        return (0, _object.set)(obj, prop, values[nextIdx]);
      }
      return (0, _object.set)(obj, prop, !currentValue);
    };
  }
  var _default = _exports.default = (0, _helper.helper)(toggle);
});