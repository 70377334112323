define("@additive-apps/ui/templates/components/ui-searchbar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "wcMP99mq",
    "block": "[[[1,[28,[35,0],[\"search\"],null]],[1,\"\\n\\n\"],[10,1],[14,0,\"flex flex-grow-1 items-center mh1\"],[12],[1,\"\\n  \"],[10,\"input\"],[14,1,\"ui-searchbar__search-input\"],[14,0,\"w-100 font-md primary pa0 bg-transparent\"],[15,\"placeholder\",[30,0,[\"placeholder\"]]],[15,\"oninput\",[28,[37,1],[[30,0],[30,0,[\"onSearch\"]]],null]],[12],[13],[1,\"\\n\"],[41,[30,0,[\"isLoading\"]],[[[1,\"    \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[18,1,[[28,[37,5],null,[[\"right\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"_hasOnCloseFunction\"]],[[[1,\"  \"],[8,[39,7],[[24,\"tabindex\",\"0\"]],[[\"@icon\",\"@classNames\",\"@onClick\"],[\"close\",\"\",[30,0,[\"onClose\"]]]],null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"svg-jar\",\"action\",\"if\",\"ui-loading\",\"yield\",\"hash\",\"component\",\"ui-action-button\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-searchbar.hbs",
    "isStrictMode": false
  });
});