define("@additive-apps/ui/templates/components/ui-selection-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "HLQA/GZ/",
    "block": "[[[18,1,[[28,[37,1],null,[[\"actions\",\"addSelection\",\"isSelected\",\"selectItem\",\"toggleSelectionMode\",\"clearSelection\",\"items\",\"selectedKeys\",\"excludedKeys\",\"notSelectableItems\",\"isMaxCount\",\"isAllSelected\",\"hasSelectedItems\",\"isInvertedSelection\",\"selectedItemsCount\"],[[50,\"ui-selection-container/actions\",0,null,[[\"toggleSelectionMode\",\"isAllSelected\",\"itemsCount\",\"isVisible\",\"isFixed\",\"isLoading\",\"loadingText\",\"disableSelectAll\",\"isInvertedSelection\"],[[28,[37,3],[[30,0],\"toggleSelectionMode\"],null],[30,0,[\"_isAllSelected\"]],[30,0,[\"_selectedItemsCount\"]],[30,0,[\"hasSelectedItems\"]],[30,0,[\"isFixed\"]],[30,0,[\"isLoading\"]],[30,0,[\"loadingText\"]],[30,0,[\"disableSelectAll\"]],[30,0,[\"_isInvertedSelection\"]]]]],[28,[37,3],[[30,0],\"addSelection\"],null],[28,[37,3],[[30,0],\"isSelected\"],null],[28,[37,3],[[30,0],\"selectItem\"],null],[28,[37,3],[[30,0],\"toggleSelectionMode\"],null],[28,[37,3],[[30,0],\"clearSelection\"],null],[30,0,[\"items\"]],[30,0,[\"_selectedKeys\"]],[30,0,[\"_excludedKeys\"]],[30,0,[\"_notSelectableItems\"]],[30,0,[\"_isMaxCount\"]],[30,0,[\"_isAllSelected\"]],[30,0,[\"hasSelectedItems\"]],[30,0,[\"_isInvertedSelection\"]],[30,0,[\"_selectedItemsCount\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-selection-container.hbs",
    "isStrictMode": false
  });
});