define("ember-svg-jar/inlined/arrow-down-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.646 17.207a.5.5 0 00.707 0l3.824-3.823a.5.5 0 000-.707l-.354-.354a.5.5 0 00-.707 0L12.75 14.69V7.5a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v7.19l-2.366-2.367a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l3.823 3.823z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});