define("ember-svg-jar/inlined/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 5.5v4a.5.5 0 01-.5.5h-4v8.5h9v-13zM6.146 8.854l4.708-4.708A.5.5 0 0111.207 4H17.5a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5V9.207a.5.5 0 01.146-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});