define("ember-svg-jar/inlined/catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.741 15.966a1 1 0 10.518-1.932 1 1 0 00-.518 1.932z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 6.972l1.035-1.793c.236-.408.354-.613.554-.666.2-.054.404.064.812.3l5.197 3c.408.235.612.353.666.553.053.2-.065.405-.3.813l-4.447 7.701a4 4 0 01-6.98.093l-4.5-7.794c-.236-.408-.354-.613-.3-.813.053-.2.257-.318.665-.553l5.197-3c.408-.236.612-.354.812-.3.2.053.318.258.554.666L12 6.972zm-2.165 9.251l-4.25-7.361 4.33-2.5 4.25 7.361c.45.777.433 1.695.039 2.432l-.04.068h.002a2.5 2.5 0 01-4.33 0zm4.25-9.861l4.33 2.5-.928 1.607c-.77 1.332-1.154 1.998-1.73 1.998-.578 0-.963-.665-1.733-1.996l-1.158-1.999 1.218-2.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});