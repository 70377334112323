define("@additive-apps/utils/validators/date", ["exports", "@ember/debug", "@ember/utils", "date-fns/locale/de", "date-fns/format", "date-fns/isValid", "date-fns/parse", "date-fns/isBefore"], function (_exports, _debug, _utils, _de, _format, _isValid, _parse, _isBefore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateDate = validateDate;
  _exports.validateDateDependency = validateDateDependency;
  _exports.validateDateFormat = validateDateFormat;
  const DEFAULT_FORMAT = 'dd.MM.yyyy';
  const VALIDATION_TYPE_BEFORE = 'before';
  const VALIDATION_TYPE_AFTER = 'after';
  const VALIDATION_TYPE = [VALIDATION_TYPE_BEFORE, VALIDATION_TYPE_AFTER];

  /**
   *  @module Validators
   */

  /**
   * Cheks if given date is valid
   *
   * Returns false if argument is Invalid Date and true otherwise.
   * Invalid Date is a Date, whose time value is NaN.
   *
   * @function validateDate
   * @param {Object} options
   * @param {Any} key the property key
   *
   * @param {String} options.format the required date ISO-format
   * @param {String} options.allowBlank If true, skips validation if the value is empty
   * @param {String} options.message Error message
   * @param {Object} changes
   * @param {Object} content
   *
   * @return {Boolean|ValidationErr}
   */
  function validateDate() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let {
      allowBlank
    } = options;
    let format = options.format || DEFAULT_FORMAT;
    let message = options.message || false;
    return (key, newValue) => {
      // if allowBlank is set and the value is empty skip validation
      if (allowBlank && (0, _utils.isEmpty)(newValue)) {
        return true;
      }

      // should return a invalidDateFormat validation error
      return validateDateFormat(newValue, format) || message;
    };
  }

  /**
   * @function validateDateDependency
   * @param {Object} options
   *
   * @param {Any} key the property key
   * @param {String} options.on The attribute to confirm against
   * @param {String} options.type Either before or after
   *
   * @param {String} options.format the required date ISO-format
   * @param {String} options.allowBlank If true, skips validation if the value is empty
   * @param {String} options.allowEmptyDependency If true, skips validation if the value is empty
   * @param {String} options.message Error message
   * @param {Object} changes
   * @param {Object} content
   *
   * @return {Boolean|ValidationErr}
   */
  function validateDateDependency() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let {
      on,
      allowBlank,
      allowEmptyDependency,
      type
    } = options;
    let message = options.message || false;
    (false && !((0, _utils.isPresent)(on)) && (0, _debug.assert)(`[validator:validateDate] option 'on' is required`, (0, _utils.isPresent)(on)));
    (false && !(VALIDATION_TYPE.indexOf(type) > -1) && (0, _debug.assert)(`[validator:validateDate] option 'type' must be before or after`, VALIDATION_TYPE.indexOf(type) > -1));
    return (key, newValue, oldValue, changes, content) => {
      // if allowBlank is set and the value is empty skip validation
      if (allowBlank && (0, _utils.isEmpty)(newValue)) {
        return true;
      }

      // if allowBlank is set and the value is empty skip validation
      const dependencyValue = changes[on] || content[on];
      if (allowEmptyDependency && (0, _utils.isEmpty)(dependencyValue)) {
        return true;
      }

      // if type is before the value needs to be before the dependend value
      const check = (0, _isBefore.default)(new Date(newValue), new Date(dependencyValue));
      if (type === 'before' && check || type === 'after' && !check) {
        return true;
      }
      return message;
    };
  }

  /**
   * Validates a date
   *
   * Should probably be a util and exposed as template-helper to
   * @function validateDateFormat
   * @param {String|Date} date the date that needs to be validated
   * @param {String} format the format the given date should have
   *
   * @return {Boolean}
   */
  function validateDateFormat(date) {
    let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_FORMAT;
    // parse date with locale and expected format
    const parsedDate = (0, _parse.default)(date, format, new Date(), {
      locale: _de.default
    });
    if ((0, _isValid.default)(parsedDate) && (0, _format.default)(parsedDate, format, {
      locale: _de.default
    }) === date) {
      return true;
    }
    return false;
  }
});