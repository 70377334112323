define("@additive-apps/utils/helpers/currency", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = _exports.default = void 0;
  /**
   * Localizes a given numeral value to a chosen currency. The intl-localization
   * uses always the standard german format and should not be modified.
   *
   * ```hbs
   *   {{currency value currency='EUR' decimalCount=2 divider=1}}
   * ```
   *
   * @param {Number} params[0], the numeral value to be localized
   * @param {String} currency, the currency in ISO-format i.e. EUR, USD, ..
   * @param {Int} decimalCount, the number of decimal digits, default: 2
   * @param {Int} divider, the multiplier ratio a value should be modified before converting, default: 1
   * @function formatCurrency
   */
  const formatCurrency = (params, _ref) => {
    let {
      currency = 'EUR',
      decimalCount = 2,
      divider = 1
    } = _ref;
    const amount = Math.floor(100 * params[0]) / 100;
    const formattedAmount = (amount / divider).toLocaleString('de-DE', {
      style: 'currency',
      currency,
      minimumFractionDigits: decimalCount,
      maximumFractionDigits: decimalCount
    });
    return formattedAmount;
  };
  _exports.formatCurrency = formatCurrency;
  var _default = _exports.default = (0, _helper.helper)(formatCurrency);
});