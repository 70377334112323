define("@additive-apps/ui/templates/components/ui-selection-container/action-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QTfI+ZDi",
    "block": "[[[41,[30,0,[\"isLoading\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@classNames\"],[\"mr1\"]],null],[1,\"\\n  \"],[10,1],[14,0,\"flex mh1 font-sm\"],[12],[1,\"\\n    \"],[1,[30,0,[\"loadingText\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@isPartiallyChecked\",\"@isChecked\",\"@theme\",\"@onChange\"],[true,[30,0,[\"isAllSelected\"]],\"white\",[28,[37,3],[[30,0],\"toggleSelectionMode\"],null]]],null],[1,\"\\n  \"],[10,1],[14,0,\"flex mh1 font-sm\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[\"selectContainer.items\"],[[\"count\"],[[30,0,[\"itemsCount\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[15,0,[29,[\"flex flex-grow-1 \",[52,[48,[30,1]],\"mr2\"]]]],[12],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[10,1],[14,0,\"flex gap2\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[\"&default\"],false,[\"if\",\"ui-loading\",\"ui-checkbox\",\"action\",\"t\",\"has-block\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-selection-container/action-bar.hbs",
    "isStrictMode": false
  });
});