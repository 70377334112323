define("ember-svg-jar/inlined/send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.972 4.486l14.134 7.067a.5.5 0 010 .894L4.972 19.514a.5.5 0 01-.708-.569L6 12 4.264 5.055a.5.5 0 01.708-.569zm10.174 8.263H7.359L6.246 17.2l8.9-4.45zM6.246 6.8l1.112 4.45h7.788l-8.9-4.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});