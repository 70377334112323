define("ember-animated/components/animated-beacon", ["exports", "@ember/component", "@ember/service", "ember-animated/-private/ember-scheduler", "ember-animated", "ember-animated/-private/ember-internals", "ember-animated/-private/sprite", "ember-animated/templates/components/animated-beacon"], function (_exports, _component, _service, _emberScheduler, _emberAnimated, _emberInternals, _sprite, _animatedBeacon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2; // @ts-ignore: templates don't have types
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
    A component that marks a region of the page that
    can serve as a source or destination for sprites to animate to and from.
  
    ```hbs
    {{#animated-beacon name="one"}}
      <button {{action "launch"}}>Launch</button>
    {{/animated-beacon}}
  
    {{#animated-if showThing use=transition}}
      <div class="message" {{action "dismiss"}}>
        Hello
      </div>
    {{/animated-if}}
    ```
  
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import scale from 'ember-animated/motions/scale';
  
    export default Component.extend({
      showThing: false,
  
      transition: function *({ insertedSprites, keptSprites, removedSprites, beacons }) {
        for (let sprite of insertedSprites) {
          sprite.startAtSprite(beacons.one);
          move(sprite);
          scale(sprite);
        }
  
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          sprite.endAtSprite(beacons.one);
          move(sprite);
          scale(sprite);
        }
      });
    },
  
      actions: {
        launch() {
          this.set('showThing', true);
        },
        dismiss() {
          this.set('showThing', false);
        }
      }
    });
    ```
    @class animated-beacon
    @public
  */
  let AnimatedBeacon = _exports.default = (_dec = (0, _service.inject)('-ea-motion'), _dec2 = (0, _emberScheduler.task)(function* () {
    let element = this._firstChildElement();
    if (!element) {
      return;
    }
    let offsetParent = _sprite.default.offsetParentStartingAt(element);
    let sprite = _sprite.default.positionedStartingAt(element, offsetParent);
    yield (0, _emberAnimated.afterRender)();
    yield (0, _emberAnimated.microwait)();
    yield* this.get('motionService').staticMeasurement(() => {
      offsetParent.measureFinalBounds();
      sprite.measureFinalBounds();
    });
    yield this.get('motionService').get('addBeacon').perform(this.name, sprite);
  }), _class = class AnimatedBeacon extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "name", void 0);
      _defineProperty(this, "layout", _animatedBeacon.default);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "_inserted", false);
      _initializerDefineProperty(this, "motionService", _descriptor, this);
      _initializerDefineProperty(this, "participate", _descriptor2, this);
    }
    didInsertElement() {
      super.didInsertElement();
      this._inserted = true;
      this.animationStarting = this.animationStarting.bind(this);
      this.get('motionService').observeAnimations(this.animationStarting);
    }
    willDestroyElement() {
      super.willDestroyElement();
      this.get('motionService').unobserveAnimations(this.animationStarting);
    }
    animationStarting() {
      this.get('participate').perform();
    }
    _firstChildElement() {
      if (this._inserted) {
        let {
          firstNode,
          lastNode
        } = (0, _emberInternals.componentNodes)(this);
        let node = firstNode;
        while (node) {
          if (node.nodeType === Node.ELEMENT_NODE) {
            return node;
          }
          if (node === lastNode) {
            break;
          }
          node = node.nextSibling;
        }
      }
      return undefined;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "motionService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "participate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});