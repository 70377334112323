define("ember-svg-jar/inlined/user-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z\" fill=\"#8F9EA4\" fill-opacity=\".15\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 15.333c3.346 0 4.91 1.674 4.692 5.022a.333.333 0 01-.333.312h-8.718a.333.333 0 01-.333-.312c-.217-3.348 1.346-5.022 4.692-5.022zm0-4.666A1.667 1.667 0 1116 14a1.667 1.667 0 010-3.333z\" fill=\"#8F9EA4\" fill-opacity=\".4\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});