define("ember-svg-jar/inlined/robot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 12a1 1 0 11-2 0 1 1 0 012 0zM15 13a1 1 0 100-2 1 1 0 000 2z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.75 4.5a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5V7H5.5a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-12a.5.5 0 00-.5-.5h-5.75V4.5zm-1.5 14H9.5v-2h1.75v2zm1.5 0h1.75v-2h-1.75v2zm3.25-3v3h1.5v-10h-11v10H8v-3a.5.5 0 01.5-.5h7a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});