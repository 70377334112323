define("ember-svg-jar/inlined/emoji", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 11a1 1 0 100-2 1 1 0 000 2zM15 10a1 1 0 11-2 0 1 1 0 012 0zM15.394 14.284c.16-.226.068-.532-.174-.664l-.44-.24c-.242-.132-.542-.038-.716.176-1.053 1.3-3.075 1.3-4.128 0-.173-.214-.474-.308-.716-.176l-.44.24c-.242.132-.333.439-.174.664 1.643 2.33 5.146 2.33 6.788 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});