define("ember-animated/-private/motion", ["exports", "ember-animated/-private/scheduler", "ember-animated/-private/concurrency-helpers", "ember-animated/-private/motion-bridge", "ember-animated/-private/transition-context"], function (_exports, _scheduler, _concurrencyHelpers, _motionBridge, _transitionContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const motions = new WeakMap();
  class Motion {
    constructor(sprite) {
      let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.sprite = sprite;
      this.opts = opts;
      _defineProperty(this, "_motionList", void 0);
      _defineProperty(this, "_inheritedMotionList", void 0);
      this.sprite = sprite;
      this.opts = opts;
      this._setupMotionList();
    }

    // All motions should read this to decide how long to animate. It allows users
    // to set a duration explicitly or rely on the prevailing default for the
    // whole running transition.
    get duration() {
      if (this.opts.duration != null) {
        return this.opts.duration;
      }
      return _transitionContext.default.forSprite(this.sprite).duration;
    }
    run() {
      let context = _transitionContext.default.forSprite(this.sprite);
      let self = this;
      return (0, _scheduler.spawnChild)(function* () {
        context.onMotionStart(self.sprite);
        try {
          yield* self._run();
        } finally {
          context.onMotionEnd(self.sprite);
        }
      });
    }

    // --- Begin Hooks you should Implement ---

    // Here you can inspect the other motions on this element that have
    // been interrupted during this frame. You should save any state on
    // `this` in order to influence your own animation. This hook is
    // skipped if there were no other motions.
    interrupted(_otherMotions) {}

    // Implement your animation here. It must be a generator function
    // that yields promises (just like an ember-concurrency task, except
    // you don't need to wrap in `task()` here and you therefore don't
    // get the extra features provided by EC tasks.
    *animate() {}

    // --- Begin private methods ---

    *_run() {
      try {
        let others = this._motionList.filter(m => m !== this);
        if (this._inheritedMotionList) {
          others = others.concat(this._inheritedMotionList);
        }
        if (others.length > 0) {
          this.interrupted(others);
        }
        yield* this.animate();
      } finally {
        (0, _concurrencyHelpers.rAF)().then(() => this._clearMotionList());
      }
    }
    _setupMotionList() {
      let element = this.sprite.element;
      let motionList = motions.get(element);
      if (!motionList) {
        motions.set(element, motionList = []);
      }
      this._motionList = motionList;
      // we wait here so that if multiple motions are started
      // simultaneously, the latter ones don't see the earlier ones as
      // interrupted.
      (0, _concurrencyHelpers.microwait)().then(() => {
        if (this._motionList) {
          this._motionList.unshift(this);
        }
      });
      let oldElement = (0, _motionBridge.continuedFromElement)(element);
      if (oldElement) {
        let inheritedMotions = motions.get(oldElement);
        if (inheritedMotions) {
          this._inheritedMotionList = inheritedMotions;
        }
      }
    }
    _clearMotionList() {
      if (this._motionList) {
        let index = this._motionList.indexOf(this);
        this._motionList.splice(index, 1);
        if (this._motionList.length === 0) {
          motions.delete(this.sprite.element);
        }
        this._motionList = undefined;
      }
    }
  }
  _exports.default = Motion;
});