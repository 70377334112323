define("@additive-apps/utils/utils/locale-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocale = void 0;
  /**
   * Matches the browser locale to an available app language and returns it
   *
   * @function getLocale
   */
  const getLocale = () => {
    if (window && window.navigator) {
      // try to get locale from localstorage
      if (window.localStorage) {
        const locale = localStorage.getItem('locale');
        if (locale) return locale;
      }
      const browserLocale = navigator.language || navigator.browserLanguage || (navigator.languages || ['de'])[0];
      const trimmedLocale = browserLocale.trim();
      const languageCode = trimmedLocale.split(/-|_/)[0];
      if (['de', 'en'].indexOf(languageCode) >= 0) {
        return languageCode;
      }
    }
    return 'de';
  };
  _exports.getLocale = getLocale;
});