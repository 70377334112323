define("@additive-apps/utils/utils/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAdapterError = void 0;
  /**
   * returns the error message when an adapter commit fails
   *
   * @function getAdapterError
   * @param {AdapterError} e
   * @return {String|Null}
   */
  const getAdapterError = e => {
    return e.errors && e.errors[0] && e.errors[0].detail;
  };
  _exports.getAdapterError = getAdapterError;
});