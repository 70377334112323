define("ember-svg-jar/inlined/spacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 11.75a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-.5zM11.646 6.354a.5.5 0 01.708 0l1.792 1.792a.5.5 0 01-.353.854h-3.586a.5.5 0 01-.353-.854l1.792-1.792zM11.646 17.646a.5.5 0 00.708 0l1.792-1.792a.5.5 0 00-.353-.854h-3.586a.5.5 0 00-.353.854l1.792 1.793z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});