define("ember-svg-jar/inlined/magic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.833 4.167a.833.833 0 11-1.667 0 .833.833 0 011.667 0zM7.159 8.523c.117-.35.175-.526.27-.56a.208.208 0 01.14 0c.096.034.155.21.272.56.374 1.121.56 1.682.906 2.13.174.224.375.426.6.6.447.345 1.008.532 2.13.906.35.117.526.175.56.27a.209.209 0 010 .141c-.034.096-.21.154-.56.27-1.122.375-1.683.562-2.13.908a3.333 3.333 0 00-.6.6c-.346.447-.532 1.007-.906 2.13-.117.35-.176.525-.271.56a.208.208 0 01-.14 0c-.096-.035-.154-.21-.271-.56-.374-1.122-.561-1.683-.907-2.13a3.332 3.332 0 00-.6-.6c-.447-.346-1.008-.533-2.13-.907-.35-.117-.525-.175-.56-.27a.208.208 0 010-.141c.035-.096.21-.154.56-.27 1.122-.375 1.683-.562 2.13-.908.225-.173.427-.375.6-.6.346-.447.533-1.008.907-2.13zM13.872 10.539L11.96 8.628a.417.417 0 010-.59l1.91-1.91a.417.417 0 01.59 0l1.91 1.91a.417.417 0 010 .59l-1.91 1.91a.417.417 0 01-.59 0z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});