define("ember-svg-jar/inlined/wellness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.18 4.88a.3.3 0 01.06.061l2.521 3.37a6.153 6.153 0 011.112 2.5c.298-.083.584-.13.857-.14l4.205-.16a.3.3 0 01.302.373l-1.032 4.079a6.159 6.159 0 01-5.735 4.644H12v.003H9.53a6.159 6.159 0 01-5.735-4.645l-1.032-4.079a.3.3 0 01.302-.373l4.205.16c.272.011.558.057.858.14A6.151 6.151 0 019.239 8.31l2.52-3.37a.3.3 0 01.42-.06zm-7.57 7.193l.639 2.525c.504 1.995 3.077 3.792 4.632 2.96 1.556-.831 1.556-2.863.619-3.674-1.357-1.175-2.516-1.682-3.287-1.711l-2.603-.1zm14.78-.003l-2.603.1c-.771.03-1.93.537-3.287 1.712-.937.81-.937 2.842.619 3.674 1.556.831 4.128-.966 4.632-2.96l.639-2.526zM12 7.124l-1.56 2.085a4.65 4.65 0 00-.892 2.205c.751.413 1.569 1.004 2.452 1.77.883-.768 1.7-1.358 2.45-1.774a4.602 4.602 0 00-.89-2.201L12 7.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});