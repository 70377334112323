define("@additive-apps/ui/templates/components/ui-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "V3Bd0qcU",
    "block": "[[[10,0],[15,0,[29,[\"ui-status flex items-center justify-center br-100\\n\\n    \",[52,[30,0,[\"state\"]],[28,[37,1],[\"ui-status--\",[30,0,[\"state\"]]],null],\"\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui-status__inner br-100 flex\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"concat\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-status.hbs",
    "isStrictMode": false
  });
});