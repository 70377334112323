define("ember-svg-jar/inlined/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.25 11.495L6.5 9.459v5.552l4.75 2.036v-5.552zm.947 7.42a.5.5 0 01-.394 0l-6.5-2.785A.5.5 0 015 15.67V7.377a.5.5 0 01.363-.48l6.5-1.858a.5.5 0 01.274 0l6.5 1.857a.5.5 0 01.363.481v8.293a.5.5 0 01-.303.46l-6.5 2.786zM17.074 8.01L12 6.56 6.926 8.01 12 10.184l5.074-2.174zm.426 1.449v5.552l-4.75 2.036v-5.552l4.75-2.036z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});