define("ember-svg-jar/inlined/grow-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.72 9.22l-1.366-1.366A.5.5 0 0115.707 7H19.5a.5.5 0 01.5.5v3.793a.5.5 0 01-.854.353L17.78 10.28l-4.426 4.427a.5.5 0 01-.708 0L10 12.061l-5.116 5.116a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707l5.823-5.823a.5.5 0 01.708 0L13 12.939z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});