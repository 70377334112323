define("ember-svg-jar/inlined/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.823 9.884L10.94 12l-2.116 2.116a.5.5 0 000 .707l.354.354a.5.5 0 00.707 0L12 13.06l2.116 2.116a.5.5 0 00.707 0l.354-.354a.5.5 0 000-.707L13.06 12l2.116-2.116a.5.5 0 000-.707l-.354-.354a.5.5 0 00-.707 0L12 10.94 9.884 8.823a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});