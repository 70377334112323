define("ember-svg-jar/inlined/feather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.235 8.023C14.358 5.956 18.61 4.05 19.28 4.72c.67.67-1.236 4.922-3.303 8.045-2.61 3.943-5.308 5.752-7.688 4.007l-2.405 2.405a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707l2.405-2.405c-1.745-2.38.064-5.077 4.007-7.688zm5.563.24L9.391 15.67c1.447.72 3.278-.626 5.335-3.733a32.112 32.112 0 002.072-3.674zm-1.063-1.06a32.112 32.112 0 00-3.672 2.071c-3.106 2.057-4.452 3.887-3.733 5.334l7.405-7.405z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});