define("ember-svg-jar/inlined/lock-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 17c-.233 0-.35 0-.441-.038a.5.5 0 01-.27-.27c-.039-.093-.039-.209-.039-.442v-2.5c0-.233 0-.35.038-.441a.5.5 0 01.27-.27C11.65 13 11.768 13 12 13s.35 0 .441.038a.5.5 0 01.27.27c.039.092.039.209.039.442v2.5c0 .233 0 .35-.038.441a.5.5 0 01-.27.27C12.35 17 12.232 17 12 17z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 3.25A3.75 3.75 0 008.25 7v3H5.5a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5H9.75V7a2.25 2.25 0 014.377-.736c.11.315.164.473.21.536.08.106.122.136.248.176C14.66 7 14.774 7 15 7c.219 0 .328 0 .43-.048a.56.56 0 00.27-.331c.028-.11.01-.195-.024-.366A3.751 3.751 0 0012 3.25zM6.5 11.5v7h11v-7h-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});