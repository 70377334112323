define("ember-svg-jar/inlined/invite-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path d=\"M183.146 47.028a.25.25 0 00-.292 0l-71 50.968a.25.25 0 00-.104.203l.047 100.948c0 .138.111.25.25.25h141.906a.25.25 0 00.25-.25l.047-100.948a.25.25 0 00-.104-.203z\" stroke=\"#00172F\" stroke-width=\"1.5\"/><path d=\"M213.598 170.25c.841 0 1.53-.63 1.584-1.429 1.499-22.426-9.118-33.495-32.146-33.495-23.029 0-33.646 11.07-32.148 33.495.054.8.743 1.429 1.584 1.429h61.126zm-30.563-43.2c7.539 0 13.65-6.111 13.65-13.65s-6.111-13.65-13.65-13.65-13.65 6.111-13.65 13.65 6.112 13.65 13.65 13.65z\" stroke=\"#c6ab8d\" stroke-width=\"1.5\"/><path d=\"M211.75 126.75v5.75a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-5.75h-5.75a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h5.75v-5.75a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v5.75h5.75a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5z\" fill=\"#c6ab8d\"/></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});