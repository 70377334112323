define("@additive-apps/utils/helpers/format-date-time", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function formatDateTime(params) {
    if (!params[0]) return '';
    const date = new Date(params[0]);
    return date.toLocaleString(params[1] || 'de');
  });
});