define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.5 20a.5.5 0 01-.5-.5V9.25a.5.5 0 01.2-.4l7.5-5.625a.5.5 0 01.6 0l7.5 5.625a.5.5 0 01.2.4V19.5a.5.5 0 01-.5.5h-15zm14-10.25v8.75H14v-4a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v4H5.5V9.75L12 4.875l6.5 4.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});