define("ember-svg-jar/inlined/bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 6.5a.5.5 0 01.5-.5h4.25a3.25 3.25 0 012.418 5.422A3.5 3.5 0 0113.5 18h-5a.5.5 0 01-.5-.5v-11zM9.5 11V7.5h3.25a1.75 1.75 0 110 3.5H9.5zm0 1.5v4h4a2 2 0 100-4h-4z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});