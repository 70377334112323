define("ember-animated/domrect-polyfill", [], function () {
  "use strict";

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // Polyfill DOMRect
  // It's not available on Edge or IE11

  function nonEnumerable(target, propertyKey) {
    let descriptor = Object.getOwnPropertyDescriptor(target, propertyKey) || {};
    if (descriptor.enumerable != false) {
      descriptor.enumerable = false;
      Object.defineProperty(target, propertyKey, descriptor);
    }
  }
  let DOMRectPolyfill = (_class = class DOMRectPolyfill {
    static fromRect() {
      var _rect$x, _rect$y, _rect$width, _rect$height;
      let rect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new this((_rect$x = rect.x) !== null && _rect$x !== void 0 ? _rect$x : 0, (_rect$y = rect.y) !== null && _rect$y !== void 0 ? _rect$y : 0, (_rect$width = rect.width) !== null && _rect$width !== void 0 ? _rect$width : 0, (_rect$height = rect.height) !== null && _rect$height !== void 0 ? _rect$height : 0);
    }
    constructor(x, y, width, height) {
      // DOMRect's properties are all non-enumerable
      _initializerDefineProperty(this, "x", _descriptor, this);
      _initializerDefineProperty(this, "y", _descriptor2, this);
      _initializerDefineProperty(this, "width", _descriptor3, this);
      _initializerDefineProperty(this, "height", _descriptor4, this);
      if (x != null) {
        this.x = x;
      }
      if (y != null) {
        this.y = y;
      }
      if (width != null) {
        this.width = width;
      }
      if (height != null) {
        this.height = height;
      }
    }
    get top() {
      return this.y;
    }
    get right() {
      return this.x + this.width;
    }
    get bottom() {
      return this.y + this.height;
    }
    get left() {
      return this.x;
    }

    // But it has a toJSON that does include all the properties.
    toJSON() {
      return {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        top: this.top,
        right: this.right,
        bottom: this.bottom,
        left: this.left
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "x", [nonEnumerable], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "y", [nonEnumerable], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "width", [nonEnumerable], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "height", [nonEnumerable], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _class);
  if (typeof window !== 'undefined' && !window.DOMRect) {
    window.DOMRect = DOMRectPolyfill;
  }
});