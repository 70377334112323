define("ember-svg-jar/inlined/missing-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" stroke-width=\"1.5\"><rect height=\"78.5\" rx=\"1\" stroke=\"#00172F\" width=\"110.5\" x=\"128.75\" y=\"114.75\"/><path d=\"M148.75 114.25h69.5V93.5c0-19.192-15.558-34.75-34.75-34.75s-34.75 15.558-34.75 34.75z\" stroke=\"#00172F\"/><path d=\"M180.3 154.48l.45.197V171c0 .138.112.25.25.25h6a.25.25 0 00.25-.25v-16.323l.45-.196a9.251 9.251 0 005.55-8.481 9.25 9.25 0 10-18.5 0 9.251 9.251 0 005.55 8.48z\" opacity=\".9\" stroke=\"#b59e85\"/></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});