define("ember-svg-jar/inlined/inactive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.5 6.5h2.175l-2.545 4.243a.5.5 0 00.428.757H17.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-2.175l2.545-4.243A.5.5 0 0017.442 5H13.5a.5.5 0 00-.5.5V6a.5.5 0 00.5.5z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.137 14.97a6.5 6.5 0 01-6.98-7.895c.087-.39-.253-.768-.627-.627a6.962 6.962 0 00-.853.39l-.004.002a7 7 0 109.487 9.488l.003-.005.066-.125a6.96 6.96 0 00.323-.728c.14-.374-.236-.714-.627-.627a6.488 6.488 0 01-.788.126zm-.89 1.526a8 8 0 01-7.743-7.742 5.5 5.5 0 107.742 7.743z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});