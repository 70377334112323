define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.683 5.243c.62-.62 1.26-.284 1.91.465.42.484.937 1.26 1.567 2.339a1.25 1.25 0 01-.233 1.55l-2.85 2.625c.405.647.956 1.323 1.663 2.034.714.707 1.393 1.26 2.041 1.666l2.626-2.85a1.25 1.25 0 011.55-.233c1.078.63 1.853 1.147 2.338 1.567.749.65 1.084 1.29.465 1.91-2.792 2.791-6.27 2.792-10.076-.999-3.793-3.804-3.793-7.282-1-10.074zm9.673 10.335a4.097 4.097 0 00-.043-.038c-.376-.326-1.026-.764-1.94-1.304l-2.174 2.36c1.536.489 2.9.09 4.157-1.018zM8.42 6.648c-1.109 1.256-1.507 2.62-1.02 4.156l2.362-2.175c-.54-.913-.978-1.563-1.304-1.939a3.629 3.629 0 00-.038-.043z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});