define("@additive-apps/utils/helpers/ui-synced-value", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiSyncedValue = uiSyncedValue;
  /**
   * Helper to get the value/syncedValue whether sync is active
   *
   * ```hbs
   *   ui-synced-value object sync=object.sync value=object.value
   * ```
   *
   * @param {Object}, object which contains `syncedValue`
   * @param {String} value, value if sync is not active
   * @param {Boolean} sync, if sync is active or not
   * @function uiSyncedValue
   * @public
   */
  function uiSyncedValue(_ref, _ref2) {
    let [{
      syncedValue
    }] = _ref;
    let {
      value = '',
      sync = false
    } = _ref2;
    return sync ? syncedValue : value;
  }
  var _default = _exports.default = (0, _helper.helper)(uiSyncedValue);
});