define("@additive-apps/ui/components/ui-comparison", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-comparison flex items-center font-sm primary gap1 {{@class}} {{@classNames}}"
    ...attributes
  >
    {{#if (and this.isUnchanged this.placeholder)}}
      <div class="secondary">
        {{this.placeholder}}
      </div>
    {{else}}
      {{#if this.isPositive}}
        <div class="flex br1 bg-green--10">
          {{svg-jar "arrow-up-small" width="20" height="20" class="green"}}
        </div>
        <div class="ws-nowrap green">
          {{format-number this._value format="0,0"}}%
        </div>
      {{else if this.isNegative}}
        <div class="flex br1 bg-red--10">
          {{svg-jar "arrow-down-small" width="20" height="20" class="red"}}
        </div>
        <div class="ws-nowrap red">
          {{format-number this._value format="0,0"}}%
        </div>
      {{else}}
        <div class="flex br1 bg-secondary--10">
          {{svg-jar "arrow-right-small" width="20" height="20" class="secondary"}}
        </div>
        <div class="ws-nowrap secondary">
          {{format-number this._value format="0,0"}}%
        </div>
      {{/if}}
      <div class="secondary">
        {{this.comparePeriod}}
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "ZrfVMOMs",
    "block": "[[[11,0],[16,0,[29,[\"ui-comparison flex items-center font-sm primary gap1 \",[30,1],\" \",[30,2]]]],[17,3],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"isUnchanged\"]],[30,0,[\"placeholder\"]]],null],[[[1,\"    \"],[10,0],[14,0,\"secondary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"placeholder\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isPositive\"]],[[[1,\"      \"],[10,0],[14,0,\"flex br1 bg-green--10\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"arrow-up-small\"],[[\"width\",\"height\",\"class\"],[\"20\",\"20\",\"green\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ws-nowrap green\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"_value\"]]],[[\"format\"],[\"0,0\"]]]],[1,\"%\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isNegative\"]],[[[1,\"      \"],[10,0],[14,0,\"flex br1 bg-red--10\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"arrow-down-small\"],[[\"width\",\"height\",\"class\"],[\"20\",\"20\",\"red\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ws-nowrap red\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"_value\"]]],[[\"format\"],[\"0,0\"]]]],[1,\"%\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"flex br1 bg-secondary--10\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"arrow-right-small\"],[[\"width\",\"height\",\"class\"],[\"20\",\"20\",\"secondary\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ws-nowrap secondary\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"_value\"]]],[[\"format\"],[\"0,0\"]]]],[1,\"%\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]],[1,\"    \"],[10,0],[14,0,\"secondary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"comparePeriod\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@class\",\"@classNames\",\"&attrs\"],false,[\"if\",\"and\",\"svg-jar\",\"format-number\"]]",
    "moduleName": "@additive-apps/ui/components/ui-comparison.hbs",
    "isStrictMode": false
  });
  /**
   * The component is used primarily to display metrics in dashboards and reports.
   * It can be used to display a simple value with a description or in most cases,
   * with a comparison of the data to an other period.
   *
   * ```hbs
   * <UiComparison
   *  @value="compare-value"
   *  @placeholder="Not enough data"
   *  @comparePeriod="last year"
   * />
   *  ```
   *
   * @class ui-comparison
   */
  let UiComparison = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.number), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class UiComparison extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The items title
       *
       * @argument description
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "description", _descriptor, this);
      /**
       * The actual compare-value
       *
       * @argument value
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "value", _descriptor2, this);
      /**
       * The compare-values placeholder that gets displayed if
       * the compare-value is equal zero.
       *
       * @argument placeholder
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "placeholder", _descriptor3, this);
      /**
       * The compare-values period,
       * i.e. `zum Vorjahr`
       *
       * @argument comparePeriod
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "comparePeriod", _descriptor4, this);
    }
    get isPositive() {
      return this.value > 0;
    }
    get isUnchanged() {
      return this.value === 0;
    }
    get isNegative() {
      return this.value < 0;
    }
    get _value() {
      return Math.abs(this.value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "description", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "placeholder", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "comparePeriod", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiComparison);
});