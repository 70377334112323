define("@additive-apps/ui/utils/dom-util", ["exports", "@ember/runloop", "rsvp"], function (_exports, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cAF = void 0;
  _exports.each = each;
  _exports.isDomElement = isDomElement;
  _exports.isFunction = isFunction;
  _exports.isNumber = isNumber;
  _exports.isObject = isObject;
  _exports.isString = isString;
  _exports.supportsPassiveEventListeners = _exports.rAF = _exports.preloadImage = _exports.nextTick = void 0;
  /*
   * Tick is set to 17 so we are sure to enter the next frame
   * As usually a runs on 60hz => TICK = 1 / 60Hz = 16,66ms
   */
  const TICK = 17;

  /**
   * This function performs some logic after a browser repaint.
   * While on testing or if raf not available, use a run-loop friendly equivalent.
   * This also makes the tests work as expected.
   * @function rAF
   * @public
   */
  const rAF = _exports.rAF = !window.requestAnimationFrame ? function (fn) {
    return (0, _runloop.later)(fn, TICK);
  } : window.requestAnimationFrame;

  /**
   * This function is the counterpart of rAF. It will cancel a previously
   * scheduled task with rAF. If on testing or when rAF isn't available
   * we default to `run.cancel`.
   * @function cAF
   * @public
   */
  const cAF = _exports.cAF = !window.cancelAnimationFrame ? function (requestID) {
    return (0, _runloop.cancel)(requestID);
  } : window.cancelAnimationFrame;

  /**
   * Performs some logic after DOM changes have been flushed
   * and after a browser repaint.
   * @function nextTick
   * @public
   */
  const nextTick = () => {
    return new _rsvp.default.Promise(resolve => {
      (0, _runloop.schedule)('afterRender', () => {
        rAF(() => {
          resolve();
        });
      });
    });
  };

  // Taken from https://github.com/Modernizr/Modernizr/blob/master/feature-detects/dom/passiveeventlisteners.js
  _exports.nextTick = nextTick;
  const supportsPassiveEventListeners = _exports.supportsPassiveEventListeners = function () {
    let supportsPassiveOption = false;
    try {
      let opts = Object.defineProperty({}, 'passive', {
        // eslint-disable-next-line
        get() {
          supportsPassiveOption = true;
        }
      });
      window.addEventListener('test', null, opts);
      // eslint-disable-next-line
    } catch (e) {}
    return supportsPassiveOption;
  }();

  /**
   * Performs some logic after DOM changes have been flushed
   * and after a browser repaint.
   *
   * @function preloadImage
   * @public
   */
  const preloadImage = url => {
    return new _rsvp.default.Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        resolve(image);
      };
      image.onerror = reject;
    });
  };

  /**
   * Iterate the given data.
   * @param {*} data - The data to iterate.
   * @param {Function} callback - The process function for each element.
   * @function each
   * @return {*} The original data.
   */
  _exports.preloadImage = preloadImage;
  function each(data, callback) {
    if (data && isFunction(callback)) {
      if (Array.isArray(data) || isNumber(data.length) /* array-like */) {
        const {
          length
        } = data;
        let i;
        for (i = 0; i < length; i += 1) {
          if (callback.call(data, data[i], i, data) === false) {
            break;
          }
        }
      } else if (isObject(data)) {
        Object.keys(data).forEach(key => {
          callback.call(data, data[key], key, data);
        });
      }
    }
    return data;
  }

  /**
   * Check if the given value is an object.
   * @param {*} value - The value to check.
   * @function isObject
   * @return {boolean} Returns `true` if the given value is an object, else `false`.
   */
  function isObject(value) {
    return typeof value === 'object' && value !== null;
  }

  /**
   * Check if the given value is a string.
   * @param {*} value - The value to check.
   * @function isString
   * @return {boolean} Returns `true` if the given value is an string, else `false`.
   */
  function isString(value) {
    return typeof value === 'string' && value !== null;
  }

  /**
   * Check if the given value is a function.
   * @param {*} value - The value to check.
   * @function isFunction
   * @return {boolean} Returns `true` if the given value is a function, else `false`.
   */
  function isFunction(value) {
    return typeof value === 'function';
  }

  /**
   * Check if the given value is a number.
   * @param {*} value - The value to check.
   * @function isNumber
   * @return {boolean} Returns `true` if the given value is a number, else `false`.
   */
  function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  /**
   * Checks if a given value is an HTMLElement.
   * This function is necessary because some browsers behave differently
   * for Elements created in JS.
   *
   * @param {*} value
   * @function isDomElement
   * @return {boolean}
   */
  function isDomElement(value) {
    return typeof HTMLElement === 'object' ? value instanceof HTMLElement : value && typeof value === 'object' && value !== null && value.nodeType === 1 && typeof value.nodeName === 'string';
  }
});