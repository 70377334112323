define("ember-svg-jar/inlined/pending", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 4c1.84 0 3.535.62 4.886 1.665l.25.2 1.01-1.011a.5.5 0 01.846.263l.008.09V8.5a.5.5 0 01-.41.492L18.5 9h-3.293a.5.5 0 01-.411-.784l.058-.07 1.214-1.215.017.014-.065-.054a6.466 6.466 0 00-3.525-1.372l-.302-.016C8.598 5.397 5.5 8.403 5.5 12.001a6.5 6.5 0 006.267 6.495L12 18.5a.75.75 0 11-.021 1.5l-.344-.009a8.46 8.46 0 01-1.109-.123c-3.174-.566-5.755-3.097-6.37-6.263A8.45 8.45 0 014 12.001 8 8 0 0112 4zm3.32 14.492a.75.75 0 11-1.401.537.75.75 0 011.4-.537zm2.122-1.636a.75.75 0 11-1.115 1.004.75.75 0 011.115-1.004zm1.377-2.299a.75.75 0 11-.658 1.349.75.75 0 01.658-1.349zM12.25 8a.5.5 0 01.5.5v3.067l2.655 1.534a.5.5 0 01.183.682l-.25.434a.5.5 0 01-.683.182L11.5 12.577a.5.5 0 01-.25-.433V8.5a.5.5 0 01.5-.5h.5zm6.933 3.92l.101.002a.75.75 0 11-.2.01l.099-.012z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});