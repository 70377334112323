define("ember-svg-jar/inlined/additive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#00172f\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M60.365 32.668a.437.437 0 01-.132.321.436.436 0 01-.321.132H54.83v5.066a.434.434 0 01-.132.321.434.434 0 01-.322.133h-3.665a.436.436 0 01-.321-.133.437.437 0 01-.132-.32V33.12h-5.081a.434.434 0 01-.321-.132.434.434 0 01-.133-.321v-3.666c0-.125.044-.232.133-.32a.437.437 0 01.32-.133h5.082v-5.096a.44.44 0 01.132-.32.44.44 0 01.321-.133h3.665c.127 0 .234.044.322.132a.436.436 0 01.132.321v5.096h5.08a.44.44 0 01.322.132.44.44 0 01.132.321zM38.683 54.622l-4.421-18.931c-.026-.1-.063-.145-.114-.133-.05.014-.088.057-.113.133L29.5 54.622c-.075.252-.251.378-.529.378H24.4c-.327 0-.453-.163-.378-.491l6.802-25.582c.075-.252.252-.378.529-.378h5.48c.277 0 .453.126.528.378l6.84 25.582.037.15c0 .228-.138.341-.415.341h-4.648c-.251 0-.416-.126-.491-.378z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80"
    }
  };
});