define("ember-svg-jar/inlined/heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.25 9a2.75 2.75 0 10-5.5 0c0 1-1.5 1-1.5 0a2.75 2.75 0 10-5.5 0c0 2.626 2.06 5.815 6.25 9.506 4.19-3.691 6.25-6.88 6.25-9.506zM15.5 4.75A4.25 4.25 0 0119.75 9c0 3.198-2.333 6.77-6.928 10.78a1.25 1.25 0 01-1.644 0C6.583 15.77 4.25 12.199 4.25 9A4.25 4.25 0 0112 6.588a4.245 4.245 0 013.5-1.838z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});