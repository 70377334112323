define("ember-svg-jar/inlined/checkbox-unchecked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.5 3.5v13h13v-13h-13zM2.5 2h15a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "#8F9EA4",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});