define("ember-svg-jar/inlined/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 19c-3.61 0-6.883-2.242-9.823-6.726a.5.5 0 010-.548C5.117 7.242 8.391 5 12 5c3.61 0 6.883 2.242 9.823 6.726a.5.5 0 010 .548C18.883 16.758 15.609 19 12 19zm0-12.5c-2.896 0-5.62 1.776-8.199 5.5C6.38 15.724 9.104 17.5 12 17.5s5.62-1.776 8.199-5.5C17.62 8.276 14.896 6.5 12 6.5zm0 8.5a3 3 0 110-6 3 3 0 010 6zm0-1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});