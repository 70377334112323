define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.303 6.697a2.5 2.5 0 00-3.535 0l-.707.707a.5.5 0 01-.707 0L12 7.05a.5.5 0 010-.707l.707-.707a4 4 0 015.657 5.657l-2.829 2.828a4 4 0 01-5.656 0l-.357-.356a.5.5 0 010-.707l.354-.354a.5.5 0 01.707 0l.356.357a2.5 2.5 0 003.536 0l2.828-2.829a2.5 2.5 0 000-3.535zm-7.07 10.606l.706-.707a.5.5 0 01.707 0l.354.354a.5.5 0 010 .707l-.707.707a4 4 0 01-5.657-5.657L8.464 9.88a4 4 0 015.657 0l.354.353a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0l-.353-.354a2.5 2.5 0 00-3.536 0l-2.828 2.829a2.5 2.5 0 103.535 3.535z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});