define("@additive-apps/ui/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uiNavigationDrawerRightContainerId = _exports.uiNavigationBarFilterableStateKey = _exports.uiApplicationNavigationDrawerAppsListStateKey = _exports.uiApplicationNavigationDrawerAccountSwitchStateKey = _exports.UI_DATEPICKER_DEFAULT_FORMAT = _exports.SORT_DIRECTIONS = _exports.SEARCH_INPUT_DOM_ID = _exports.SEARCH_INPUT_DEFAULT_PLACEHOLDER_TRANSLATION_KEY = _exports.REQUEST_DEBOUNCE_MS = void 0;
  const REQUEST_DEBOUNCE_MS = _exports.REQUEST_DEBOUNCE_MS = 250;
  const uiNavigationBarFilterableStateKey = _exports.uiNavigationBarFilterableStateKey = 'ui-navigation-bar-filterable';
  const uiNavigationDrawerRightContainerId = _exports.uiNavigationDrawerRightContainerId = 'ui-navigation-drawer--right';
  const uiApplicationNavigationDrawerAccountSwitchStateKey = _exports.uiApplicationNavigationDrawerAccountSwitchStateKey = 'ui-application-navigation-drawer-account-switch';
  const uiApplicationNavigationDrawerAppsListStateKey = _exports.uiApplicationNavigationDrawerAppsListStateKey = 'ui-application-navigation-drawer-apps-list';
  const SEARCH_INPUT_DOM_ID = _exports.SEARCH_INPUT_DOM_ID = 'ui-searchbar__search-input';
  const SEARCH_INPUT_DEFAULT_PLACEHOLDER_TRANSLATION_KEY = _exports.SEARCH_INPUT_DEFAULT_PLACEHOLDER_TRANSLATION_KEY = 'uiNavigationBarFilterabel.searchInputDefaultPlaceholder';
  const SORT_DIRECTIONS = _exports.SORT_DIRECTIONS = {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC'
  };
  const UI_DATEPICKER_DEFAULT_FORMAT = _exports.UI_DATEPICKER_DEFAULT_FORMAT = 'yyyy-MM-dd';
});