define("ember-svg-jar/inlined/hiking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.75 7h4.016a.5.5 0 01.384.18l2.083 2.5a.5.5 0 010 .64l-2.083 2.5a.5.5 0 01-.384.18H12.75v6.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V13H6.5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h4.75V4.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5zM7.5 8.5v3h8.797l1.25-1.5-1.25-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});