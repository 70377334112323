define("ember-svg-jar/inlined/rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.337 6.353A.5.5 0 016.815 6h10.37a.5.5 0 01.478.353l2.153 7a.5.5 0 01-.477.647h-4.677a.5.5 0 01-.478-.647l1.8-5.853H12.75v9h2.75a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5V17a.5.5 0 01.5-.5h2.75v-9H8.015l1.801 5.853A.5.5 0 019.34 14H4.662a.5.5 0 01-.478-.647l2.153-7zM7 9.3l.985 3.2h-1.97L7 9.3zm10 0l-.985 3.2h1.97L17 9.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});