define("@additive-apps/ui/templates/components/ui-reports/card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0IOzmh0M",
    "block": "[[[41,[30,0,[\"isEmpty\"]],[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"title\",\"description\"],[[30,0,[\"title\"]],[30,0,[\"description\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,3],[\"arrow-chart\"],[[\"class\"],[\"blue\"]]]],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-column flex-grow-1 ph2\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-sm blue\"],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"font-xs secondary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,3],[\"arrow-right\"],[[\"class\"],[\"blue\"]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"yield\",\"hash\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-reports/card.hbs",
    "isStrictMode": false
  });
});