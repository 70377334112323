define("ember-svg-jar/inlined/no-search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M217.25 203V53a.25.25 0 00-.25-.25h-61.608a.25.25 0 00-.17.067L114.83 90.253a.25.25 0 00-.08.184V203c0 .138.112.25.25.25h102a.25.25 0 00.25-.25z\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"/><path d=\"M245.562 169.562l28.285 28.285\" stroke=\"#8f9ea4\" stroke-linecap=\"round\" stroke-width=\"1.5\"/><g fill-rule=\"nonzero\" transform=\"translate(114 52)\"><circle cx=\"98\" cy=\"84\" fill=\"#f2f2f2\" r=\"48\"/><path d=\"M41.25 38.25V1.296a.25.25 0 00-.42-.183L1.373 37.817a.25.25 0 00.17.433z\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"/><circle cx=\"98\" cy=\"84\" r=\"47.25\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"/></g><g stroke=\"#8f9ea4\" stroke-linecap=\"round\" stroke-opacity=\".4\" stroke-width=\"1.5\"><path d=\"M199.046 124.694c-.193-4.974.222-8.488 1.245-10.54 2.705-5.434 7.158-7.154 12.912-7.154 7.42 0 12.797 5.159 12.797 13.95 0 5.379-2.546 9.42-5.92 12.336-6.228 5.384-9 7.372-9 11.283V153M211 161v3\"/></g></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});