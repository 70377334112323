define("ember-svg-jar/inlined/klarna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.988 23.46h4.969V.126h-4.969zm31.48-13.472v-2.07h-4.683V23.5h4.684v-8.164c0-2.363 2.648-3.399 4.515-2.902h.04V7.918c-1.907-.043-3.645.789-4.555 2.07zM37.48 19.855c-2.445 0-4.394-1.867-4.394-4.187 0-2.32 1.988-4.188 4.394-4.188 2.442 0 4.391 1.868 4.391 4.188 0 2.32-1.988 4.187-4.39 4.187zm4.348-10.902C40.504 8 38.844 7.461 37.062 7.461c-4.554 0-8.199 3.691-8.199 8.207 0 4.52 3.688 8.207 8.2 8.207 1.78 0 3.398-.582 4.765-1.492v1.078h4.059V7.875h-4.059zm27.879-1.492c-1.867 0-3.648.582-4.809 2.156V7.875h-4.68v15.586h4.68v-8.54c0-2.362 1.575-3.523 3.524-3.523 2.027 0 3.23 1.247 3.23 3.48v8.54h4.68v-9.906c0-3.563-2.898-6.051-6.625-6.051zm30.238 10.488a2.95 2.95 0 00-2.941 2.942 2.95 2.95 0 002.941 2.941 2.95 2.95 0 002.938-2.941 2.923 2.923 0 00-2.938-2.942zm-13.382 1.906c-2.442 0-4.391-1.867-4.391-4.187 0-2.32 1.988-4.188 4.39-4.188 2.446 0 4.391 1.868 4.391 4.188.043 2.32-1.945 4.187-4.39 4.187zm4.39-10.902C89.63 8 87.973 7.461 86.191 7.461c-4.554 0-8.203 3.691-8.203 8.207 0 4.52 3.688 8.207 8.203 8.207 1.782 0 3.399-.582 4.762-1.492v1.078h4.063V7.875h-4.063zm7.004-.785h.207c.121 0 .207.082.207.164 0 .125-.043.168-.207.168h-.207zm0 .539h.207l.207.453h.207l-.207-.453c.121-.043.207-.168.207-.332 0-.207-.168-.375-.414-.375h-.414v1.16h.207zM.117 23.461h5.176V.125H.117zM18.59.125h-5.012c-.082 5.266-3.148 9.824-7.539 12.063l7.746 11.273h6.297l-7.043-10.238C16.52 9.78 18.508 5.14 18.59.125zM98.12 7.668a.92.92 0 01.91.914c0 .496-.414.91-.91.91a.919.919 0 01-.91-.91c0-.5.414-.914.91-.914zm0 2.031c.621 0 1.117-.496 1.117-1.117 0-.625-.496-1.121-1.117-1.121s-1.117.496-1.117 1.121c0 .621.496 1.117 1.117 1.117zm0 0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "103",
      "height": "24",
      "viewBox": "0 0 103 24"
    }
  };
});