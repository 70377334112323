define("ember-svg-jar/inlined/lock-closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.25 16.5a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v3z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.25 7a3.75 3.75 0 117.5 0v3h2.75a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h2.75V7zm6 0v3h-4.5V7a2.25 2.25 0 014.5 0zM6.5 11.5v7h11v-7h-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});