define("ember-svg-jar/inlined/language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.25 4a.5.5 0 01.5.5V6h3.75a.5.5 0 01.5.5V7a.5.5 0 01-.5.5l-1.809.002c-.366 1.53-.896 2.869-1.587 4.01l-.212.335.269.112c.64.245 1.366.382 2.185.412l.12.001 1.079-2.37a.5.5 0 01.91 0l4.224 9.291a.5.5 0 01-.455.707h-.552a.5.5 0 01-.455-.293l-1.003-2.208h-4.428l-1.003 2.208a.5.5 0 01-.455.293h-.551a.5.5 0 01-.456-.707l2.48-5.454c-.942-.082-1.793-.293-2.549-.635l-.295-.143-.255.272A9.408 9.408 0 016.28 15.56l-.367.132-.09.021a.51.51 0 01-.517-.276l-.034-.087-.138-.482-.017-.085a.496.496 0 01.263-.486l.081-.036.342-.125a7.954 7.954 0 002.65-1.679l.248-.248-.234-.217a6.715 6.715 0 01-1.021-1.278l-.174-.295-.037-.085a.51.51 0 01.188-.56l.08-.048.449-.214.082-.03a.504.504 0 01.524.177l.05.072.149.248c.255.402.547.752.878 1.052.648-.982 1.152-2.156 1.511-3.528L5.5 7.5A.5.5 0 015 7v-.5a.5.5 0 01.5-.5h3.75V4.5a.5.5 0 01.5-.5h.5zM15 12.625l-1.534 3.374h3.066L15 12.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});