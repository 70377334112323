define("ember-svg-jar/inlined/oppwa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M74.33 25.53l6.677-9.274-5.444-9.276 5.68-.007s1.505 2.212 2.825 4.805c1.808 3.553 2.112 4.469 2.112 4.469s-.425.915-2.706 4.467a82.741 82.741 0 01-3.347 4.807zm14.993-9.283s1.113 2.067 2.34 4.228c1.201 2.114 3.104 5.046 3.104 5.046h-5.335l-3.252-5.473c1.908-3.002 2.28-3.801 2.28-3.801s-.281-.846-1.905-4.06a132.21 132.21 0 011.983-2.768c.939-1.257 1.902-2.447 1.902-2.447H96s-1.324 1.674-3.415 4.58c-2.092 2.905-3.262 4.695-3.262 4.695zM29.449 6.28c4.389 0 7.023 3.178 7.023 7.61 0 6.797-3.336 12.19-9.587 12.19-4.39 0-7.023-3.14-7.023-7.61 0-6.796 3.336-12.19 9.587-12.19zm-2.192 15.626c3.02 0 4.46-4.174 4.46-7.794 0-2.031-.668-3.51-2.423-3.51-3.09 0-4.46 4.655-4.46 8.238 0 2.217.772 3.066 2.423 3.066zM12.537 6.281c2.984 0 5.021 1.552 5.021 4.84 0 .997-.14 2.179-.387 3.582l-1.79 10.898h-4.74l1.614-9.752c.247-1.44.317-2.364.317-2.956 0-1.33-.457-2.29-1.967-2.29-2.177 0-3.687 1.663-4.39 5.948L4.741 25.6H0L4.27 0h4.737L7.514 8.83c1.16-1.403 2.775-2.549 5.022-2.549zm37.487 0c3.723 0 5.479 3.066 5.479 7.093 0 6.982-3.336 12.708-9.904 12.708-4.916 0-6.53-2.697-6.53-6.317 0-1.33.21-3.03.455-4.433L42.074 0h4.738l-1.459 8.682c.914-1.219 2.178-2.4 4.671-2.4zm-3.827 15.626c3.02 0 4.46-4.174 4.46-7.794 0-2.031-.667-3.51-2.424-3.51-3.09 0-4.46 4.655-4.46 8.238 0 2.217.773 3.066 2.424 3.066zm16.75-4.29s-.158 1.209.006 2.197c.128.765.684 2.1 2.369 2.167 2.447.097 5.03-.764 7.237-1.866l-.615 4.086c-.537.296-1.097.551-1.762.814-1.456.574-3.178 1.067-5.282 1.067-4.53 0-6.954-3.14-6.954-7.61 0-6.612 3.688-12.19 10.08-12.19 3.45 0 6.01 2.263 6.305 4.94.354 3.23-1.46 5.44-5.29 6.13-2.819.507-6.094.264-6.094.264zm3.634-3.123c2.913-.228 3.668-1.298 3.65-2.437-.02-1.284-1.058-2.281-3.224-1.97-1.63.233-3.127 1.386-3.763 4.362 0 0 1.409.196 3.337.045z\" fill=\"#d10007\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "height": "27",
      "viewBox": "0 0 96 27",
      "width": "96",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});