define("ember-svg-jar/inlined/code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.245 7.049a.5.5 0 01.392.588l-1.803 9.02a.5.5 0 01-.589.392l-.49-.098a.5.5 0 01-.392-.588l1.803-9.02a.5.5 0 01.589-.392l.49.098zM16.884 8.823l2.823 2.823a.5.5 0 010 .707l-2.823 2.824a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707L17.94 12l-2.116-2.116a.5.5 0 010-.707l.354-.354a.5.5 0 01.707 0zM7.827 8.823l.353.354a.5.5 0 010 .707L6.064 12l2.116 2.116a.5.5 0 010 .707l-.353.354a.5.5 0 01-.707 0l-2.824-2.824a.5.5 0 010-.707L7.12 8.823a.5.5 0 01.707 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});