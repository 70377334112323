define("ember-svg-jar/inlined/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.706 15.634L12 19.268l6.294-3.634V8.366L12 4.732 5.706 8.366v7.268zm6.544-12.49a.5.5 0 00-.5 0L4.456 7.356a.5.5 0 00-.25.433v8.422a.5.5 0 00.25.433l7.294 4.212a.5.5 0 00.5 0l7.294-4.212a.5.5 0 00.25-.433V7.79a.5.5 0 00-.25-.433L12.25 3.144z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 1.5a3 3 0 100-6 3 3 0 000 6z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});