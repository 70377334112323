define("ember-svg-jar/inlined/newsletter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#00172f\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M55.897 41.937v-3.875s0-1.291-1.334-1.291h-.446v-9.063s.114-2.4-1.254-1.51c-1.649 1.08-6.006 7.984-14.335 7.984H28.116c-2.258 0-3.116 1.703-3.116 2.996v5.643c0 1.294.945 2.997 3.116 2.997h2.05s1.626 7.755 3.65 7.755c2.028 0 3.79.15 3.878-1.096.091-1.249-2.096-4.72-2.096-6.66h2.926c8.33 0 12.687 6.91 14.336 7.983 1.367.895 1.254-1.507 1.254-1.507v-9.059h.445c1.338-.001 1.338-1.297 1.338-1.297\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80"
    }
  };
});