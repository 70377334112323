define("ember-svg-jar/inlined/gender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.354 5.854l1.792 1.792a.5.5 0 01-.353.854H16.75v2.07a4.001 4.001 0 11-1.5 0V8.5h-1.043a.5.5 0 01-.353-.854l1.792-1.792a.5.5 0 01.708 0zM8 5.5a4 4 0 01.75 7.93V15H10a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H8.75v1a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1H6a.5.5 0 01-.5-.5v-.5A.5.5 0 016 15h1.25v-1.57A4.001 4.001 0 018 5.5zm8 6.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM8 7a2.5 2.5 0 100 5 2.5 2.5 0 000-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});