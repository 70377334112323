define("ember-svg-jar/inlined/watch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 20a8 8 0 110-16 8 8 0 010 16zm0-1.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm3.588-4.716l-.25.432a.5.5 0 01-.683.183L11.5 12.577a.5.5 0 01-.25-.433V8.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v3.067l2.655 1.533a.5.5 0 01.183.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});