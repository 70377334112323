define("@additive-apps/ui/utils/pages-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculatePagesAroundCurrentPage = void 0;
  /**
   * This function calculates the proper start and end page with given total, currentPage and length.
   * It handles overflowing and underflowing of first or last page (See tests for details).
   *
   * @param {number} total         The total amount of pages
   * @param {number} currentPage   The current page where you want to calculate pages around
   * @param {number} length        The length of pages should be calculated around
   * @function calculatePagesAroundCurrentPage
   * @return {[Number startPage, Number endPage]} Array of pagenumbers from startPage to endPage
   */
  const calculatePagesAroundCurrentPage = (total, currentPage, length) => {
    if (total < currentPage || total < 1 || currentPage < 1 || total <= length) {
      return {
        startPage: 1,
        endPage: 1
      };
    }

    // Ignore last page, as its fixed
    const lastDynamicPage = total - 1;
    // Remove the currentPage element from calculation: -1.
    // And calculate how many pages we have to add on each side (before currentPage and after currentPage)
    const pagesAroundCurrent = Math.floor((length - 1) / 2);
    let startPage = currentPage - pagesAroundCurrent;
    let endPage = currentPage + pagesAroundCurrent;

    // Check for underflow
    if (startPage < 2) {
      // Add the amount of pages which would have underflown the startPage to the endPage
      endPage = endPage + (2 - startPage);
      // Ignore first page, as its fixed
      startPage = 2;
    }

    // Check for overflow
    if (endPage > total - 1) {
      // Add the amount of pages which would have overflown the endPage to the startPage
      startPage = startPage - (endPage - lastDynamicPage);
      endPage = lastDynamicPage;
    }
    return {
      startPage,
      endPage
    };
  };
  _exports.calculatePagesAroundCurrentPage = calculatePagesAroundCurrentPage;
});