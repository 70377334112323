define("ember-svg-jar/inlined/plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.333 7.371l4.934 3.278-1.685 2.106-1.5 2-2.729-.484a.3.3 0 00-.263.509l2.492 2.475 2.506 2.523a.3.3 0 00.507-.267l-.513-2.756 2-1.5 2.115-1.692 3.31 4.982a.5.5 0 00.77.077l.563-.563a.5.5 0 00.12-.51l-2.081-6.316c.71-.722 1.351-1.503 1.876-2.371.826-1.367 1.517-2.937.857-3.597-.642-.642-2.158-.013-3.51.773-.92.536-1.745 1.203-2.505 1.945L6.329 5.917a.5.5 0 00-.51.122l-.563.562a.5.5 0 00.077.77zm3.249 7.884l1.581-1.186 2.7-2.16c1.469-1.175 2.74-2.386 3.608-3.823a9.763 9.763 0 00.727-1.416c-.389.148-.85.379-1.34.665-1.514.88-2.783 2.205-4.012 3.74l-2.078 2.599-1.186 1.581z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});