define("@additive-apps/ui/utils/chart-draw-value-plugin", ["exports", "chart.js/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chartDrawValuePlugin = void 0;
  /**
   * Draws the value over the bars of the chart
   *
   * @type {Object} chartDrawValuePlugin
   */
  const chartDrawValuePlugin = _exports.chartDrawValuePlugin = {
    afterDatasetDraw: chart => {
      const ctx = chart && chart.ctx;
      ctx.font = (0, _helpers.fontString)(14, '500', 'Barlow');
      ctx.fillStyle = '#00172F';
      ctx.textAlign = 'left';
      ctx.textBaseline = 'center';
      const options = chart.config && chart.config && chart.config.options;
      const formatFunction = options && options.formatFunction;
      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart._metasets[i];
        meta.data.forEach((bar, index) => {
          let data = dataset.data[index];
          if (formatFunction && typeof formatFunction === 'function') {
            data = formatFunction(data);
          }
          ctx.fillText(data, chart.chartArea.left + 5, bar.y + 5);
        });
      });
    }
  };
});