define("@additive-apps/ui/components/ui-accordion", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _object, _component2, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mb1 border ba b--white-10 bg-secondary--10 bg-secondary--20--hover br1">
    {{! template-lint-disable no-invalid-interactive }}
    <div
      class="flex items-center pv2 mh3 br1 {{if (or this.isSelectable this.isExpandable) "cpointer"}}"
      {{on "click" this.toggleAccordion}}
    >
      <div class="flex items-center w-100 break-all">
        {{#if this.isDraggable}}
          <div class="min-w-1">
            {{svg-jar "drag" width="20" height="20" class="drag-start-container mr2 cgrab secondary"}}
          </div>
        {{/if}}
        <div class="flex flex-column">
          <div class="font-sm">
            {{this.title}}
          </div>
          {{#if this.description}}
            <div class="font-sm secondary">
              {{this.description}}
            </div>
          {{/if}}
        </div>
      </div>
      <div class="flex w-50">
        <span class="flex-grow-1"></span>
  
        {{#if this.isSelectable}}
          <UiRadioButton @value={{this.value}} @selected={{this.selectedValue}} />
        {{/if}}
      </div>
    </div>
    {{#if (and this.isExpandable this._isOpen)}}
      <div class="mh3">
        {{yield}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "7YEbCCVA",
    "block": "[[[10,0],[14,0,\"mb1 border ba b--white-10 bg-secondary--10 bg-secondary--20--hover br1\"],[12],[1,\"\\n\"],[1,\"  \"],[11,0],[16,0,[29,[\"flex items-center pv2 mh3 br1 \",[52,[28,[37,1],[[30,0,[\"isSelectable\"]],[30,0,[\"isExpandable\"]]],null],\"cpointer\"]]]],[4,[38,2],[\"click\",[30,0,[\"toggleAccordion\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center w-100 break-all\"],[12],[1,\"\\n\"],[41,[30,0,[\"isDraggable\"]],[[[1,\"        \"],[10,0],[14,0,\"min-w-1\"],[12],[1,\"\\n          \"],[1,[28,[35,3],[\"drag\"],[[\"width\",\"height\",\"class\"],[\"20\",\"20\",\"drag-start-container mr2 cgrab secondary\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,0],[14,0,\"flex flex-column\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"font-sm\"],[12],[1,\"\\n          \"],[1,[30,0,[\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"description\"]],[[[1,\"          \"],[10,0],[14,0,\"font-sm secondary\"],[12],[1,\"\\n            \"],[1,[30,0,[\"description\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex w-50\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"flex-grow-1\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isSelectable\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@value\",\"@selected\"],[[30,0,[\"value\"]],[30,0,[\"selectedValue\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"isExpandable\"]],[30,0,[\"_isOpen\"]]],null],[[[1,\"    \"],[10,0],[14,0,\"mh3\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&default\"],false,[\"if\",\"or\",\"on\",\"svg-jar\",\"ui-radio-button\",\"and\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-accordion.hbs",
    "isStrictMode": false
  });
  let UiAccordion = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec4 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.string), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiAccordion extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "title", _descriptor, this);
      _initializerDefineProperty(this, "description", _descriptor2, this);
      _initializerDefineProperty(this, "isExpandable", _descriptor3, this);
      _initializerDefineProperty(this, "isSelectable", _descriptor4, this);
      _initializerDefineProperty(this, "selectedValue", _descriptor5, this);
      _initializerDefineProperty(this, "value", _descriptor6, this);
      _initializerDefineProperty(this, "_isOpen", _descriptor7, this);
      _initializerDefineProperty(this, "onSelect", _descriptor8, this);
    }
    toggleAccordion(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isExpandable && (this._isOpen = !this._isOpen);
      this.onSelect(this.value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isExpandable", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSelectable", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedValue", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_isOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onSelect", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleAccordion", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAccordion"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiAccordion);
});