define("ember-svg-jar/inlined/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 20a8 8 0 110-16 8 8 0 010 16zm0-1.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm.24-4.5h-.5a.5.5 0 01-.5-.5v-.27c0-.962.589-1.778 1.43-2.245.4-.222.57-.517.57-.985a1.25 1.25 0 00-2.448-.354.492.492 0 01-.472.354h-.74c-.31 0-.33-.275-.31-.41a2.76 2.76 0 013.518-2.227c1.194.338 2 1.528 1.95 2.767-.038.95-.496 1.695-1.33 2.162a1.273 1.273 0 00-.667 1.105v.103a.5.5 0 01-.5.5zm-.49 1.25h.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24",
      "width": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});