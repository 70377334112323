define("@additive-apps/ui/components/ui-datepicker/nav", ["exports", "ember-power-calendar/components/power-calendar/nav", "@additive-apps/ui/templates/components/ui-datepicker/nav", "date-fns", "@ember/object"], function (_exports, _nav, _nav2, _dateFns, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Custom nav component for the ember-power-calendar
   *
   * @class ui-datepicker/nav
   * @extends power-calendar/nav
   */
  var _default = _exports.default = _nav.default.extend({
    layout: _nav2.default,
    tagName: 'nav',
    classNames: ['ui-datepicker__nav', 'flex', 'items-center', 'pv1'],
    /**
     *  the range of selectable years starting from `today - yearRange / 2`
     *
     * @property yearRange
     * @type {Number}
     * @default null
     */
    yearRange: null,
    /**
     * the lowest date that is selectable
     *
     * @property minDate
     * @type {Date}
     * @default null
     */
    minDate: null,
    /**
     * the highest date that is selectable
     *
     * @property maxDate
     * @type {Date}
     * @default null
     */
    maxDate: null,
    /**
     * the list of selectable years
     *
     * @property years
     * @type {[Number]}
     * @default undefined
     */
    _years: undefined,
    init() {
      this._super(...arguments);
      const yearRange = this.yearRange || 100;
      const currentYear = (0, _dateFns.getYear)(new Date());
      const maxYear = this.maxDate ? (0, _dateFns.getYear)(this.maxDate) : currentYear + yearRange / 2;
      const minYear = this.minDate ? (0, _dateFns.getYear)(this.minDate) : currentYear - yearRange / 2;
      const range = maxYear - minYear + 1;
      const years = Array(...Array(range)).map((_, i) => `${i + minYear}`);
      (0, _object.set)(this, '_years', years);
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      changeCenter(calendar, e) {
        const newCenter = (0, _dateFns.setYear)(calendar.center, e.target.value);
        calendar.actions.changeCenter(newCenter);
      }
    }
  });
});