define("@additive-apps/ui/templates/components/ui-discard-changes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SbWOvVQJ",
    "block": "[[[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@size\",\"@title\",\"@onClose\"],[\"sm\",[28,[37,2],[\"uiDiscardChanges.title\"],null],[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"isOpen\"]]],null],false],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,1,[\"header\"]]],[1,\"\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"uiDiscardChanges.message\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n    \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@label\",\"@isSecondary\",\"@onClick\",\"@icon\",\"@class\"],[[28,[37,2],[\"uiDiscardChanges.discardAction\"],null],true,[28,[37,3],[[30,0],[30,0,[\"onDiscard\"]]],null],\"delete\",\"mr1\"]],null],[1,\"\\n      \"],[8,[39,5],null,[[\"@label\",\"@onClick\",\"@icon\"],[[28,[37,2],[\"dialogService.discardChanges.continueEditing\"],null],[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"isOpen\"]]],null],false],null],\"edit\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\",\"content\"],false,[\"if\",\"ui-modal\",\"t\",\"action\",\"mut\",\"ui-button\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-discard-changes.hbs",
    "isStrictMode": false
  });
});