define("ember-svg-jar/inlined/nexi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M88.1.8h-6.4v25.1h6.4zM12.3.1C6.3.1 0 2.2 0 2.2V26h6.6V6.6s2.3-1 5.8-1c4.3 0 6 2.3 6 5.9v14.4h6.5V11.5C24.9 3.8 21.3.1 12.3.1m67.6.7H72l-6 7.4L62.8 4C61.2 1.9 58.5.7 55.9.7H52l9.9 12.5-10.2 12.7h7.8l6.4-7.8 3.7 4.6c1.6 2.1 4.3 3.2 6.9 3.2h3.8L70 13z\" fill=\"#2d32aa\"/><path d=\"M40.4 5.5c2.9 0 5.3 1.3 6.2 3.6l-13 2.3c.7-3.8 3.4-5.9 6.8-5.9m12.4 15.6l-4.3-3.5c-1.4 1.6-3.7 3.5-7.5 3.5-3 0-5.7-1.6-6.9-4.4l19.3-3.4A13.22 13.22 0 0052.3 8c-2-4.7-6.5-8-12.1-8-7.4 0-13.1 5.3-13.1 13.3 0 7.8 5.6 13.3 13.8 13.3 6.3.1 10-3.1 11.9-5.5\" fill=\"#2d32aa\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "88.1",
      "height": "26.602"
    }
  };
});