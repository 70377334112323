define("@additive-apps/ui/components/ui-selectable", ["exports", "@ember/object/computed", "@ember/component", "@additive-apps/ui/templates/components/ui-selectable", "@ember/object", "@ember/array", "@ember/string", "@ember/runloop", "@additive-apps/ui/utils/dom-util", "@additive-apps/ui/utils/function-util", "@additive-apps/utils/utils/viewport"], function (_exports, _computed, _component, _uiSelectable, _object, _array, _string, _runloop, _domUtil, _functionUtil, _viewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * HOC component that handles select and multiselect options
   *
   * ```hbs
   * {{#ui-selectable
   *   options
   *   initialValue
   *   selected
   *   isMultiSelect
   *   onOpen=(action)
   *   onToggle=(action)
   *   as |selectable|
   * }}
   *   {{selectable.trigger}}
   *   {{selectable.content}}
   * {{/ui-selectable}}
   * ```
   *
   * @class ui-selectable
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiSelectable.default,
    /**
     * array of booleans defining whether a certain option is checked
     *
     * @property checked
     * @type {Boolean[]}
     */
    checked: undefined,
    /**
     * when specified this custom offset is subtracted from the calculated one
     *
     * @property customOffset
     * @type {Number}
     * @default null
     */
    customOffset: null,
    /**
     * the value, we want the selected to be initialized with
     *
     * @property initialValue
     * @type {any}
     * @default undefined
     */
    initialValue: undefined,
    /**
     * whether the options can be collapsed
     *
     * @argument isCollapsible
     * @type {Boolean}
     * @default false
     */
    isCollapsible: false,
    /**
     *  whether the dropdown should have a dynamic min-width
     *
     * @property isAutoWidth
     * @type {Boolean}
     * @default false
     */
    isAutoWidth: false,
    /**
     * whether multiple options can be selected
     *
     * @property isMultiSelect
     * @type {Boolean}
     * @default false
     */
    isMultiSelect: false,
    /**
     * property that indicates if the select is open or not
     *
     * @property isOpen
     * @type {Boolean}
     * @default false
     */
    isOpen: false,
    /**
     * set to true after dropdown is rendered, starts animation
     *
     * @property isEntering
     * @type {Boolean}
     * @default false
     */
    isEntering: false,
    /**
     * hide content only on outside click (not on trigger)
     *
     * @property isOnlyClosingOnOutsideClick
     * @type {Boolean}
     * @default false
     */
    isOnlyClosingOnOutsideClick: false,
    /**
     *  the key of the property in the options containing a string, which can be
     *  displayed in the input field representing the selected value
     *
     * @property objectKey
     * @type {String}
     * @default undefined
     */
    objectKey: 'name',
    /**
     * the key of the property in the options containing the value
     *
     * @property valueKey
     * @type {String}
     * @default 'value'
     */
    valueKey: 'value',
    /**
     * the options array
     *
     * @argument options
     * @type {Array}
     * @default null
     */
    options: null,
    /**
     * the current search value
     *
     * @argument searchValue
     * @type {String}
     * @default ''
     */
    searchValue: '',
    /**
     * The selected option
     *
     * @property selected
     * @type {any}
     */
    selected: undefined,
    /**
     * whether the select is read only
     *
     * @property isReadOnly
     * @type {Boolean}
     * @default true
     */
    isReadOnly: false,
    /**
     * whether the the select should automatically scroll to an option
     * when a key is pressed
     *
     * @property scrollToOption
     * @type {Boolean}
     * @default false
     */
    scrollToOption: false,
    /**
     * the value that gets passed to all child options.
     *
     * is used to determinate if a certain option is selected or not,
     * in case the actual value differs from the displays 'selected' option.
     *
     * @property _value
     * @type {any}
     * @default undefined
     * @private
     */
    _value: undefined,
    /**
     * the next parent with scroll
     *
     * @property _parentWithScroll
     * @type {Element}
     * @default null
     * @private
     */
    _parentWithScroll: null,
    /**
     * the search input in the options
     *
     * @property _searchInput
     * @type {Element}
     * @default null
     * @private
     */
    _searchInput: null,
    /**
     * internal copy of selected options containing only the values that need
     * to be displayed
     *
     * @property _selected
     * @type {String[]}
     * @default undefined
     * @private
     */
    _selected: undefined,
    /**
     * The current style values of the options container
     *
     * @property _style
     * @type {String[]}
     * @default undefined
     * @private
     */
    _style: undefined,
    /**
     *  The current initialValue value
     *
     * @property initialValue
     * @type {any}
     * @default undefined
     * @private
     */
    _initialValue: undefined,
    /**
     *  The initial options
     *
     * @property _initialOptions
     * @type {Array}
     * @default undefined
     * @private
     */
    _initialOptions: undefined,
    /**
     * whether there are selected options
     *
     * @property hasSelectedOptions
     * @type {Boolean}
     * @default undefined
     */
    hasSelectedOptions: (0, _computed.gt)('_selected.length', 0),
    /**
     * html safe formatted text displaying the selected options
     *
     * @property selectedText
     * @type {String}
     * @default undefined
     */
    selectedText: (0, _object.computed)('_initialOptions', '_selected.[]', 'isCollapsible', 'objectKey', 'options', 'selected', 'valueKey', {
      get() {
        let selected = this._selected;
        if (this.isCollapsible && this.selected && this._initialOptions) {
          selected = [];
          this.selected.forEach(selectedOption => {
            const option = this._initialOptions.find(option => option[this.valueKey] === selectedOption[this.valueKey]);

            // Only show selected options which doesn't have sub-options
            if (option && (!option.subData || option.subData.length === 0)) {
              selected.push(option[this.objectKey]);
            }
          });
        }
        return (0, _string.htmlSafe)((0, _array.isArray)(selected) ? selected.join(', ') : selected);
      }
    }),
    /**
     * html safe formatted text displaying the selected options
     *
     * @property selectedText
     * @type {String}
     * @default undefined
     */
    style: (0, _object.computed)('_style', {
      get() {
        const style = this._style || {};
        let styleString = '';
        Object.keys(style).forEach(key => styleString += `${key}: ${style[key]};`);
        return (0, _string.htmlSafe)(styleString);
      }
    }),
    /**
     * whether all options are selected
     *
     * @property isAllSelected
     * @type {Boolean}
     * @default undefined
     */
    isAllSelected: (0, _object.computed)('_selected.[]', 'options.[]', {
      get() {
        var _this$_selected, _this$options;
        return ((_this$_selected = this._selected) === null || _this$_selected === void 0 ? void 0 : _this$_selected.length) === ((_this$options = this.options) === null || _this$options === void 0 ? void 0 : _this$options.length);
      }
    }),
    /**
     * triggered when an option is selected
     *
     * @event onChange
     * @param {Any} option the clicked option
     * @param {Event} event The click event
     * @return {Array}
     */
    onChange() {},
    /**
     * triggered when the search input changes
     *
     * @event onSearch
     * @param {Event} value The search value
     * @return {Array}
     */
    onSearch() {},
    /**
     * triggered when the options container is closed
     *
     * @function onClose
     */
    onClose() {},
    /**
     * triggered when the options container is toggled
     *
     * @function onToggle
     */
    onToggle() {},
    /**
     * triggered when the user clicks on the deselect all checkbox
     *
     * @function onDeselectAll
     */
    onDeselectAll() {},
    /**
     * triggered when the options container is opened
     *
     * @function onOpen
     */
    onOpen() {},
    /**
     * triggered when the user clicks outside of the options
     * container
     *
     * @function onOutsideClick
     */
    onOutsideClick() {},
    /**
     * triggered when the user clicks on the select all checkbox
     *
     * @function onSelectAll
     */
    onSelectAll() {},
    init() {
      this._super(...arguments);

      // preselect options if they are defined
      if (this.options && this.options.length > 0) {
        this._preselectOptions();
      }
      (0, _object.setProperties)(this, {
        _previousOptions: this.options,
        _initialValue: this.initialValue
      });
      this._clickListener = e => {
        if (document) {
          const selectOptions = document.querySelector('.options-container');
          // if click is outside close options container
          if (!this.isDestroying && selectOptions && !selectOptions.contains(e.target) && !this.element.contains(e.target) && this.isOpen) {
            this.onOutsideClick();
            this._close();
          }
        }
      };
      this.selectAll = (0, _runloop.bind)(this, this.selectAll);
      this.deselectAll = (0, _runloop.bind)(this, this.deselectAll);
      if (window) {
        this.close = (0, _runloop.bind)(this, this._close);
        this.clickListener = (0, _runloop.bind)(this, this._clickListener);
        window.addEventListener('mouseup', this.clickListener, false);
        window.addEventListener('orientationchange', this.close, false);
        window.addEventListener('resize', this.close, false);
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      if (document) {
        // for now we use the dropdown wormhole to avoid to many top-level divs
        (0, _object.set)(this, 'destinationEl', document.querySelector('#ember-basic-dropdown-wormhole'));
        const triggerElement = this.element.querySelector('.ui-selectable-trigger') || this.element;
        (0, _object.set)(this, '_triggerElement', triggerElement);
        if (this.scrollToOption || this.isSearchable) {
          this._keyUpEvent = (0, _runloop.bind)(this, this._keyUpEvent);
          document.addEventListener('keyup', this._keyUpEvent, false);
        }
      }

      // find closest parent with scroll bar
      let parent = this.element.parentElement;
      while (!this._parentWithScroll && parent) {
        if (parent.scrollHeight > parent.clientHeight) {
          (0, _object.set)(this, '_parentWithScroll', parent);
          break;
        }
        parent = parent.parentElement;
      }
      this._onScroll = (0, _runloop.bind)(this, this._onScroll);
      if (this._parentWithScroll) {
        this._parentWithScroll.addEventListener('mousewheel', this._onScroll, false);
      } else if (window) {
        window.addEventListener('mousewheel', this._onScroll, false);
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super();
      // preselect options as soon as they are defined
      if (this.options !== this._previousOptions) {
        this._preselectOptions();
        (0, _object.set)(this, '_previousOptions', this.options);
        if (this._initialOptions && !this.isCollapsible) {
          (0, _object.set)(this, '_initialOptions', this.options);
        }
      }
      if (this.isMultiSelect && (0, _functionUtil.emberStringify)(this.selected) !== (0, _functionUtil.emberStringify)(this._selected)) {
        this._preselectOptions();
      }
      if ((0, _functionUtil.emberStringify)(this.initialValue) !== (0, _functionUtil.emberStringify)(this._initialValue)) {
        this._preselectOptions();
        (0, _object.set)(this, '_initialValue', this.initialValue);
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      if (window) {
        window.removeEventListener('click', this.clickListener, false);
        window.removeEventListener('orientationchange', this.close, false);
        window.removeEventListener('resize', this.close, false);
      }
      if (this._parentWithScroll) {
        this._parentWithScroll.removeEventListener('mousewheel', this._onScroll, false);
      } else if (window) {
        window.removeEventListener('mousewheel', this._onScroll, false);
      }
      if ((this.scrollToOption || this.isSearchable) && document) {
        document.removeEventListener('keyup', this._keyUpEvent, false);
      }
    },
    /**
     * Returns the proper keyup event handler
     *
     * @function _keyUpEvent
     * @param {Event} e
     */
    _keyUpEvent(e) {
      if (this.isOpen) {
        if (this.isSearchable) {
          return this._searchOptions(e);
        } else {
          return this._scrollToOption(e);
        }
      }
    },
    /**
     * Scrolls to the first option starting with the
     * pressed key
     *
     * @function _scrollToOption
     * @param {Event} e
     */
    _scrollToOption(e) {
      // find option starting with pressed key
      const regex = new RegExp(`^${e.key}`, 'i');
      const option = this.options.find(option => regex.test(option[this.objectKey]));
      if (!option) return;
      const container = document.getElementById(`options-container-${this.elementId}`);
      if (container) {
        const optionElement = container.querySelector(`[value="${option[this.objectKey]}"]`) || container.querySelector(`[value='${option.value}']`);
        optionElement && optionElement.scrollIntoView();
      }
    },
    /**
     * Focuses the search input and inserts the pressed key as search value
     * Triggers onSearch
     *
     * @function _searchOptions
     * @param {Event} e
     */
    _searchOptions(e) {
      // if search input was not focused and letter key is pressed, focus input and insert pressed key as search value
      if (this._searchInput && this._searchInput !== document.activeElement && e.key && e.key.length === 1) {
        this._searchInput.focus();

        // append pressed key to search value
        (0, _object.set)(this, 'searchValue', `${this.searchValue}${e.key}`);
      }
    },
    /**
     * selects all options
     *
     * @function selectAll
     */
    selectAll() {
      const options = this.options;
      const checked = this.checked;
      const selected = options.map((option, index) => {
        (0, _object.set)(checked, index.toString(), true);
        this.onChange(option, true);
        return option[this.objectKey];
      });
      (0, _object.set)(this, '_selected', (0, _array.A)(selected));
      this.onSelectAll((0, _array.A)(selected));
    },
    /**
     * deselects all options
     *
     * @function deselectAll
     */
    deselectAll() {
      const options = this.options;
      const checked = this.checked;

      // deselect all options
      options.forEach((option, index) => {
        (0, _object.set)(checked, index.toString(), false);
        this.onChange(option, false);
      });
      (0, _object.set)(this, '_selected', (0, _array.A)());
      this.onDeselectAll((0, _array.A)());
    },
    /**
     * preselects initial options
     *
     * @function _preselectOptions
     * @private
     */
    _preselectOptions() {
      // keep copy of initial options
      if (!this._initialOptions && (0, _array.isArray)(this.options) && this.options.length > 0) {
        (0, _object.set)(this, '_initialOptions', this.options.slice(0));
      }
      (0, _object.set)(this, '_value', this.initialValue);

      // Unselect parent option if no sub-option is selected
      if (this.isCollapsible && this.selected) {
        const parentOptions = this.selected.filter(item => item.subData.length > 0);
        parentOptions.forEach(option => {
          let hasSelectedSubOptions = false;
          option.subData.forEach(subOption => {
            if (this.selected.find(item => item[this.valueKey] === subOption[this.valueKey])) {
              hasSelectedSubOptions = true;
              return;
            }
          });
          if (!hasSelectedSubOptions) {
            const selected = this.selected;
            const index = this.selected.indexOf(option);
            selected.splice(index, 1);
            (0, _object.set)(this, 'selected', selected);
          }
        });
      }

      // Select the parent option if a sub-option is selected
      if (this.isCollapsible && this.selected) {
        const allParentOptions = this.options.filter(item => {
          var _item$subData;
          return ((_item$subData = item.subData) === null || _item$subData === void 0 ? void 0 : _item$subData.length) > 0;
        });
        allParentOptions.forEach(parentOption => {
          let hasParentOption = false;
          parentOption.subData.forEach(subOption => {
            if (this.selected.find(item => item[this.valueKey] === subOption[this.valueKey])) {
              hasParentOption = true;
              return;
            }
          });

          // Check if parent was already pushed to selected values
          const isParentSelected = this.selected.filter(item => item[this.valueKey] === parentOption[this.valueKey]).length > 0;
          if (hasParentOption && !isParentSelected) {
            const selected = this.selected;
            selected.push(parentOption);
            (0, _object.set)(this, 'selected', selected);
          }
        });
      }
      if (this.isMultiSelect && this._initialOptions) {
        // if selected options are defined, they get preselected
        let selectedOptions = [];
        if (this.selected && this.selected.length > 0) {
          selectedOptions = this.selected.map(option => {
            const selectedOption = this._initialOptions.find(_selectedOption => {
              return _selectedOption[this.valueKey] === option[this.valueKey];
            });
            return {
              value: selectedOption && selectedOption[this.valueKey],
              key: selectedOption && selectedOption[this.objectKey]
            };
          });
          (0, _object.set)(this, '_selected', (0, _array.A)(selectedOptions.map(opt => opt.key)));
        } else if ((!this.selected || this.selected.length === 0) && this._selected) {
          // if selected options are not defined, unselect options
          (0, _object.set)(this, '_selected', (0, _array.A)([]));
        }

        // instantiate array with same length of options to store isChecked values
        const checked = (0, _array.A)();
        if (selectedOptions && selectedOptions.length > 0) {
          const options = this.isCollapsible && this._initialOptions ? this._initialOptions : this.options;
          selectedOptions.map(option => {
            const selectedOption = options && options.find(_selectedOption => {
              return _selectedOption[this.valueKey] === option.value;
            });
            const index = options.indexOf(selectedOption);
            index != -1 && (0, _object.set)(checked, index.toString(), true);
          });
        }
        (0, _object.set)(this, 'checked', checked);
      }
    },
    /**
     * calculates the position of the dropdown and sets it using the style attribute
     *
     * @function _calculatePosition
     */
    _calculatePosition() {
      if (window && this.element) {
        const {
          top,
          right,
          bottom,
          left,
          width
        } = this.element.getBoundingClientRect();
        const triggerWidth = this._triggerElement ? this._triggerElement.getBoundingClientRect().width : width;
        const offset = this.customOffset || -16;

        // determine whether select should have a higher z-index
        /* TODO: z-index concept */
        const isInsideDialog = document && (document.querySelector(`.ui-dialog #${this.element.id}`) || document.querySelector(`.ui-modal #${this.element.id}`) || document.querySelector(`#mm-fullscreen-view-container #${this.element.id}`) || document.querySelector(`.ui-navigation-drawer #${this.element.id}`));
        let style = {};
        style.top = `${bottom + offset}px`;
        style.left = `${left}px`;
        style['z-index'] = isInsideDialog ? 181 : 25;
        if (!this.isAutoWidth) {
          style['min-width'] = (triggerWidth > 250 ? triggerWidth : 250) + 'px';
        }
        (0, _object.set)(this, '_style', style);

        /* Re-validate position, to detect whether it overflows viewport and re-position it into viewport */
        (0, _runloop.next)(this, () => {
          if (this.isDestroying) {
            return;
          }
          const optionContainer = document.getElementById(`options-container-${this.elementId}`);
          if (!optionContainer) {
            return;
          }
          const bestPosition = (0, _viewport.detectBestPositionInViewport)(optionContainer, ['bottom', 'right']);
          const {
            height: containerHeight,
            width: containerWidth
          } = optionContainer.getBoundingClientRect();
          let style = Object.assign({}, this._style);
          if (bestPosition) {
            /* Position container in top of the element */
            if (bestPosition[0] === 'top') {
              const clientHeight = document.documentElement.clientHeight;
              // calculate bottom position
              let positionBottom = clientHeight + offset - top;

              // substract difference (plus small offset) if options container would reach outside of viewport
              if (containerHeight + positionBottom > clientHeight) {
                positionBottom -= containerHeight + positionBottom - clientHeight + 8;
              }
              style.top = 'auto';
              style.bottom = `${positionBottom}px`;
            }
            /* Position container at the left hand side of the element */
            if (bestPosition[1] === 'left') {
              const clientWidth = document.documentElement.clientWidth;
              // calculate right position
              let positionRight = clientWidth - right;

              // substract difference if options container would overflow to left
              if (containerWidth + positionRight > clientWidth) {
                positionRight -= containerWidth + positionRight - clientWidth;
              }
              style.left = 'auto';
              style.right = `${positionRight}px`;
            }
          }
          (0, _object.set)(this, '_style', style);
        });
      }
    },
    /**
     * closes the selection container
     *
     * @function _close
     */
    _close() {
      (0, _object.setProperties)(this, {
        isOpen: false,
        isEntering: false,
        searchValue: '',
        _searchInput: null
      });
      this.onClose();
    },
    /**
     * closes the selection container if the user scrolls
     * outside
     *
     * @function _onScroll
     * @event mousewheel
     */
    _onScroll(e) {
      if (document) {
        const optionsContainer = document.querySelector(`#options-container-${this.elementId}`);
        if (optionsContainer && !optionsContainer.contains(e.target)) {
          this._close();
        }
      }
    },
    _didClickOnTrigger(event) {
      if (document && event) {
        const trigger = document.querySelector(`#${this.elementId} .ui-selectable-trigger`);
        return trigger.contains(event.target);
      }
      return false;
    },
    /**
     * Used to flatten options, if it contains multiple sub-options.
     *
     * @function _flattenOptions
     */
    _flattenOptions(data) {
      let array = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      data.forEach(item => {
        var _item$subData2;
        array.push(item);
        if ((item === null || item === void 0 || (_item$subData2 = item.subData) === null || _item$subData2 === void 0 ? void 0 : _item$subData2.length) > 0) {
          this._flattenOptions(item.subData, array);
        }
      });
      return array;
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      _onSearch(event) {
        const {
          value
        } = event.target;
        if ((0, _functionUtil.hasBody)(this.onSearch)) {
          this.onSearch(value);
        }
        (0, _object.set)(this, 'searchValue', value);
      },
      _onSearchClear() {
        if ((0, _functionUtil.hasBody)(this.onSearch)) {
          this.onSearch('');
        }
        (0, _object.set)(this, 'searchValue', '');
      },
      toggle(event) {
        if (this.isReadOnly) {
          return;
        }
        if (this.isOpen && !this.isOnlyClosingOnOutsideClick || this.isOpen && this.isOnlyClosingOnOutsideClick && !this._didClickOnTrigger(event)) {
          this._close();
        } else {
          this.onOpen(event);
          (0, _object.set)(this, 'isOpen', true);
          (0, _domUtil.nextTick)().then(() => {
            if (this.isDestroying) {
              return;
            }
            this._calculatePosition();
            (0, _object.set)(this, 'isEntering', true);
            if (this.isSearchable) {
              const searchInput = this.destinationEl.querySelector(`.options-container .ui-selectable-search-wrapper input`);
              (0, _object.set)(this, '_searchInput', searchInput);
            }
          });
        }
        this.onToggle(event);
      },
      selectOption(option) {
        // if it is a multi-select the selected options are added to the selected array and a
        // boolean flag is set in the checked array
        if (this.isMultiSelect) {
          let options = this.options;
          const checked = this.checked;
          if (this.isCollapsible && this._initialOptions) {
            options = this._initialOptions;
          }
          const index = options.indexOf(option);
          const isChecked = checked[index];
          if (this.isCollapsible && option.subData) {
            // Parent option was selected
            // Get all sub-options and select/unselect them
            const allOptions = this._flattenOptions([option]);
            allOptions.forEach(subOption => {
              const index = options.indexOf(subOption);
              (0, _object.set)(checked, index.toString(), !isChecked);

              /*
               * Create unmutable copy and ensure selected is an ember-array,
               * as we use use `removeObject` and `pushObject` which are only available for ember-array
               */
              const selected = (0, _array.A)(this._selected || []);
              const textOption = subOption[this.objectKey];
              if (isChecked) {
                selected.removeObject(textOption);
              } else {
                selected.pushObject(textOption);
              }
              (0, _object.set)(this, '_selected', selected);
              this.onChange(subOption, !isChecked);
            });
            return;
          }
          (0, _object.set)(checked, index.toString(), !isChecked);

          /*
           * Create unmutable copy and ensure selected is an ember-array,
           * as we use use `removeObject` and `pushObject` which are only available for ember-array
           */
          const selected = (0, _array.A)(this._selected || []);
          const textOption = option[this.objectKey];
          if (isChecked) {
            selected.removeObject(textOption);
          } else {
            selected.pushObject(textOption);
          }
          (0, _object.set)(this, '_selected', selected);
          this.onChange(option, !isChecked);
        } else {
          /**
           * the select is only closed if its not a multi-select and if
           * it is not the filter "custom_date"
           */
          if (option.value !== 'custom_date') this._close();
          (0, _object.set)(this, '_value', option);
          this.onChange(option);
        }
      },
      toggleAll() {
        const isAllSelected = this.isAllSelected;

        // select all options
        if (!isAllSelected) {
          this.selectAll();
        } else {
          this.deselectAll();
        }
      }
    }
  });
});