define("ember-changeset-validations/validators/format", ["exports", "@ember/utils", "ember-changeset-validations/utils/validation-errors", "ember-validators/format"], function (_exports, _utils, _validationErrors, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateFormat;
  function validateFormat() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let hasOptions = !(0, _utils.isEmpty)(Object.keys(options));
    return (key, value) => {
      if (!hasOptions) {
        return true;
      }
      let result = (0, _format.default)(value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});