define("ember-svg-jar/inlined/crossing-arrows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 6.06l1.19 1.19c-1.489 0-2.463.007-3.346.388-.902.388-1.586 1.114-2.645 2.235l-.978 1.035-1.7-1.8c-.798-.845-1.313-1.391-1.959-1.749a4.75 4.75 0 00-.817-.357c-.565-.186-1.166-.227-1.987-.243-.233-.004-.35-.006-.443.031a.502.502 0 00-.276.271C4 7.154 4 7.272 4 7.508c0 .23 0 .345.037.436.05.122.144.218.265.27.09.04.207.041.44.046.795.016 1.177.05 1.533.167.194.063.381.145.56.244.417.231.768.591 1.68 1.556L10.19 12l-1.675 1.773c-.912.965-1.263 1.325-1.68 1.556a3.25 3.25 0 01-.56.244c-.356.118-.738.151-1.533.167-.233.005-.35.007-.44.046a.497.497 0 00-.265.27c-.037.09-.037.206-.037.436 0 .236 0 .354.039.447a.502.502 0 00.276.27c.094.038.21.036.443.032.82-.016 1.422-.057 1.987-.243a4.75 4.75 0 00.817-.357c.646-.358 1.16-.904 1.96-1.75l1.7-1.799.977 1.035c1.059 1.121 1.743 1.846 2.645 2.235.883.38 1.857.388 3.345.388L17 17.94c-.164.164-.246.246-.284.338a.5.5 0 000 .383c.038.092.12.174.285.339.165.165.247.247.339.285a.5.5 0 00.383 0c.091-.038.174-.12.339-.285l2.293-2.293c.333-.333.5-.5.5-.707 0-.207-.167-.374-.5-.707L18.06 13c-.165-.165-.247-.247-.34-.285a.5.5 0 00-.382 0c-.092.038-.174.12-.34.285-.164.165-.246.247-.284.339a.5.5 0 000 .383c.038.091.12.174.285.339l1.19 1.189c-1.572-.002-2.2-.027-2.753-.265-.582-.252-1.049-.724-2.26-2.006L12.254 12l.925-.979c1.21-1.282 1.677-1.755 2.26-2.006.553-.238 1.18-.263 2.751-.265L17 9.94c-.164.164-.246.246-.284.338a.5.5 0 000 .383c.038.092.12.174.285.339.165.165.247.247.339.285a.5.5 0 00.383 0c.091-.038.174-.12.339-.285l2.293-2.293c.333-.333.5-.5.5-.707 0-.207-.167-.374-.5-.707L18.06 5c-.165-.165-.247-.247-.34-.285a.5.5 0 00-.382 0c-.092.038-.174.12-.34.285-.164.165-.246.247-.284.339a.5.5 0 000 .383c.038.092.12.174.285.339z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});