define("@additive-apps/ui/templates/components/ui-radio-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/1iN/v+0",
    "block": "[[[10,0],[14,0,\"ui-radio-button__box\"],[15,\"tabindex\",[30,0,[\"tabIndex\"]]],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"radio-button\"],[[\"class\"],[\"white\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"label\"]],[[[1,\"  \"],[10,0],[14,0,\"ui-label h-auto flex font-sm pl1\"],[12],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"svg-jar\",\"if\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-radio-button.hbs",
    "isStrictMode": false
  });
});