define("@additive-apps/configurator/components/ae-configurations/contents", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/application", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-arg-types", "ember-concurrency", "prop-types", "@ember/string", "@additive-apps/ui/constants"], function (_exports, _component, _templateFactory, _component2, _application, _object, _service, _tracking, _emberArgTypes, _emberConcurrency, _propTypes, _string, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert (perform this._fetchContents)}}>
    <UiSelect
      @valueKey="id"
      @selected={{if
        this._isMultiselect
        this._selectedContents
        (get (array-find this.item.value.id this._contents "id") "name")
      }}
      @onChange={{this.onContentSelect}}
      @onSelectAll={{fn this.onContentSelectAll true}}
      @onDeselectAll={{fn this.onContentSelectAll false}}
      @options={{this._contents}}
      @placeholder={{if
        this._isMultiselect
        (t "aeConfiguration.content.allContents")
        (t "aeConfiguration.content.selectContent")
      }}
      @isMultiSelect={{this._isMultiselect}}
      @isReadOnly={{@isReadOnly}}
      @isPlain={{true}} as |option item|
    >
      {{#if this._fetchContents.isRunning}}
        <option.left>
          <UiLoading @classNames="pa05" />
        </option.left>
      {{/if}}
      <option.item @value={{item}}>
        {{item.name}}
        {{#if (and this._isOfferType (not item.isActive))}}
          <UiBadge @class="ml1" @state="warning">
            {{t "aeConfiguration.content.expired"}}
          </UiBadge>
        {{/if}}
      </option.item>
    </UiSelect>
  </div>
  */
  {
    "id": "lyqS1gYz",
    "block": "[[[11,0],[4,[38,0],[[28,[37,1],[[30,0,[\"_fetchContents\"]]],null]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@valueKey\",\"@selected\",\"@onChange\",\"@onSelectAll\",\"@onDeselectAll\",\"@options\",\"@placeholder\",\"@isMultiSelect\",\"@isReadOnly\",\"@isPlain\"],[\"id\",[52,[30,0,[\"_isMultiselect\"]],[30,0,[\"_selectedContents\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"item\",\"value\",\"id\"]],[30,0,[\"_contents\"]],\"id\"],null],\"name\"],null]],[30,0,[\"onContentSelect\"]],[28,[37,6],[[30,0,[\"onContentSelectAll\"]],true],null],[28,[37,6],[[30,0,[\"onContentSelectAll\"]],false],null],[30,0,[\"_contents\"]],[52,[30,0,[\"_isMultiselect\"]],[28,[37,7],[\"aeConfiguration.content.allContents\"],null],[28,[37,7],[\"aeConfiguration.content.selectContent\"],null]],[30,0,[\"_isMultiselect\"]],[30,1],true]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"_fetchContents\",\"isRunning\"]],[[[1,\"      \"],[8,[30,2,[\"left\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,8],null,[[\"@classNames\"],[\"pa05\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3,[\"name\"]]],[1,\"\\n\"],[41,[28,[37,9],[[30,0,[\"_isOfferType\"]],[28,[37,10],[[30,3,[\"isActive\"]]],null]],null],[[[1,\"        \"],[8,[39,11],null,[[\"@class\",\"@state\"],[\"ml1\",\"warning\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,7],[\"aeConfiguration.content.expired\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[2,3]]]]],[1,\"\\n\"],[13]],[\"@isReadOnly\",\"option\",\"item\"],false,[\"did-insert\",\"perform\",\"ui-select\",\"if\",\"get\",\"array-find\",\"fn\",\"t\",\"ui-loading\",\"and\",\"not\",\"ui-badge\"]]",
    "moduleName": "@additive-apps/configurator/components/ae-configurations/contents.hbs",
    "isStrictMode": false
  });

  // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  let AeConfigurationsContentsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _object.computed)('_contents', 'item.value.ids'), _dec4 = (0, _emberConcurrency.task)(function* () {
    // skip fetch if already loaded
    if (this._contents) {
      return;
    }
    try {
      var _this$currentUser;
      let tasks = [];
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const contentBaseHost = ENV.APP.appId === _constants.APP_ID_CONTENT ? ENV.APP.apiBaseHost : ENV.APP.contentHost;
      const organizationSlug = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
      let url = `${contentBaseHost}/${organizationSlug}/${this._contentType}?per_page=-1`;
      if (this._contentType === 'offers') {
        url += '&timespans=1';
      } else if (this._contentType === 'last_minute_offers') {
        url += '&room=1';
      }
      const request = this.authenticatedFetch.fetch(url, {
        headers: {
          'Accept-Language': this.uiAppSettings.currentLocale
        }
      });
      tasks.push(request);
      tasks.push((0, _emberConcurrency.timeout)(400));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      if (!response || !response.ok) {
        throw Error('[CONTENT] Fetch of contents failed');
      }
      const json = yield response.json();
      if (!(json !== null && json !== void 0 && json[(0, _string.camelize)(this._contentType)])) {
        throw Error('[CONTENT] Fetch of contents failed');
      }
      const contents = json[(0, _string.camelize)(this._contentType)];
      if (this._isOfferType) {
        const now = new Date();
        contents.forEach(content => {
          content.isActive = content.timespans.some(timespan => {
            const endDate = new Date(timespan.endsAt);
            return now < endDate;
          });
        });
        contents.sort((a, b) => a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1);
      }
      this._contents = contents;
    } catch (error) {
      this._isFetchError = true;
    }
  }), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AeConfigurationsContentsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor3, this);
      _initializerDefineProperty(this, "item", _descriptor4, this);
      _initializerDefineProperty(this, "errorMessage", _descriptor5, this);
      /**
       * the fetched contents
       *
       * @argument _contents
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "_contents", _descriptor6, this);
      _defineProperty(this, "getSelectedContents", ids => {
        const publishedContents = this._contents.filter(content => !Array.isArray(content.publishedLanguages) || content.publishedLanguages.includes(this.uiAppSettings.currentLocale));
        return ids.length === 0 ? publishedContents : publishedContents.filter(content => ids.includes(content.id));
      });
      /**
       * fetches the contents
       *
       * @function _fetchContents
       * @type {Task}
       */
      _initializerDefineProperty(this, "_fetchContents", _descriptor7, this);
      _initializerDefineProperty(this, "onChange", _descriptor8, this);
    }
    get _contentType() {
      switch (this.item.type) {
        case 'offers':
        case 'offer':
          return 'offers';
        case 'rooms':
        case 'room':
          return 'rooms';
        case 'last_minute_offers':
          return 'last-minute-offers';
        case 'image_galleries':
        case 'image_gallery':
          return 'galleries';
        case 'portal_scores':
        case 'portal_score':
          return 'portal-scores';
        case 'survey':
          return 'surveys';
        default:
          return null;
      }
    }
    get _isMultiselect() {
      return ['offers', 'rooms', 'last_minute_offers', 'image_galleries', 'portal_scores'].includes(this.item.type);
    }
    get _isOfferType() {
      return this._contentType === 'offers';
    }
    get _selectedContents() {
      if (!this._contents) {
        return [];
      }
      return this._contents.filter(content => {
        var _this$item$value;
        return (((_this$item$value = this.item.value) === null || _this$item$value === void 0 ? void 0 : _this$item$value.ids) || []).includes(content.id);
      });
    }
    onContentSelect(toggledContent, checked) {
      var _this$getSelectedCont;
      if (this._isMultiselect) {
        var _this$item$value2;
        const ids = Array.isArray((_this$item$value2 = this.item.value) === null || _this$item$value2 === void 0 ? void 0 : _this$item$value2.ids) ? [...this.item.value.ids] : [];
        if (checked) {
          ids.pushObject(toggledContent.id);
        } else {
          ids.removeObject(toggledContent.id);
        }
        const content = this.getSelectedContents(ids);
        this.onChange({
          ids,
          content
        });
        return;
      }
      const id = toggledContent.id;
      const content = (_this$getSelectedCont = this.getSelectedContents([id])) === null || _this$getSelectedCont === void 0 ? void 0 : _this$getSelectedCont[0];
      this.onChange({
        id,
        content
      });
    }
    onContentSelectAll(checked) {
      const ids = checked ? [...this._contents.map(content => content.id)] : [];
      const content = this.getSelectedContents(ids);
      this.onChange({
        ids,
        content
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_contents", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_selectedContents", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_selectedContents"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_fetchContents", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onContentSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onContentSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onContentSelectAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onContentSelectAll"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AeConfigurationsContentsComponent);
});