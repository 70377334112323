define("ember-svg-jar/inlined/arrow-right-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.207 12.354a.5.5 0 000-.707l-3.823-3.824a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l2.366 2.366H7.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h7.19l-2.367 2.366a.5.5 0 000 .707l.354.354a.5.5 0 00.707 0l3.823-3.823z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});