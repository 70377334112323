define("ember-svg-jar/inlined/announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.75 12.5a.5.5 0 01-.5-.5V8.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5V12a.5.5 0 01-.5.5h-.5zM11.75 16a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-.5z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.63 3.406a.5.5 0 01.74 0l1.278 1.403a.5.5 0 00.521.14l1.808-.576a.5.5 0 01.64.37l.406 1.853a.5.5 0 00.381.382l1.854.405a.5.5 0 01.37.64l-.576 1.808a.5.5 0 00.14.522l1.402 1.278a.5.5 0 010 .739l-1.402 1.278a.5.5 0 00-.14.521l.576 1.808a.5.5 0 01-.37.64l-1.854.406a.5.5 0 00-.381.382l-.406 1.853a.5.5 0 01-.64.37l-1.808-.576a.5.5 0 00-.521.14l-1.278 1.403a.5.5 0 01-.74 0l-1.278-1.403a.5.5 0 00-.52-.14l-1.81.576a.5.5 0 01-.64-.37l-.405-1.853a.5.5 0 00-.381-.382l-1.854-.405a.5.5 0 01-.37-.64l.576-1.809a.5.5 0 00-.14-.521L3.406 12.37a.5.5 0 010-.74l1.402-1.277a.5.5 0 00.14-.522l-.576-1.808a.5.5 0 01.37-.64l1.854-.405a.5.5 0 00.381-.382l.406-1.854a.5.5 0 01.64-.37l1.808.576a.5.5 0 00.521-.14l1.278-1.402zM18 12a6 6 0 11-12 0 6 6 0 0112 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});