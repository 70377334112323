define("ember-svg-jar/inlined/arrow-up-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.354 8.646l2.823 2.824a.5.5 0 010 .707l-.354.353a.5.5 0 01-.707 0l-1.366-1.366v3.629a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-3.63L9.884 12.53a.5.5 0 01-.707 0l-.354-.353a.5.5 0 010-.707l2.823-2.824a.5.5 0 01.707 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});