define("ember-modifier/-private/functional/modifier-manager", ["exports", "@ember/modifier"], function (_exports, _modifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const MODIFIER_ELEMENTS = new WeakMap();
  const MODIFIER_TEARDOWNS = new WeakMap();
  function teardown(modifier) {
    const teardown = MODIFIER_TEARDOWNS.get(modifier);
    if (teardown && typeof teardown === 'function') {
      teardown();
    }
  }
  function setup(modifier, element, args) {
    const {
      positional,
      named
    } = args;
    const teardown = modifier(element, positional, named);
    MODIFIER_TEARDOWNS.set(modifier, teardown);
  }
  class FunctionalModifierManager {
    constructor() {
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)('3.13'));
    }
    createModifier(factory) {
      // This looks superfluous, but this is creating a new instance
      // of a function -- this is important so that each instance of the
      // created modifier can have its own state which is stored in
      // the MODIFIER_ELEMENTS and MODIFIER_TEARDOWNS WeakMaps
      return function () {
        return factory.class(...arguments);
      };
    }
    installModifier(modifier, element, args) {
      MODIFIER_ELEMENTS.set(modifier, element);
      setup(modifier, element, args);
    }
    updateModifier(modifier, args) {
      const element = MODIFIER_ELEMENTS.get(modifier);
      teardown(modifier);
      setup(modifier, element, args);
    }
    destroyModifier(modifier) {
      teardown(modifier);
    }
  }
  var _default = _exports.default = new FunctionalModifierManager();
});