define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.227 6.873a.5.5 0 01.707 0L12 10.94l4.066-4.066a.5.5 0 01.707 0l.354.354a.5.5 0 010 .707L13.06 12l4.066 4.066a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0L12 13.06l-4.066 4.066a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707L10.94 12 6.873 7.934a.5.5 0 010-.707l.354-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});