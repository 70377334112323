define("ember-svg-jar/inlined/voucher-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"a\" fill=\"#fff\"><rect fill-rule=\"evenodd\" height=\"160\" rx=\"1\" width=\"160\"/></mask><g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(104 48)\"><rect height=\"158.5\" rx=\"1\" stroke=\"#8f9ea4\" stroke-opacity=\".4\" stroke-width=\"1.5\" width=\"158.5\" x=\".75\" y=\".75\"/><g fill=\"#8f9ea4\"><circle cx=\"7.5\" cy=\"7.5\" mask=\"url(#a)\" r=\"1.5\"/><circle cx=\"12.5\" cy=\"7.5\" mask=\"url(#a)\" r=\"1.5\"/><circle cx=\"17.5\" cy=\"7.5\" mask=\"url(#a)\" r=\"1.5\"/><rect fill-opacity=\".4\" height=\"1.5\" mask=\"url(#a)\" rx=\".75\" width=\"80\" x=\"40\" y=\"6.5\"/></g><rect height=\"54.5\" mask=\"url(#a)\" rx=\"1\" stroke=\"#5d6e7a\" stroke-width=\"1.5\" width=\"54.5\" x=\"20.75\" y=\"49.75\"/><rect height=\"54.5\" mask=\"url(#a)\" rx=\"1\" stroke=\"#5d6e7a\" stroke-width=\"1.5\" width=\"54.5\" x=\"20.75\" y=\"113.75\"/><rect height=\"54.5\" mask=\"url(#a)\" rx=\"1\" stroke=\"#fff\" stroke-width=\"1.5\" width=\"54.5\" x=\"84.75\" y=\"49.75\"/><g fill=\"#fff\" mask=\"url(#a)\"><path d=\"M116.042 73h2.458a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h2.458A2.5 2.5 0 01112 70.215 2.5 2.5 0 01116.042 73zm-9.542 4h4.75v-2.5h-4.75zm0 1.5v5h4.75v-5zm11-1.5v-2.5h-4.75V77zm0 1.5h-4.75v5h4.75zm-7.25-5.5a1 1 0 100-2 1 1 0 000 2zm3.5 0a1 1 0 100-2 1 1 0 000 2z\"/></g><rect height=\"54.5\" mask=\"url(#a)\" rx=\"1\" stroke=\"#5d6e7a\" stroke-width=\"1.5\" width=\"54.5\" x=\"84.75\" y=\"113.75\"/></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});