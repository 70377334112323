define("ember-svg-jar/inlined/cms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#00172f\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M58 44.75c0-2.04-1.483-3.698-3.311-3.698-1.97 0-2.903 2.334-3.922 2.334-.764 0-1.02-.343-1.046-1.775v-9.803s0-1.469-1.463-1.469h-7.056c-1.624 0-1.986-.248-1.986-1.06 0-1.028 2.319-1.965 2.319-3.946 0-1.84-1.644-3.333-3.676-3.333-2.03 0-3.675 1.492-3.675 3.333 0 1.981 2.32 2.918 2.32 3.947 0 .81-.361 1.06-1.985 1.06h-7.058C26 30.34 26 31.807 26 31.807v2.83c0 1.633.246 1.996 1.051 1.996 1.022 0 1.956-2.332 3.924-2.332 1.83 0 3.312 1.655 3.312 3.7 0 2.043-1.483 3.698-3.312 3.698-1.968 0-2.902-2.334-3.924-2.334-.803 0-1.051.363-1.051 1.997v11.165C26 54 27.461 54 27.461 54H37.7c1.62 0 1.983-.249 1.983-1.058 0-1.028-2.32-1.966-2.32-3.947 0-1.84 1.647-3.333 3.678-3.333 2.033 0 3.679 1.493 3.679 3.333 0 1.981-2.32 2.92-2.32 3.947 0 .81.36 1.058 1.982 1.058h3.876s1.463 0 1.463-1.472v-4.636c.025-1.435.282-1.774 1.047-1.774 1.018 0 1.95 2.335 3.92 2.335C56.519 48.449 58 46.795 58 44.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80"
    }
  };
});