define("@additive-apps/ui/utils/function-util", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasBody = _exports.emberStringify = void 0;
  /**
   * checks wheter a function is defined and has a body
   *
   * @function hasBody
   * @param {Function} func
   */
  const hasBody = func => {
    const functionString = func && func.toString();
    const body = functionString && functionString.slice(functionString.indexOf('{') + 1, functionString.lastIndexOf('}'));
    return typeof func === 'function' && body && body.length > 0;
  };

  /**
   * stringifies an object including ember data models
   * which throw errors when fed into JSON.stringify
   *
   * @function emberStringify
   * @param {Any} data
   * @return {String}
   */
  _exports.hasBody = hasBody;
  const emberStringify = data => {
    let json = data;
    if ((0, _array.isArray)(data)) {
      json = data.map(item => item && item.serialize ? item.serialize() : item);
    } else if (typeof data === 'object') {
      json = data && data.serialize ? data.serialize() : data;
    }
    return JSON.stringify(json);
  };
  _exports.emberStringify = emberStringify;
});