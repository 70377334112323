define("ember-svg-jar/inlined/error-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.5 12a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm-7.56 0L8.822 9.884a.5.5 0 010-.707l.354-.354a.5.5 0 01.707 0L12 10.94l2.116-2.116a.5.5 0 01.707 0l.354.354a.5.5 0 010 .707L13.06 12l2.116 2.116a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0L12 13.06l-2.116 2.116a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707L10.94 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});