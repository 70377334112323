define("@additive-apps/utils/utils/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getColorOptions = void 0;
  /**
   * Util that transforms given colors into an array which are supported by our select-component
   *
   *
   * The input param should always have the correct format as defined by the api.
   *
   * ```js
   * colors: {
   *  key: {
   *    main: {
   *      color: #000,
   *      contrastColor: #ff
   *    }
   *  }
   * }
   * ```
   * Available colors keys are `main`, `accent` and `ambient`.
   * Each one is again an object which consists of a color and a contrast color.
   *
   * @function getColorOptions
   * @param {Object} colors
   * @return {Array} colorOptions
   */
  const getColorOptions = colors => {
    const colorOptions = Object.keys(colors).map(key => {
      return {
        key,
        value: colors[key]
      };
    });
    return colorOptions;
  };
  _exports.getColorOptions = getColorOptions;
});