define("ember-svg-jar/inlined/voucher-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#00172f\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M52.996 32.325h-5.09L49.09 27c-4.68.084-7.47 2.275-9.09 4.43-1.617-2.155-4.41-4.346-9.088-4.43l1.18 5.325h-5.086C25 32.325 25 34.329 25 34.329l.513 6.071h1.025v9.813c0 2.006 2.006 2.006 2.006 2.006h22.913s2.005 0 2.005-2.006V40.4h1.024L55 34.33s0-2.005-2.004-2.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "128",
      "height": "128",
      "viewBox": "0 0 80 80"
    }
  };
});