define("@additive-apps/ui/components/ui-modal", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/internals", "ember-concurrency", "ember-arg-types", "prop-types", "@additive-apps/ui/utils/dom-util"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _internals, _emberConcurrency, _emberArgTypes, _propTypes, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#in-element this._destinationElement insertBefore=null}}
    <div
      class="ui-modal-container flex flex-column fixed top-0 left-0 w-100 h-100 oauto bg-black--50"
      {{did-insert this.onDidInsert}}
    >
      <UiModal::Inner @classNames={{this.modalClassNames}} @theme={{this.theme}} @size={{this.size}} @scrollInline={{@scrollInline}} ...attributes>
        {{yield
          (hash
            header=(component
              "ui-modal/header"
              title=this.title
              theme=this.theme
              observerId=this._observerId
              onClose=this.onClose
              size=this.size
            )
            content=(component
              "ui-modal/content"
              title=this.title
              subtitle=this.subtitle
              observerId=this._observerId
              isLoading=this.isLoading
              size=this.size
            )
            footer=(component "ui-modal/footer")
            fixedBottom=(component "ui-blank-template" class="ui-modal__fixed-bottom fixed ma-auto" tagName="div" onDidInsert=this.onDidInsertFixedBottom)
          )
        }}
      </UiModal::Inner>
    </div>
  {{/in-element}}
  */
  {
    "id": "IwBXoJXD",
    "block": "[[[40,[[[1,\"  \"],[11,0],[24,0,\"ui-modal-container flex flex-column fixed top-0 left-0 w-100 h-100 oauto bg-black--50\"],[4,[38,1],[[30,0,[\"onDidInsert\"]]],null],[12],[1,\"\\n    \"],[8,[39,2],[[17,1]],[[\"@classNames\",\"@theme\",\"@size\",\"@scrollInline\"],[[30,0,[\"modalClassNames\"]],[30,0,[\"theme\"]],[30,0,[\"size\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[18,3,[[28,[37,4],null,[[\"header\",\"content\",\"footer\",\"fixedBottom\"],[[50,\"ui-modal/header\",0,null,[[\"title\",\"theme\",\"observerId\",\"onClose\",\"size\"],[[30,0,[\"title\"]],[30,0,[\"theme\"]],[30,0,[\"_observerId\"]],[30,0,[\"onClose\"]],[30,0,[\"size\"]]]]],[50,\"ui-modal/content\",0,null,[[\"title\",\"subtitle\",\"observerId\",\"isLoading\",\"size\"],[[30,0,[\"title\"]],[30,0,[\"subtitle\"]],[30,0,[\"_observerId\"]],[30,0,[\"isLoading\"]],[30,0,[\"size\"]]]]],[50,\"ui-modal/footer\",0,null,null],[50,\"ui-blank-template\",0,null,[[\"class\",\"tagName\",\"onDidInsert\"],[\"ui-modal__fixed-bottom fixed ma-auto\",\"div\",[30,0,[\"onDidInsertFixedBottom\"]]]]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"_destinationElement\"]],null]],[\"&attrs\",\"@scrollInline\",\"&default\"],false,[\"in-element\",\"did-insert\",\"ui-modal/inner\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-modal.hbs",
    "isStrictMode": false
  });
  /**
   * ```hbs
   * {{#ui-modal theme="white" size="md" title="title" subtitle="subtitle" onClose as |modal|}}
   *
   *  {{#modal.header}}
   *    yielded into navigation bar
   *  {{#modal.header}}
   *
   *  {{#modal.content as |content|}}
   *    {{#content.top}}
   *      yielded above modal title
   *    {{/content.top}}
   *    {{#content.body}}
   *      content of modal
   *    {{/content.body}}
   *  {{/modal.content}}
   *
   *  {{#modal.footer}}
   *    action footer
   *  {{/modal.footer}}
   *
   * {{/ui-modal}}
   * ```
   *
   * @class ui-modal
   */
  let UiModalComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.object), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiModalComponent extends _component2.default {
    /**
     * computed id to handle navbar on-scroll behavior
     *
     * @computed _observerId
     * @type {String}
     * @private
     */
    get _observerId() {
      return `ui-modal-header-sentinel-${this._generatedElementId}`;
    }
    constructor() {
      super(...arguments);
      /**
       * the background color of the modal
       * the default and fallback theme is white
       *
       * [theme='white'] $ui-color-white
       * [theme='grey'] $ui-color-near-white
       * [theme='error']
       *
       * @property theme
       * @type {String}
       * @default 'white'
       */
      _initializerDefineProperty(this, "theme", _descriptor, this);
      /**
       *
       * the size of the modal
       *
       * [size='sm'] 240x440
       * [size='md'] 640x540
       * [size='lg'] 640x960
       *
       * @property size
       * @type {String}
       * @default 'md'
       */
      _initializerDefineProperty(this, "size", _descriptor2, this);
      /**
       * the title of the modal
       *
       * @property title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor3, this);
      /**
       * the subtitle of the modal
       *
       * @property subtitle
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "subtitle", _descriptor4, this);
      /**
       * class names for the actual modal
       *
       * @property modalClassNames
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "modalClassNames", _descriptor5, this);
      /**
       * used as substitution for elementId which is not available on tag-less components
       *
       * @property _generatedElementId
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "_generatedElementId", _descriptor6, this);
      /**
       * the element into which the modal should be rendered
       *
       * @property _destinationElement
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "_destinationElement", _descriptor7, this);
      _initializerDefineProperty(this, "_modalContainer", _descriptor8, this);
      _initializerDefineProperty(this, "onClose", _descriptor9, this);
      this._generatedElementId = (0, _internals.guidFor)(this);
      this._destinationElement = document.querySelector('#ui-dialog-wrapper');
      document.body.classList.add('modal-open');
    }
    willDestroy() {
      var _this$_modalContainer;
      super.willDestroy(...arguments);
      document.body.classList.remove('modal-open');
      (_this$_modalContainer = this._modalContainer) === null || _this$_modalContainer === void 0 || _this$_modalContainer.removeEventListener('scroll', this._onScroll, false);
      if (this._resizeObserver) {
        this._resizeObserver.disconnect();
      }
    }
    _positionFixedBottom() {
      const modal = this._modalContainer.querySelector('.ui-modal');
      const modalBottom = modal === null || modal === void 0 ? void 0 : modal.getBoundingClientRect().bottom;
      this._fixedBottom.style.top = `${window.innerHeight < modalBottom ? window.innerHeight : modalBottom}px`;
    }
    async onDidInsert(element) {
      this._modalContainer = element;
      this._modal = element.querySelector('.ui-modal');
      if (this._fixedBottom) {
        this._resizeObserver = new ResizeObserver(() => {
          this._positionFixedBottom();
        });
        this._resizeObserver.observe(this._modal);
      }
    }
    async onDidInsertFixedBottom(element) {
      this._fixedBottom = element;
      if (this._fixedBottom) {
        this._onScroll = () => this._positionFixedBottom();
        await (0, _emberConcurrency.waitForProperty)(this, '_modalContainer');
        this._modalContainer.addEventListener('scroll', this._onScroll, false);
        await (0, _domUtil.nextTick)();
        this._positionFixedBottom();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'white';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'md';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "subtitle", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalClassNames", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_generatedElementId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_destinationElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_modalContainer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsertFixedBottom", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsertFixedBottom"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiModalComponent);
});