define("ember-animated/components/animated-container", ["exports", "@ember/object/computed", "@ember/service", "@ember/component", "ember-animated/motions/resize", "ember-animated/-private/ember-scheduler", "ember-animated/-private/sprite", "ember-animated", "ember-animated/-private/ember-internals", "ember-animated/templates/components/animated-container", "@ember/object"], function (_exports, _computed, _service, _component, _resize, _emberScheduler, _sprite, _emberAnimated, _emberInternals, _animatedContainer, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   Provides a boundary between animator components and the surrounding document
   which smoothly resizes as animators change. Use animated-container whenever you
   need to "hold a place for" some animated content while that content is animating.
    ```hbs
    <button {{action toggleThing}}>Toggle</button>
    <AnimatedContainer>
      {{#animated-if showThing use=transition }}
          <div class="message" {{action "toggleThing"}}>
              Hello!
          </div>
      {{/animated-if}}
    </AnimatedContainer>
    <p>
      This is outside of the container.
    </p>
    ```
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import {easeOut, easeIn } from 'ember-animated/easings/cosine';
  
    export default Component.extend({
      showThing: false,
  
      toggleThing() {
        this.set('showThing', !this.get('showThing'));
      },
  
      transition: function * ({ insertedSprites, keptSprites, removedSprites }) {
        for (let sprite of insertedSprites) {
          sprite.startAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeOut });
        }
  
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          sprite.endAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeIn });
        }
      },
    });
    ```
    @class animated-container
    @public
  */
  let AnimatedContainerComponent = _exports.default = (_dec = (0, _service.inject)('-ea-motion'), _dec2 = (0, _computed.alias)('animated.isRunning'), _dec3 = (0, _emberScheduler.task)(function* (duration, animationTask) {
    this._startingUp = true;
    let service = this.get('motionService');
    let sprite;
    let useMotion;
    let element = this._ownElement();
    if (element) {
      sprite = _sprite.default.sizedStartingAt(element);
      this.sprite = sprite;
      sprite.lock();
      useMotion = true;
    } else {
      useMotion = this.get('onInitialRender');
    }
    try {
      yield (0, _emberAnimated.afterRender)();
      yield (0, _emberAnimated.microwait)();
    } finally {
      this._startingUp = false;
    }
    yield* service.staticMeasurement(() => {
      if (!sprite) {
        // ownElement is non-null here because we waited for render above, and
        // our own template definitely contains an Element
        sprite = _sprite.default.sizedEndingAt(this._ownElement());
        this.sprite = sprite;
      } else {
        sprite.measureFinalBounds();
      }
    });
    if (useMotion) {
      yield* new this.motion(this.sprite, {
        duration
      })._run();
    }
    yield animationTask;
    this.sprite.unlock();
    this.sprite = null;
  }).restartable(), _class = class AnimatedContainerComponent extends _component.default {
    constructor(properties) {
      super(properties);
      _defineProperty(this, "layout", _animatedContainer.default);
      _defineProperty(this, "tagName", '');
      _initializerDefineProperty(this, "motionService", _descriptor, this);
      /**
       * Use a custom tag for the container. Defaults to div.
        @argument tag
        @type String
      */
      _defineProperty(this, "tag", 'div');
      /**
       * Whether to animate the initial render. You will probably also need to set
       * initialInsertion=true on a child component of animated-container.
       * Defaults to false.
        @argument onInitialRender
        @type Boolean
      */
      _defineProperty(this, "onInitialRender", false);
      /**
       * Use a custom tag for the container. Defaults to div.
        @argument motion
        @type String
      */
      _defineProperty(this, "motion", _resize.Resize);
      _defineProperty(this, "_inserted", false);
      _defineProperty(this, "_startingUp", false);
      _defineProperty(this, "sprite", null);
      _initializerDefineProperty(this, "isAnimating", _descriptor2, this);
      _initializerDefineProperty(this, "animate", _descriptor3, this);
      this.get('motionService').register(this).observeDescendantAnimations(this, this.maybeAnimate); // TODO: shouldn't need this cast;
    }
    didInsertElement() {
      this._inserted = true;
    }
    _ownElement() {
      if (!this._inserted) {
        return undefined;
      }
      let {
        firstNode,
        lastNode
      } = (0, _emberInternals.componentNodes)(this);
      let node = firstNode;
      while (node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          return node;
        }
        if (node === lastNode) {
          break;
        }
        node = node.nextSibling;
      }
      return undefined;
    }
    willDestroyElement() {
      this.get('motionService').unregister(this).unobserveDescendantAnimations(this, this.maybeAnimate); // TODO: shouldn't need this cast
    }
    maybeAnimate(_ref) {
      let {
        duration,
        task
      } = _ref;
      if (!this._startingUp) {
        this.get('animate').perform(duration, task);
      }
    }
    beginStaticMeasurement() {
      if (this.sprite) {
        this.sprite.unlock();
      }
    }
    endStaticMeasurement() {
      if (this.sprite) {
        this.sprite.lock();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "motionService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isAnimating", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "maybeAnimate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "maybeAnimate"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "animate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});