define("ember-svg-jar/inlined/on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 13a2 2 0 11-4 0 2 2 0 014 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.885 7.865l-.874 1.454a13.359 13.359 0 012.872 3.464.434.434 0 010 .434C20.276 14.29 17.315 19 12 19c-5.315 0-8.276-4.709-8.883-5.783a.433.433 0 010-.434c.317-.56 1.274-2.11 2.872-3.464l-.874-1.454a.5.5 0 01.17-.686l.43-.258a.5.5 0 01.685.17l.801 1.334c1.124-.712 2.474-1.26 4.049-1.393V5.55a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v1.482c1.575.134 2.925.68 4.049 1.393l.8-1.333a.5.5 0 01.687-.171l.428.258a.5.5 0 01.171.686zM4.741 13a11.972 11.972 0 002.047 2.389C8.088 16.538 9.817 17.5 12 17.5s3.913-.96 5.212-2.111A11.975 11.975 0 0019.26 13a11.978 11.978 0 00-2.047-2.389C15.912 9.461 14.183 8.5 12 8.5s-3.913.96-5.212 2.111A11.975 11.975 0 004.74 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});