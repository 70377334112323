define("ember-svg-jar/inlined/crm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#00172f\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M39.425 27l-6.737 10.5L23 32.96 27.712 51H51.137l4.713-18.04-9.687 4.54z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80"
    }
  };
});