define("ember-svg-jar/inlined/journal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#061c2d\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M23.063 58.95c-.66 1.068 3.868-15.197 14.069-25.396C47.334 23.35 59.16 23 59.16 23c-7.672 6.635-5.049 11.54-10.905 16.524-1.438 1.224-3.207 1.716-4.95 1.312-1.306-.303-3.327-1.226-2.972-.566.509.95 1.44 1.624 2.2 2.06 1.236.708 2.835.637 2.042 1.353-1.278 1.154-3.694 2.036-5.51 1.89-2.143-.172.363 1.333-.448 1.847-3.969 2.534-9.2 1.445-9.2 1.445-3.736 3.991-5.302 8.383-6.354 10.085\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80"
    }
  };
});