define("ember-animated/box-shadow", ["exports", "ember-animated/color", "ember-animated", "ember-animated/easings/linear", "ember-animated/element-remove"], function (_exports, _color, _emberAnimated, _linear, _elementRemove) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BoxShadowTween = _exports.BoxShadow = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const innerPattern = /^ (\d+)px (\d+)px(?: (\d+)px)?(?: (\d+)px)?( inset)?(?:, )?/;
  class BoxShadow {
    static fromComputedStyle(string) {
      let originalString = string;
      let shadows = [];
      if (!string || string === 'none') {
        return shadows;
      }
      while (string.length > 0) {
        let color = _color.Color.fromComputedStyle(string);
        string = string.slice(color.sourceString.length);
        let m = innerPattern.exec(string);
        if (!m) {
          throw new Error(`failed to parse computed shadow ${originalString}`);
        }
        let x = parseInt(m[1]);
        let y = parseInt(m[2]);
        let blur = m[3] == null ? 0 : parseInt(m[3]);
        let spread = m[4] == null ? 0 : parseInt(m[4]);
        let inset = m[5] != null;
        shadows.push(new BoxShadow({
          color,
          x,
          y,
          blur,
          spread,
          inset
        }));
        string = string.slice(m[0].length);
      }
      return shadows;
    }
    static fromUserProvidedShadow(string) {
      let testElement = document.createElement('div');
      testElement.style.display = 'none';
      testElement.style.boxShadow = string;
      document.body.appendChild(testElement);
      let result = this.fromComputedStyle(getComputedStyle(testElement).boxShadow);
      testElement.remove();
      return result;
    }
    constructor(_ref) {
      let {
        color,
        x,
        y,
        blur,
        spread,
        inset
      } = _ref;
      _defineProperty(this, "color", void 0);
      _defineProperty(this, "x", void 0);
      _defineProperty(this, "y", void 0);
      _defineProperty(this, "blur", void 0);
      _defineProperty(this, "spread", void 0);
      _defineProperty(this, "inset", void 0);
      this.color = color;
      this.x = x;
      this.y = y;
      this.blur = blur;
      this.spread = spread;
      this.inset = inset;
    }
    toString() {
      return `${this.inset ? 'inset ' : ''}${this.x}px ${this.y}px ${this.blur}px ${this.spread}px ${this.color.toString()}`;
    }
  }
  _exports.BoxShadow = BoxShadow;
  function emptyShadowOfType(otherShadow) {
    return new BoxShadow({
      color: _color.Color.fromComputedStyle('rgba(0, 0, 0, 0)'),
      blur: 0,
      spread: 0,
      x: 0,
      y: 0,
      inset: otherShadow.inset
    });
  }
  class BoxShadowTween {
    constructor(fromShadows, toShadows, duration) {
      let easing = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _linear.default;
      _defineProperty(this, "shadowTweens", void 0);
      let shadowCount = Math.max(fromShadows.length, toShadows.length);
      if (fromShadows.length < shadowCount) {
        fromShadows = fromShadows.slice();
        while (fromShadows.length < shadowCount) {
          fromShadows.push(emptyShadowOfType(toShadows[fromShadows.length]));
        }
      }
      if (toShadows.length < shadowCount) {
        toShadows = toShadows.slice();
        while (toShadows.length < shadowCount) {
          toShadows.push(emptyShadowOfType(fromShadows[toShadows.length]));
        }
      }
      this.shadowTweens = fromShadows.map((fromShadow, index) => new OneShadowTween(fromShadow, toShadows[index], duration, easing));
    }
    get currentValue() {
      return this.shadowTweens.map(tween => tween.currentValue);
    }
    get done() {
      return this.shadowTweens.every(tween => tween.done);
    }
  }
  _exports.BoxShadowTween = BoxShadowTween;
  class OneShadowTween {
    constructor(fromShadow, toShadow, duration, easing) {
      _defineProperty(this, "colorTween", void 0);
      _defineProperty(this, "xTween", void 0);
      _defineProperty(this, "yTween", void 0);
      _defineProperty(this, "blurTween", void 0);
      _defineProperty(this, "spreadTween", void 0);
      _defineProperty(this, "inset", void 0);
      this.colorTween = new _color.ColorTween(fromShadow.color, toShadow.color, duration, easing);
      this.xTween = new _emberAnimated.Tween(fromShadow.x, toShadow.x, duration, easing);
      this.yTween = new _emberAnimated.Tween(fromShadow.y, toShadow.y, duration, easing);
      this.blurTween = new _emberAnimated.Tween(fromShadow.blur, toShadow.blur, duration, easing);
      this.spreadTween = new _emberAnimated.Tween(fromShadow.spread, toShadow.spread, duration, easing);
      this.inset = fromShadow.inset;
    }
    get currentValue() {
      return new BoxShadow({
        x: this.xTween.currentValue,
        y: this.yTween.currentValue,
        blur: this.blurTween.currentValue,
        spread: this.spreadTween.currentValue,
        inset: this.inset,
        color: this.colorTween.currentValue
      });
    }
    get done() {
      return [this.colorTween, this.xTween, this.yTween, this.blurTween, this.spreadTween].every(tween => tween.done);
    }
  }
});