define("ember-animated/motions/scale", ["exports", "ember-animated"], function (_exports, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Scale = void 0;
  _exports.default = scale;
  /**
    Smoothly scales _sprite_ from its the initial size to its final size.
  
    _sprite_ must have both `initialBounds` and `finalBounds` set.
  
    ```js
    for (let sprite of insertedSprites) {
      sprite.startAtSprite(beacons['source']);
      scale(sprite)
    }
    ```
  
    @function scale
    @export default
    @param {Sprite} sprite
    @return {Motion}
  */
  function scale(sprite, opts) {
    return new Scale(sprite, opts).run();
  }
  class Scale extends _emberAnimated.Motion {
    constructor(sprite, opts) {
      super(sprite, opts);
      this.widthTween = null;
      this.heightTween = null;
    }
    *animate() {
      let sprite = this.sprite;
      let duration = this.duration;
      let initialWidthFactor, initialHeightFactor;
      if (sprite.originalInitialBounds) {
        // the sprite is going to start at its own native initial size,
        // which may differ from the initialBounds.width &
        // initialBounds.height that have been set for it. This
        // compensates with an initial scaling.
        initialWidthFactor = sprite.initialBounds.width / sprite.originalInitialBounds.width;
        initialHeightFactor = sprite.initialBounds.height / sprite.originalInitialBounds.height;
      } else {
        // the sprite is going to start at its own native final size
        initialWidthFactor = sprite.initialBounds.width / sprite.originalFinalBounds.width;
        initialHeightFactor = sprite.initialBounds.height / sprite.originalFinalBounds.height;
      }
      let widthFactor = sprite.finalBounds.width / sprite.initialBounds.width;
      let heightFactor = sprite.finalBounds.height / sprite.initialBounds.height;
      this.widthTween = new _emberAnimated.Tween(sprite.transform.a * initialWidthFactor, sprite.transform.a * initialWidthFactor * widthFactor, duration, this.opts.easing);
      this.heightTween = new _emberAnimated.Tween(sprite.transform.d * initialHeightFactor, sprite.transform.d * initialHeightFactor * heightFactor, duration, this.opts.easing);
      while (!this.widthTween.done || !this.heightTween.done) {
        sprite.scale(this.widthTween.currentValue / sprite.transform.a, this.heightTween.currentValue / sprite.transform.d);
        yield (0, _emberAnimated.rAF)();
      }
    }
  }
  _exports.Scale = Scale;
});