define("ember-svg-jar/inlined/plus-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.257 12.75h2.993v2.993a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5V12.75h2.993a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5H12.75V8.257a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v2.993H8.257a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});