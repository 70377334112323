define("ember-svg-jar/inlined/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<clipPath id=\"a\"><rect x=\"2.75\" y=\"2.75\" width=\"14.5\" height=\"14.5\" rx=\"7.25\"/></clipPath><rect class=\"circle\" x=\"2.75\" y=\"2.75\" width=\"14.5\" height=\"14.5\" rx=\"7.25\" stroke=\"#8F9EA4\" stroke-width=\"3\" clip-path=\"url(#a)\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});