define("ember-svg-jar/inlined/landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 6.5v11a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h15a.5.5 0 01.5.5zm-1.5 1h-13v9h13v-9zM14.672 12l-1.116-1.116a.5.5 0 010-.707l.354-.354a.5.5 0 01.707 0l1.823 1.823a.5.5 0 010 .708l-1.823 1.823a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707L14.672 12zm-4.232 1.823l-.353.354a.5.5 0 01-.707 0l-1.824-1.823a.5.5 0 010-.708L9.38 9.823a.5.5 0 01.707 0l.353.354a.5.5 0 010 .707L9.324 12l1.116 1.116a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});