define("@additive-apps/ui/templates/components/ui-routable-tabs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hjGKHCzm",
    "block": "[[[18,1,[[28,[37,1],null,[[\"header\",\"content\",\"pane\"],[[50,\"ui-tabs-header\",0,null,null],[50,\"ui-tabs-content\",0,null,null],[50,\"ui-tabs-pane\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-routable-tabs.hbs",
    "isStrictMode": false
  });
});