define("ember-svg-jar/inlined/medium-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.646 12.646l-2.293-2.292a.5.5 0 010-.708l2.293-2.292a.5.5 0 01.707 0l2.293 2.292a.5.5 0 010 .708l-2.293 2.292a.5.5 0 01-.707 0z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});