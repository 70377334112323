define("ember-svg-jar/inlined/checkbox-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.5 2a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h15zm-3.677 4.823a.5.5 0 00-.707 0L9 10.94 6.884 8.823a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l2.823 2.823a.5.5 0 00.708 0l4.823-4.823a.5.5 0 000-.707l-.354-.354z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "#4D76F1",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});