define("ember-animated/-private/child", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Child {
    constructor(group, id, value, index) {
      this.group = group;
      this.id = id;
      this.value = value;
      this.index = index;
      _defineProperty(this, "state", 'new');
      _defineProperty(this, "removalBlockers", 0);
      _defineProperty(this, "removalCycle", null);
      this.removalBlockers = 0;
      this.removalCycle = null;
    }
    block(cycle) {
      if (this.removalCycle == null || this.removalCycle === cycle) {
        this.removalCycle = cycle;
        this.removalBlockers++;
      }
    }
    unblock(cycle) {
      if (this.removalCycle === cycle) {
        this.removalBlockers--;
      }
    }
    flagForRemoval() {
      this.removalCycle = null;
      this.removalBlockers = 0;
      this.state = 'removing';
    }
    get shouldRemove() {
      return this.state === 'removing' && this.removalBlockers < 1;
    }
    clone() {
      return new Child(this.group, this.id, this.value, this.index);
    }
  }
  _exports.default = Child;
});