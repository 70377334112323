define("ember-svg-jar/inlined/numbered-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 17.5H5v-1h1V16H4.5v-1h1.8a.7.7 0 01.7.7v2.6a.7.7 0 01-.7.7H4.5v-1H6v-.5zM9.5 6.25h10a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5zm0 5h10a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5zm0 5h10a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5zM4.777 6.916l-.554-.832 1.189-.793a.7.7 0 011.088.583V9h-1V6.434l-.723.482zM4.5 11v-1h1.676a.7.7 0 01.626 1.013L5.81 13H7v1H5.324a.7.7 0 01-.626-1.013L5.69 11H4.5z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});