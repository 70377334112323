define("ember-svg-jar/inlined/arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.743 16.84V5.15a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v11.69l-4.366-4.367a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l5.824 5.823a.5.5 0 00.707 0l5.823-5.823a.5.5 0 000-.707l-.354-.354a.5.5 0 00-.707 0l-4.366 4.366z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});