define("ember-svg-jar/inlined/shipping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 6a.5.5 0 01.5.5V8h2.25a.5.5 0 01.4.2l2.75 3.667a.5.5 0 01.1.3V16.5a.5.5 0 01-.5.5h-2.55a2.5 2.5 0 01-4.9 0h-3.1a2.5 2.5 0 01-4.9 0H3.5a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5H15zm.5 9.5a1 1 0 100 2 1 1 0 000-2zm-8 0a1 1 0 100 2 1 1 0 000-2zm6.5-8H4.5v8h.708a2.5 2.5 0 014.584 0h3.416a2.51 2.51 0 01.792-1v-7zm3.25 2H15.5V14a2.5 2.5 0 012.292 1.5H19.5v-3l-2.25-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});