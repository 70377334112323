define("ember-svg-jar/inlined/inhouse-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#fff\" stroke-width=\"1.5\"><path d=\"M112 139.676h144M184 51v153.169\" stroke-opacity=\".3\"/><path d=\"M184.146 51.05a.25.25 0 00-.292 0l-71 50.967a.25.25 0 00-.104.203l.046 100.948c0 .138.113.25.25.25h141.907c.138 0 .25-.112.25-.25l.047-100.948a.25.25 0 00-.104-.203z\"/><path d=\"M185 139.676c19.798-18.381 41.8-18.381 29.673 4.354-8.085 15.157-17.976 13.706-29.673-4.354zM184.144 139.676c-19.797-18.381-41.8-18.381-29.672 4.354 8.084 15.157 17.975 13.706 29.672-4.354z\" fill=\"#4d76f1\"/><path d=\"M185 139.676c-8.43 10.254-10.044 25.814-4.84 46.679M185 140.021c7.273 9.356 8.647 19.38 4.12 30.071\"/></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});