define("ember-svg-jar/inlined/devices-dark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(68 52)\"><g transform=\"translate(17)\"><path d=\"M88.75 127.25v23.5h14.5v-23.5z\" stroke=\"#fff\" stroke-width=\"1.5\"/><rect fill=\"#fff\" fill-rule=\"nonzero\" height=\"1.5\" rx=\".5\" width=\"56\" x=\"68\" y=\"150.5\"/><path d=\"M1 .75A.25.25 0 00.75 1v126c0 .138.112.25.25.25h190a.25.25 0 00.25-.25V1a.25.25 0 00-.25-.25z\" stroke=\"#fff\" stroke-width=\"1.5\"/><g fill-rule=\"nonzero\"><circle cx=\"6.5\" cy=\"6.5\" fill=\"#fff\" r=\"1.5\"/><circle cx=\"11.5\" cy=\"6.5\" fill=\"#c6ab8d\" r=\"1.5\"/><circle cx=\"16.5\" cy=\"6.5\" fill=\"#c6ab8d\" r=\"1.5\"/></g><path d=\"M0 112h192v1.5H0z\" fill=\"#fff\"/><circle cx=\"96\" cy=\"120\" fill=\"#fff\" r=\"1.5\"/></g><path d=\"M41.608 86.441l52.834-16.255 29.2 11.064 60.282-25.965\" stroke=\"#c6ab8d\" stroke-linecap=\"round\" stroke-width=\"1.5\"/><rect fill=\"#fff\" height=\"1.5\" rx=\".5\" width=\"56\" x=\"41\" y=\"24\"/><rect fill=\"#fff\" height=\"1.5\" rx=\".5\" width=\"40\" x=\"41\" y=\"29\"/><rect fill=\"#00172F\" height=\"91.5\" rx=\".5\" stroke=\"#fff\" stroke-width=\"1.5\" width=\"66.5\" x=\"164.75\" y=\"59.75\"/><g fill=\"#fff\"><circle cx=\"198.5\" cy=\"144.5\" r=\"1.5\"/><rect height=\"1.5\" rx=\".5\" width=\"48\" x=\"174\" y=\"69\"/><rect height=\"1.5\" rx=\".5\" width=\"40\" x=\"174\" y=\"74\"/></g><rect fill=\"#00172F\" height=\"65.5\" rx=\".5\" stroke=\"#fff\" stroke-width=\"1.5\" width=\"33.5\" x=\".75\" y=\"85.75\"/><circle cx=\"17.5\" cy=\"144.5\" fill=\"#fff\" r=\"1.5\"/><rect fill=\"#fff\" height=\"1.5\" rx=\".5\" width=\"23\" x=\"6\" y=\"91\"/><rect fill=\"#fff\" height=\"1.5\" rx=\".5\" width=\"16\" x=\"6\" y=\"95\"/></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});