define("ember-svg-jar/inlined/connect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.42 5.58a2.083 2.083 0 00-2.947 0l-.59.59a.417.417 0 01-.588 0L10 5.875a.417.417 0 010-.589l.59-.59a3.333 3.333 0 014.713 4.715l-2.357 2.357a3.334 3.334 0 01-4.714 0l-.297-.297a.417.417 0 010-.59l.295-.294a.417.417 0 01.59 0l.296.297a2.083 2.083 0 002.946 0l2.357-2.357a2.083 2.083 0 000-2.946zm-5.893 8.84l.59-.59a.417.417 0 01.588 0l.295.295a.417.417 0 010 .59l-.59.588a3.333 3.333 0 01-4.713-4.714l2.357-2.357a3.334 3.334 0 014.714 0l.294.295a.417.417 0 010 .59l-.294.294a.417.417 0 01-.59 0l-.294-.295a2.083 2.083 0 00-2.946 0L5.58 11.473a2.083 2.083 0 102.946 2.946z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});