define("ember-svg-jar/inlined/cycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path d=\"M143.5 168s23.5 1 57-28.5 57-28.5 57-28.5\" stroke=\"#8F9EA4\" stroke-opacity=\".4\"/><path d=\"M266 111H127.5c-15.74 0-28.5 12.76-28.5 28.5v0c0 15.74 12.76 28.5 28.5 28.5h2.5m136-57l-20 20m20-20l-20-20\" stroke=\"#00172F\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M135 168h138.5c15.74 0 28.5-12.76 28.5-28.5v0c0-15.74-12.76-28.5-28.5-28.5H271m-136 57l20-20m-20 20l20 20\" stroke=\"#8F9EA4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "400",
      "height": "280",
      "viewBox": "0 0 400 280",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});