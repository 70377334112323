define("ember-svg-jar/inlined/feedback-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.823 8.824l.354.353a.5.5 0 010 .707l-5.823 5.823a.5.5 0 01-.707 0l-2.823-2.82a.5.5 0 010-.708l.353-.354a.5.5 0 01.707 0L11 13.94l5.116-5.116a.5.5 0 01.707 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});