define("ember-svg-jar/inlined/enquiry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#00172f\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M48.355 46.57c-2.688-.803-4.977-2.006-4.977-3.11v-2.708c0-.624 1.791-1.905 1.791-4.262 0-1.505.399-1.254.797-3.06.259-1.174 0-3.108.298-4.111.3-1.004-.895-6.319-6.57-6.319-5.674 0-6.868 5.315-6.57 6.319.3 1.003.04 2.937.3 4.112.397 1.805.796 1.554.796 3.059 0 2.357 1.792 3.638 1.792 4.262v2.708c0 1.104-2.29 2.307-4.978 3.11-2.687.802-6.321 3.81-4.579 5.515 3.238 3.17 4.28 4.915 6.769 4.915h12.94c2.49 0 3.532-1.745 6.77-4.915 1.742-1.704-1.891-4.713-4.58-5.516\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80"
    }
  };
});