define("ember-svg-jar/inlined/empty-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path d=\"M214.258 162.25l27.992-28.898V74a.25.25 0 00-.25-.25H124a.25.25 0 00-.25.25v88c0 .138.112.25.25.25h29.75v35.836a.25.25 0 00.427.177l36.012-36.013z\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"/><g fill=\"#8f9ea4\" fill-opacity=\".3\" transform=\"translate(123 73)\"><circle cx=\"49.5\" cy=\"45.5\" r=\"1.5\"/><circle cx=\"60.5\" cy=\"45.5\" r=\"1.5\"/><circle cx=\"71.5\" cy=\"45.5\" r=\"1.5\"/></g><g stroke-width=\"1.5\" transform=\"translate(123 73)\"><circle cx=\"114\" cy=\"83\" fill=\"#f2f2f2\" r=\"23.25\" stroke=\"#8f9ea4\"/><path d=\"M114 70v13l8.102 6.374\" stroke=\"#d3d7d9\" stroke-linecap=\"round\"/></g></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});