define("ember-animated/components/animated-if", ["exports", "@ember/component", "@ember/object", "ember-animated/templates/components/animated-if"], function (_exports, _component, _object, _animatedIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _AnimatedIfComponent; // @ts-ignore: templates don't have types
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
    A drop in replacement for `{{#if}}` that animates changes when the predicate changes.
    Animated-if uses the same arguments as animated-each.
    ```hbs
    <button {{action toggleThing}}>Toggle</button>
  
    {{#animated-if showThing use=transition}}
        <div class="message" {{action "toggleThing"}}>
            myContent
        </div>
    {{/animated-if}}
    ```
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import { easeOut, easeIn } from 'ember-animated/easings/cosine';
  
    export default Component.extend({
      showThing: false,
  
      toggleThing() {
        this.set('showThing', !this.get('showThing'));
      },
  
      transition: function * ({ insertedSprites, keptSprites, removedSprites }) {
        for (let sprite of insertedSprites) {
          sprite.startAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeOut });
        }
  
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          sprite.endAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeIn });
        }
      },
    });
    ```
    @class animated-if
    @public
  */
  let AnimatedIfComponent = _exports.default = (_dec = (0, _object.computed)('group'), _class = (_AnimatedIfComponent = class AnimatedIfComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _animatedIf.default);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "group", void 0);
    }
    get realGroup() {
      return this.group || `animated_if_${Math.floor(Math.random() * 1000000)}`;
    }
  }, _defineProperty(_AnimatedIfComponent, "positionalParams", ['predicate']), _AnimatedIfComponent), _applyDecoratedDescriptor(_class.prototype, "realGroup", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "realGroup"), _class.prototype), _class);
});