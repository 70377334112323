define("ember-svg-jar/inlined/bolt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.5 11V7.127L8.74 13h3.76v3.874L16.26 11H12.5zm5.586-1.5a.5.5 0 01.422.77L11.92 20.56c-.27.422-.921.23-.921-.269v-5.79H6.914a.5.5 0 01-.421-.77l6.586-10.292c.27-.42.921-.23.921.27V9.5h4.086z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});