define("ember-svg-jar/inlined/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 4a8 8 0 110 16 8 8 0 010-16zm0 1.5a6.5 6.5 0 00-1 12.923V13.5h-1a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h1v-1.25c0-.966.784-1.75 1.75-1.75H14a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-1.25a.25.25 0 00-.25.25V12h1a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-1v4.981A6.5 6.5 0 0012 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});