define("ember-svg-jar/inlined/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.5 5a.5.5 0 01.5.5V10a.5.5 0 01-.5.5H19v8a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-8h-.5A.5.5 0 014 10V5.5a.5.5 0 01.5-.5h15zm-2 5.5h-11v7h11v-7zm-4.5 2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2zm5.5-6h-13V9h13V6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});