define("ember-svg-jar/inlined/plug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.596 4.328a.5.5 0 01.707 0l1.415 1.414 1.06-1.06a.5.5 0 01.707 0l.354.353a.5.5 0 010 .707l-1.06 1.06 1.414 1.415 1.06-1.06a.5.5 0 01.707 0l.354.353a.5.5 0 010 .707l-1.06 1.06 1.413 1.415a.5.5 0 010 .707l-2.475 2.475a3 3 0 01-4.242 0l-.854-.854c-.94 1.206-1.113 2.558-.76 3.618.397 1.189 1.482 2.112 3.164 2.112 1.728 0 2.481-.728 2.849-1.372.198-.346.3-.7.352-.971a.464.464 0 01.461-.409l.552.002a.48.48 0 01.484.534c-.008.057-.015.11-.024.153a4.677 4.677 0 01-.523 1.435c-.632 1.107-1.88 2.128-4.151 2.128-2.318 0-3.983-1.326-4.587-3.138-.541-1.626-.205-3.558 1.117-5.158l-.909-.909a3 3 0 010-4.242l2.475-2.475zM9.182 7.863l1.768-1.767 4.95 4.95-1.768 1.767a1.5 1.5 0 01-2.122 0L9.182 9.985a1.5 1.5 0 010-2.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});