define("ember-svg-jar/inlined/tiktok", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.5 13.5V9.668c.466.293 1.046.495 1.75.56.276.025.5-.202.5-.478v-.5c0-.276-.225-.495-.499-.531-.693-.091-1.095-.37-1.334-.647a1.747 1.747 0 01-.33-.582C14.5 7.228 14.278 7 14 7h-.5a.5.5 0 00-.5.5v6a1.75 1.75 0 11-2.243-1.68c.265-.077.493-.294.493-.57v-.5c0-.276-.225-.504-.498-.462A3.25 3.25 0 1014.5 13.5z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});