define("ember-svg-jar/inlined/empty-document-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"><g fill-rule=\"nonzero\"><path d=\"M243.25 211V61a.25.25 0 00-.25-.25h-61.608a.25.25 0 00-.17.067L140.83 98.253a.25.25 0 00-.08.184V211c0 .138.112.25.25.25h102a.25.25 0 00.25-.25z\" stroke-opacity=\".4\"/><path d=\"M227.25 195V45a.25.25 0 00-.25-.25h-61.608a.25.25 0 00-.17.067L124.83 82.253a.25.25 0 00-.08.184V195c0 .138.112.25.25.25h102a.25.25 0 00.25-.25z\" fill=\"#f2f2f2\"/><path d=\"M165.25 82.25V45.296a.25.25 0 00-.42-.183l-39.457 36.704a.25.25 0 00.17.433z\"/></g><g stroke-linecap=\"round\" stroke-opacity=\".4\"><path d=\"M141 99.75h64M141 109.75h56M141 119.75h40\"/></g></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});