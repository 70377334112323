define("ember-changeset/utils/assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pureAssign;
  function pureAssign() {
    for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }
    return objects.reduce((acc, obj) => {
      return Object.defineProperties(acc, Object.getOwnPropertyDescriptors(obj));
    }, {});
  }
});