define("@additive-apps/ui/components/ui-control-panel", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiListItem
    @class="ui-control-panel"
    @title={{this.label}}
    @description={{this._value}}
    @popoverInfo={{this.description}}
    @state={{this.state}}
    @isReadOnly={{this.isReadOnly}}
    @onClick={{this._onPanelClick}}
    ...attributes as |item|
  >
    <item.left>
      {{yield (hash left=(component "ui-blank-template"))}}
    </item.left>
    <item.state />
    <item.right>
      {{yield (hash right=(component "ui-blank-template"))}}
    </item.right>
  </UiListItem>
  
  {{#if this._isDetailOpen}}
    <UiModal
      @title={{this.modal.title}}
      @size={{this.modal.size}}
      @onClose={{this.onModalClose}} as |modal|
    >
      <modal.header />
      <modal.content as |content|>
        <content.body>
          {{yield (hash modal=(component "ui-blank-template"))}}
        </content.body>
      </modal.content>
      <modal.footer>
        <UiButton
          @label={{this.modal.actionLabel}}
          @isSecondary={{true}}
          @isDisabled={{this.modal.isReadOnly}}
          @onClick={{this.onModalAction}}
        />
      </modal.footer>
    </UiModal>
  {{/if}}
  
  <UiDiscardChanges @isOpen={{this._isDiscardChangesDialog}} @onDiscard={{this.discardChanges}} />
  */
  {
    "id": "miKIN3wE",
    "block": "[[[8,[39,0],[[17,1]],[[\"@class\",\"@title\",\"@description\",\"@popoverInfo\",\"@state\",\"@isReadOnly\",\"@onClick\"],[\"ui-control-panel\",[30,0,[\"label\"]],[30,0,[\"_value\"]],[30,0,[\"description\"]],[30,0,[\"state\"]],[30,0,[\"isReadOnly\"]],[30,0,[\"_onPanelClick\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"left\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[28,[37,2],null,[[\"left\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"state\"]],null,null,null],[1,\"\\n  \"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,5,[[28,[37,2],null,[[\"right\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"_isDetailOpen\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@title\",\"@size\",\"@onClose\"],[[30,0,[\"modal\",\"title\"]],[30,0,[\"modal\",\"size\"]],[30,0,[\"onModalClose\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"header\"]],null,null,null],[1,\"\\n    \"],[8,[30,3,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,4,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,5,[[28,[37,2],null,[[\"modal\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n    \"],[8,[30,3,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,6],null,[[\"@label\",\"@isSecondary\",\"@isDisabled\",\"@onClick\"],[[30,0,[\"modal\",\"actionLabel\"]],true,[30,0,[\"modal\",\"isReadOnly\"]],[30,0,[\"onModalAction\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,7],null,[[\"@isOpen\",\"@onDiscard\"],[[30,0,[\"_isDiscardChangesDialog\"]],[30,0,[\"discardChanges\"]]]],null]],[\"&attrs\",\"item\",\"modal\",\"content\",\"&default\"],false,[\"ui-list-item\",\"yield\",\"hash\",\"component\",\"if\",\"ui-modal\",\"ui-button\",\"ui-discard-changes\"]]",
    "moduleName": "@additive-apps/ui/components/ui-control-panel.hbs",
    "isStrictMode": false
  });
  /**
   * Higher order component, which wraps common.
   *
   * ```hbs
   *  {{ui-control-panel}}
   * ```
   *
   * @class ui-control-panel
   * @public
   */
  let UiControlPanel = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.object), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _dec9 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiControlPanel extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * Changeset gets only passed in order to check if a discard
       * changeset dialog should be shown or not
       *
       * @argument changeset
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor, this);
      /**
       * The label
       *
       * @argument label
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "label", _descriptor2, this);
      /**
       * The description (rendered in popover)
       *
       * @argument description
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "description", _descriptor3, this);
      /**
       * The state
       *
       * @argument state
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "state", _descriptor4, this);
      /**
       * The modal properties
       *
       * @argument modal
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "modal", _descriptor5, this);
      /**
       * The value
       *
       * @argument value
       * @type {Any}
       * @default undefined
       */
      _initializerDefineProperty(this, "value", _descriptor6, this);
      /**
       * If the panel has a disabled attribute
       *
       * @argument isDisabled
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDisabled", _descriptor7, this);
      /**
       * Sets the panel on readonly mode
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor8, this);
      /**
       * Whether the discard changes dialog is open or not
       * @property _isDiscardChangesDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isDiscardChangesDialog", _descriptor9, this);
      /**
       * whether the modal is open
       *
       * @property _isDetailOpen
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isDetailOpen", _descriptor10, this);
      _initializerDefineProperty(this, "_isLoading", _descriptor11, this);
      /**
       * whether the discard changes dialog is open
       *
       * @property _isDiscardChangesDialog
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isDiscardChangesDialog", _descriptor12, this);
      /**
       * Action that gets triggered on modal action click
       *
       * @function onClick
       * @type {Action}
       */
      _initializerDefineProperty(this, "onClick", _descriptor13, this);
      /**
       * Action that gets triggered on panel click
       *
       * @function onPanelClick
       * @type {Action}
       */
      _initializerDefineProperty(this, "onPanelClick", _descriptor14, this);
      /**
       * Action that gets triggered on close
       * @type {Action}
       * @function onClose
       */
      _initializerDefineProperty(this, "onClose", _descriptor15, this);
    }
    /**
     * Whether it is disabled
     * @computed _isDisabled
     * @type {Boolean}
     * @private
     */
    get _isDisabled() {
      return this.isDisabled || this._isLoading;
    }
    get _value() {
      if (this.value === undefined) {
        return null;
      }
      return this.value || '-';
    }
    _onPanelClick() {
      if (this._isDisabled || this.isReadOnly) {
        return;
      }
      if (this.modal) {
        this._isDetailOpen = true;
        return;
      }
      this.onPanelClick();
    }
    discardChanges() {
      var _this$changeset;
      (_this$changeset = this.changeset) === null || _this$changeset === void 0 || _this$changeset.rollback();
      this._isDiscardChangesDialog = false;
      this.onClose();
    }
    async onModalAction(event) {
      try {
        var _this$changeset2, _this$changeset3;
        this._isLoading = true;
        if ((_this$changeset2 = this.changeset) !== null && _this$changeset2 !== void 0 && _this$changeset2.get('isPristine') || (_this$changeset3 = this.changeset) !== null && _this$changeset3 !== void 0 && _this$changeset3.get('isInvalid')) {
          if (this.changeset.get('isPristine')) {
            this.onModalClose();
          }
          return;
        }
        await this.onClick(event);
        if (this.isDestroying) {
          return;
        }
        this.onModalClose();
      } finally {
        this._isLoading = false;
      }
    }
    async onModalClose(event) {
      if (!this.changeset || this.changeset.get('isPristine')) {
        await this.onClose(event);
        !this.isDestroying && (this._isDetailOpen = false);
      } else {
        this._isDiscardChangesDialog = true;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "changeset", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modal", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "value", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_isDiscardChangesDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_isDetailOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_isDiscardChangesDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "onPanelClick", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onPanelClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onPanelClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onModalAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onModalAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onModalClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onModalClose"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiControlPanel);
});