define("ember-svg-jar/inlined/warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 21a9 9 0 110-18 9 9 0 010 18zm0-1.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm.25-7.5h-.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm0 4h-.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});