define("@additive-apps/utils/validators/numeral", ["exports", "@ember/object", "@ember/utils", "ember-validators", "@ember/polyfills"], function (_exports, _object, _utils, _emberValidators, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isString = isString;
  _exports.validateNumeral = validateNumeral;
  const DEPENDENT_KEYS = ['is', 'lt', 'lte', 'gt', 'gte'];

  /**
   * @function validateNumeral
   *
   * the validator is an extension of the default number validator. The main difference is that
   * all dependent options can be passed as a string, in that case the validator is executed against a
   * other property of the same changeset.
   *
   * @param {Object} options
   *
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Boolean} options.allowNone If true, skips validation if the value is null or undefined. __Default: true__
   * @param {Boolean} options.allowString If true, validator will accept string representation of a number
   * @param {Boolean} options.integer Number must be an integer
   * @param {Boolean} options.positive Number must be greater than or equal to 0
   * @param {Boolean} options.odd Number must be odd
   * @param {Boolean} options.even Number must be even
   *
   * dependent options
   * @param {String} options.is Number must be equal to this value
   * @param {String} options.lt Number must be less than this value
   * @param {String} options.lte Number must be less than or equal to this value
   * @param {String} options.gt Number must be greater than or equal to this value
   * @param {String} options.gte Number must be greater than or equal to this value
   * @param {String} options.multipleOf Number must be a multiple of this value
   *
   * @param {Object} changes
   * @param {Object} content
   *
   * @return {Boolean|ValidationErr}
   */
  function validateNumeral() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    options = (0, _polyfills.assign)({
      allowString: true,
      allowNone: false
    }, options);

    // set the ember-validator default to true
    if (options.allowBlank) {
      options.allowNone = true;
    }
    const message = options.message || false;
    const {
      is,
      lt,
      lte,
      gt,
      gte
    } = (0, _object.getProperties)(options, DEPENDENT_KEYS);
    // check if there is a dependent property the value has to be validated agains
    const hasDependentProperties = isString(is) || isString(lt) || isString(lte) || isString(gt) || isString(gte);
    return (key, newValue, oldValue, changes, content) => {
      // allow comma as decimal separator
      if (options.allowString && isString(newValue)) {
        newValue = newValue.replace(',', '.');
      }

      // validate number format as from ember-validators
      let result = (0, _emberValidators.validate)('number', newValue, options, null, key);

      // if empty value is allowed, skip dependent properties check
      if (options.allowBlank && (0, _utils.isEmpty)(newValue)) {
        return true;
      }
      if (hasDependentProperties) {
        for (let i = 0; i < DEPENDENT_KEYS.length; i++) {
          let type = DEPENDENT_KEYS[i];
          // run validation only if the options value is a string
          if (isString(options[type])) {
            let dependencyValue = (0, _object.get)(changes, `${options[type]}`) || (0, _object.get)(content, `${options[type]}`);

            // allow comma as decimal separator for dep value
            if (options.allowString && isString(dependencyValue)) {
              dependencyValue = dependencyValue.replace(',', '.');
            }
            let returnValue = _validateType(type, newValue, dependencyValue);
            if (returnValue === false) {
              result = false;
              return message;
            }
          }
        }
      }
      return result === true ? true : message;
    };
  }

  /**
   * @function isString
   *
   * Check if the given value is a string.
   *
   * @param {Any} value - The value to check.
   * @return {Boolean} Returns `true` if the given value is a string, else `false`.
   */
  function isString(value) {
    return typeof value === 'string' && value !== null;
  }

  /**
   * @function _validateType
   *
   * @param {String} type the type of comparison
   * @param {Number} value the orginial value
   * @param {Number} compareValue the Number the value gets compared against
   * @private
   *
   * @return {Boolean} true if valid
   */
  const _validateType = (type, value, compareValue) => {
    let actual = Number(value);
    let expected = Number(compareValue);
    if (type === 'is' && actual !== expected) {
      return false;
    } else if (type === 'lt' && actual >= expected) {
      return false;
    } else if (type === 'lte' && actual > expected) {
      return false;
    } else if (type === 'gt' && actual <= expected) {
      return false;
    } else if (type === 'gte' && actual < expected) {
      return false;
    }
    return true;
  };
});