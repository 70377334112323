define("ember-composable-helpers/helpers/sort-by", ["exports", "@ember/object", "@ember/array", "@ember/object/computed", "@ember/component/helper", "@ember/utils"], function (_exports, _object, _array, _computed, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    compute(params) {
      // slice params to avoid mutating the provided params
      let sortProps = params.slice();
      let array = sortProps.pop();
      let [firstSortProp] = sortProps;
      if ((0, _utils.typeOf)(firstSortProp) === 'function' || (0, _array.isArray)(firstSortProp)) {
        sortProps = firstSortProp;
      }

      // TODO: can we / should we use variables instead of computed properties?
      (0, _object.set)(this, 'array', array);
      (0, _object.set)(this, 'sortProps', sortProps);
      if ((0, _utils.isEmpty)(sortProps)) {
        (0, _object.defineProperty)(this, 'content', []);
      }
      if (typeof sortProps === 'function') {
        (0, _object.defineProperty)(this, 'content', (0, _computed.sort)('array', sortProps));
      } else {
        (0, _object.defineProperty)(this, 'content', (0, _computed.sort)('array', 'sortProps'));
      }
      return this.content;
    }
  });
});