define("ember-svg-jar/inlined/success-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke-width=\"1.5\"><circle cx=\"184\" cy=\"128\" r=\"79.25\" stroke=\"#00172F\"/><g stroke=\"#c6ab8d\" stroke-linecap=\"round\"><path d=\"M218.284 106.716L177 148M156.716 127.716L177 148\"/></g></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});