define("ember-svg-jar/inlined/singlesubscriber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.7 16.2c-1.8-.5-3.3-1.3-3.3-2v-1.8c0-.4 1.2-1.3 1.2-2.7 0-1 .3-.8.5-1.9.1-.8 0-2 .3-2.7.1-.6-.7-4.1-4.3-4.1-3.8 0-4.6 3.5-4.4 4.1.1.6 0 1.9.1 2.7.3 1.2.5 1 .5 1.9 0 1.5 1.2 2.3 1.2 2.7v1.8c0 .8-1.6 1.5-3.3 2-1.7.5-4.2 2.4-3 3.6 2.1 2 2.9 3.2 4.4 3.2h8.6c1.7 0 2.3-1.2 4.4-3.2 1.1-1.1-1.2-3.1-2.9-3.6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});