define("ember-svg-jar/inlined/stay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.5 14a6.5 6.5 0 10-12.981.5l12.962.002c.013-.166.019-.334.019-.502zM12.25 4a.5.5 0 01.5.5v1.535a8 8 0 017.088 9.57.5.5 0 01-.489.397l-6.599-.001V17.5h5.75a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5V18a.5.5 0 01.5-.5h5.75v-1.499h-6.6a.5.5 0 01-.489-.397A8.043 8.043 0 014 14a8 8 0 017.25-7.965V4.5a.5.5 0 01.5-.5h.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});