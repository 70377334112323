define("@additive-apps/ui/utils/close-tab-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeWarningDialogBeforeClose = removeWarningDialogBeforeClose;
  _exports.showWarningDialogBeforeClose = showWarningDialogBeforeClose;
  function listenerFunction(e, showDialogFunction) {
    const showDialog = showDialogFunction && showDialogFunction();
    if (!showDialog) return;
    e = e || window.event;
    // For IE and Firefox prior to version 4
    if (e) {
      e.preventDefault();
      e.returnValue = '';
    }

    // For Safari
    return '';
  }

  // Adds event listener before tab/window is reloaded/closed. A popup is shown to the user
  function showWarningDialogBeforeClose(router, currentRouteName, showDialogFunction, onChangeUrlWhenDirty) {
    let excludeRouteChangeListener = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    // Native
    window.onbeforeunload = event => listenerFunction(event, showDialogFunction);

    // Ember changes url
    if (!excludeRouteChangeListener) {
      router.on('routeWillChange', transition => {
        const doesChangeRoute = !transition.to.name.startsWith(currentRouteName);
        if (doesChangeRoute && showDialogFunction()) {
          router.transitionTo(router.currentRouteName);
          onChangeUrlWhenDirty();
        }
      });
    }
  }
  function removeWarningDialogBeforeClose(router) {
    window.onbeforeunload = null;
    router.on('routeWillChange', null);
  }
});