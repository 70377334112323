define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 16.893l-4.402 2.315a.5.5 0 01-.725-.527l.84-4.903-3.561-3.471a.5.5 0 01.277-.853l4.922-.715 2.201-4.46a.5.5 0 01.897 0l2.2 4.46 4.923.715a.5.5 0 01.277.853l-3.562 3.471.841 4.903a.5.5 0 01-.725.527z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});