define("@additive-apps/utils/validators/currency", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = void 0;
  _exports.validateCurrency = validateCurrency;
  const EU_FORMAT = new RegExp(/^[0-9]{1,3}(?:\.?[0-9]{3})*(?:,[0-9]{0,2})?$/);

  /**
   *  @module Validators
   */

  /**
   * @function validateCurrency
   * @param {Object} options
   *
   * @param {String} options.allowBlank If true, skips validation if the value is empty
   * @param {Object} changes
   * @param {Object} content
   *
   * @return {Boolean|ValidationErr}
   */
  function validateCurrency() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let {
      allowBlank
    } = options;
    return (key, newValue) => {
      // if allowBlank is set and the value is empty skip validation
      if (allowBlank && (0, _utils.isEmpty)(newValue)) {
        return true;
      }
      return EU_FORMAT.test(newValue);
    };
  }

  /**
   * Localizes a given numeral value to a choosen currency. The intl-localization
   * uses always the standard german format and should not be modified.
   *
   * @param {Array} params
   *
   * @param {Number} amount, the numeral value to be localized
   * @param {String} currency, the currency in ISO-format i.e. EUR, USD, ..
   * @param {Int} decimalCount, the number of decimal digits, default: 2
   * @param {Int} divider, the multiplier ratio a value should be modified before converting, default: 1
   * @function formatCurrency
   */
  const formatCurrency = _exports.formatCurrency = function formatCurrency(amount) {
    let currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'EUR';
    let decimalCount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    let divider = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
    const roundedAmount = Math.floor(100 * amount) / 100;
    const formattedAmount = (roundedAmount / divider).toLocaleString('de-DE', {
      style: 'currency',
      currency,
      minimumFractionDigits: decimalCount,
      maximumFractionDigits: decimalCount
    });
    return formattedAmount;
  };
});