define("ember-svg-jar/inlined/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.5 4a.5.5 0 01.5.5V5h3.5a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5H9v-.5a.5.5 0 01.5-.5h5zM9 6.5H6.5v11h11v-11H15v2a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-2zm6.5 7.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-7A.5.5 0 018 15v-.5a.5.5 0 01.5-.5h7zm0-3a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-7A.5.5 0 018 12v-.5a.5.5 0 01.5-.5h7zm-2-5.5h-3v2h3v-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});