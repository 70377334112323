define("ember-animated/components/ea-list-element", ["exports", "@ember/debug", "@ember/component", "ember-animated/-private/ember-internals"], function (_exports, _debug, _component, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /*
     This component has one job: tracking which DOM elements correspond
     with which list elements.
  */

  class _default extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "isEmberAnimatedListElement", true);
      _defineProperty(this, "child", void 0);
      _defineProperty(this, "elementToChild", void 0);
    }
    didRender() {
      let mapping = this.get('elementToChild');
      let child = this.get('child');
      this._forEachElement(elt => {
        mapping.set(elt, child);
      });
    }
    _forEachElement(fn) {
      let {
        firstNode,
        lastNode
      } = (0, _emberInternals.componentNodes)(this);
      let node = firstNode;
      while (node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          fn(node);
        } else if (!/^\s*$/.test(node.textContent)) {
          (false && (0, _debug.warn)('Found bare text content inside an animator', false, {
            id: 'ember-animated-bare-text'
          }));
        }
        if (node === lastNode) {
          break;
        }
        node = node.nextSibling;
      }
    }
  }
  _exports.default = _default;
});