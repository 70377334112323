define("ember-svg-jar/inlined/save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.793 5a.5.5 0 01.353.146l3.708 3.708a.5.5 0 01.146.353V18.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h9.293zM10 17.5V15h3.5v2.5H10zm7.5 0H15V14a.5.5 0 00-.5-.5H9a.5.5 0 00-.5.5v3.5h-2v-11h2V10a.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5H10V6.5h4.379L17.5 9.621V17.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});