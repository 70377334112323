define("@additive-apps/ui/components/ui-timepicker", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-timepicker", "@ember/object", "ember-changeset", "ember-changeset-validations/validators", "ember-changeset-validations", "date-fns", "@ember/utils"], function (_exports, _component, _uiTimepicker, _object, _emberChangeset, _validators, _emberChangesetValidations, _dateFns, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components

  const TIME_REGEX = /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/;

  /**
   * Component to select time
   *
   * ```hbs
   * {{ui-timepicker
   *   minTime
   *   maxTime
   *   initialValue
   *   label
   *   onChange
   *   onValidStateChange
   * }}
   * ```
   *
   * @class ui-timepicker
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiTimepicker.default,
    /**
     * Whether empty is valid
     *
     * @property allowEmptyValue
     * @type {Boolean}
     * @default false
     */
    allowEmptyValue: false,
    /**
     * custom error message
     *
     * @property errorMessage
     * @type {String}
     * @default null
     */
    errorMessage: null,
    /**
     * the initial value of the input
     *
     * @property initialValue
     * @type {String}
     * @default null
     */
    initialValue: null,
    /**
     * an info message to be shown below the input
     *
     * @property infoMessage
     * @type {String}
     * @default null
     */
    infoMessage: null,
    /**
     * whether the input has been touched and an error message can be shown
     *
     * @property isTouched
     * @type {Boolean}
     * @default false
     *
     */
    isTouched: false,
    /**
     * the label of the input
     *
     * @property label
     * @type {String}
     * @default null
     */
    label: null,
    /**
     * the minimal selectable time
     *
     * @property minTime
     * @type {String}
     * @default '0:00'
     */
    minTime: '0:00',
    /**
     * the maximal selectable time
     *
     * @property maxTime
     * @type {String}
     * @default '23:59'
     */
    maxTime: '23:59',
    /**
     * the placeholder value for the input
     *
     * @property placeholder
     * @type {String}
     * @default null
     */
    placeholder: null,
    /**
     * the theme of the timepicker
     *
     * @property theme
     * @type {String}
     * @default 'white'
     */
    theme: 'white',
    /**
     * whether the input should be read only
     *
     * @property isReadOnly
     * @type {Boolean}
     * @default false
     */
    isReadOnly: false,
    /**
     * the internal changeset
     *
     * @property _changeset
     * @type {Changeset}
     * @private
     */
    _changeset: null,
    /**
     * On true, uses internal changeset with validation
     *
     * @property useChangeset
     * @type {boolean}
     */
    useChangeset: true,
    /**
     * the changeset valid state of the last validation
     *
     * @property _changesetIsValid
     * @type {Changeset}
     * @private
     */
    _changesetIsValid: false,
    /**
     * The current input value
     *
     * @property _inputValue
     * @type {String}
     * @private
     */
    _inputValue: null,
    isClearable: false,
    /**
     * the selectable time options
     *
     * @computed options
     * @type {Array}
     */
    options: (0, _object.computed)('minTime', 'maxTime', {
      get() {
        const start = this._getDateTime(this.minTime);
        const end = this._getDateTime(this.maxTime);
        const options = [];
        let time = (0, _dateFns.setMinutes)(start, 0);
        while ((0, _dateFns.isWithinInterval)(time, {
          start,
          end
        })) {
          const hours = (0, _dateFns.getHours)(time);
          const minutes = (0, _dateFns.getMinutes)(time);
          options.push({
            key: `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
          });
          time = (0, _dateFns.addMinutes)(time, 30);
        }
        return options;
      }
    }),
    init() {
      this._super(...arguments);
      if (this.useChangeset) {
        // initialize changeset and validation on time
        const validation = {
          time: (0, _validators.validateFormat)({
            allowBlank: this.allowEmptyValue,
            regex: TIME_REGEX
          })
        };
        const changeset = new _emberChangeset.default({
          time: this.initialValue
        }, (0, _emberChangesetValidations.default)(validation), validation);
        (0, _object.set)(this, '_changeset', changeset);
        this._validateChangeset(false);
      }
      (0, _object.set)(this, '_initialValue', this.initialValue);
      (0, _object.set)(this, '_inputValue', this.initialValue);
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      if (!this._initialValue || JSON.stringify(this._initialValue) !== JSON.stringify(this.initialValue)) {
        (0, _object.set)(this, '_inputValue', this.initialValue);
        this.useChangeset && this._changeset.set('time', this.initialValue);
      }
    },
    /**
     * callback called when valid time is entered
     *
     * @function onChange
     * @param {Date} date
     */
    onChange() {},
    /**
     * callback called when changesets valid state changes
     *
     * @function onValidStateChange
     * @param {Boolean} isValid Whether changeset is valid
     */
    onValidStateChange(/* isValid */) {},
    /**
     * formats a time string to a date object
     *
     * @function _getDateTime
     * @param {String} time
     * @return {Date}
     */
    _getDateTime(time) {
      return (0, _dateFns.parse)(time, 'HH:mm', new Date());
    },
    /**
     * Updates `_changesetIsValid` variable and
     * triggers onValidStateChange when the state changes
     *
     * @function _updateValidState
     * @private
     */
    _updateValidState() {
      const changeset = this._changeset;
      changeset && changeset.validate().then(() => {
        if (this.isDestroying) {
          return;
        }
        const currentState = this._changesetIsValid;
        const isValid = changeset.get('isValid');
        if (currentState === !isValid) {
          (0, _object.set)(this, '_changesetIsValid', isValid);
          this.onValidStateChange(isValid);
        }
      });
    },
    /**
     * Validates changeset and updates internal valid state
     *
     * @function _validateChangeset
     * @private
     */
    _validateChangeset() {
      const changeset = this._changeset;
      return changeset.validate().then(() => {
        this._updateValidState();
        if (this.isDestroying || changeset.get('isInvalid')) {
          return;
        }
        if (this.allowEmptyValue && (0, _utils.isEmpty)(changeset.get('time'))) {
          this.onChange(null);
          return;
        }
        const timeString = changeset.get('time');
        const dateTime = this._getDateTime(timeString);
        const start = this._getDateTime(this.minTime);
        const end = this._getDateTime(this.maxTime);
        if ((0, _dateFns.isWithinInterval)(dateTime, {
          start,
          end
        })) {
          this.onChange(dateTime);
        } else {
          changeset.pushErrors('time', 'out_of_range');
        }
      });
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onClear(onClose, isSelectableOpen) {
        (0, _object.set)(this, '_inputValue', null);
        isSelectableOpen && onClose && onClose();
      },
      /**
       * handle the selected option of the dropdown
       *
       * @function onChangeSelect
       * @param {Object} option
       */
      onChangeSelect(option) {
        (0, _object.set)(this, '_inputValue', option.key);
        if (this.useChangeset) {
          this._changeset.set('time', option.key);
          this._updateValidState();
        }
        this.onChange(this._getDateTime(option.key));
      },
      onInputChange(value) {
        //If we want to validate the changeset on our own, we just return the changeset on input change
        if (!this.useChangeset) {
          const _value = /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/.test(value) ? this._getDateTime(value) : value;
          this.onChange(_value);
          return;
        }
        // add colon to input field after two digits have been entered
        if (value.length === 2 && value.indexOf(':') === -1) {
          value = `${value}:`;
        } else if (value.length > 2 && value.indexOf('::') > 0) {
          /* ':' gets added automatically, remove duplication when user enters ':' as well */
          value = value.replace('::', ':');
        }
        this._changeset.set('time', value);
        this._validateChangeset();
      }
    }
  });
});