define("ember-svg-jar/inlined/unformat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 6.5V7a.5.5 0 01-.5.5h-3.91l-1.726 7.118a.5.5 0 01-.486.382h-.515a.5.5 0 01-.486-.618L9.047 7.5H5.5A.5.5 0 015 7v-.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5zM17.06 15l2.117-2.116a.5.5 0 000-.707l-.354-.354a.5.5 0 00-.707 0L16 13.94l-2.116-2.116a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707L14.94 15l-2.116 2.116a.5.5 0 000 .707l.354.354a.5.5 0 00.707 0L16 16.06l2.116 2.116a.5.5 0 00.707 0l.354-.354a.5.5 0 000-.707L17.06 15zM10.5 18a.5.5 0 00.5-.5V17a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h5z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});