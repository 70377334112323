define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.84 11.25H5.15a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h11.69l-4.367 4.366a.5.5 0 000 .707l.354.354a.5.5 0 00.707 0l5.823-5.823a.5.5 0 000-.707l-5.823-5.824a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l4.366 4.366z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});