define("ember-svg-jar/inlined/vertical_alignment_top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5 4a.5.5 0 00-.5.5v7a.5.5 0 00.5.5H8a.5.5 0 00.5-.5v-7A.5.5 0 008 4h-.5zM11.25 4.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-15zM15.5 4.5A.5.5 0 0116 4h.5a.5.5 0 01.5.5v7a.5.5 0 01-.5.5H16a.5.5 0 01-.5-.5v-7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});