define("ember-svg-jar/inlined/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.958 14.987c.643-.913 1.622-1.045 2.569-.672.232.091.451.203.753.374-.038-.022.506.29.658.374.975.532 1.675.696 2.489.48C17.797 15.18 18.5 13.74 18.5 12a6.5 6.5 0 10-13 0c0 2.876 2.247 5.45 4.931 6.048-.91-.99-1.258-1.946-.473-3.061zm2.613 1.024c-.636-.361-1.095-.574-1.386-.16-.263.373.01.826.45 1.286l.154.155c1.005.98 2.574 1.958 1.136 2.252C8.307 20.487 4 16.418 4 12a8 8 0 1116 0c0 1.576-.585 4.303-3.189 4.993-1.768.47-3.126-.341-4.1-.902l-.14-.08zm2.354-3.346a1 1 0 111.95.45 1 1 0 01-1.95-.45zm-.427-3.936a1 1 0 111.259 1.554 1 1 0 01-1.259-1.554zm-6.402.148a1 1 0 111.554 1.258 1 1 0 01-1.554-1.258zM12 7a1 1 0 110 2 1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});