define("@additive-apps/utils/helpers/is-none", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isNoneHelper = isNoneHelper;
  function isNoneHelper(params) {
    return (0, _utils.isNone)(params[0]);
  }
  var _default = _exports.default = (0, _helper.helper)(isNoneHelper);
});