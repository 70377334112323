define("@additive-apps/utils/helpers/currency-symbol", ["exports", "@ember/component/helper", "@additive-apps/utils/utils/currency-util"], function (_exports, _helper, _currencyUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.currencySymbol = void 0;
  /**
   * Returns the currency symbol for the given currency code
   *
   * ```hbs
   *   {{currency-symbol currencyCode}}
   * ```
   *
   * @param {String} params[0], the ISO-formatted currency code
   * @function currencySymbol
   */
  const currencySymbol = params => {
    return (0, _currencyUtil.getCurrencySymbol)(params[0]);
  };
  _exports.currencySymbol = currencySymbol;
  var _default = _exports.default = (0, _helper.helper)(currencySymbol);
});