define("ember-svg-jar/inlined/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.4 4.782l.353.354a.5.5 0 010 .707l-1.061 1.06 1.414 1.414 1.06-1.06a.5.5 0 01.708 0l.354.354a.5.5 0 010 .707l-1.062 1.06.708.708a.5.5 0 010 .707l-1.768 1.768a3 3 0 01-4.242 0l-.177-.177-1.456 1.458c-.502.501-.932 1.293-.635 2.01.195.471 1.014 1.537 4.421 1.631a.506.506 0 01.497.498v.499c0 .28-.238.511-.518.503-3.934-.107-5.32-1.435-5.786-2.557-.485-1.171-.117-2.568.96-3.645l1.457-1.457-.178-.178a3 3 0 010-4.242l1.768-1.768a.5.5 0 01.707 0l.707.706 1.061-1.06a.5.5 0 01.707 0zm-2.83 2.122l-1.06 1.06a1.5 1.5 0 000 2.122l1.414 1.414a1.5 1.5 0 002.122 0l1.06-1.06-3.535-3.536z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});