define("ember-svg-jar/inlined/offer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.5 13a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm1.323-6.177l.354.354a.5.5 0 010 .707l-9.293 9.293a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707l9.293-9.293a.5.5 0 01.707 0zM15.5 14.5a1 1 0 100 2 1 1 0 000-2zM8.5 6a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0 1.5a1 1 0 100 2 1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});