define("ember-svg-jar/inlined/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 7.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5V11a.5.5 0 01-.5.5h-5A.5.5 0 019 11V7.5zm1.5 2.5V8.5h3V10h-3z\"/><path d=\"M9 13.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-5A.5.5 0 019 14v-.5zM9.5 15.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V16a.5.5 0 00-.5-.5h-5z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 4.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-15zm1.5 14v-13h9v13h-9z\"/><path d=\"M10.5 8.5V10h3V8.5h-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});