define("ember-svg-jar/inlined/tiktokCampaign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.667 11a8.667 8.667 0 11-17.334 0 8.667 8.667 0 0117.334 0zm2 0c0 5.891-4.776 10.667-10.667 10.667S.333 16.89.333 11 5.11.333 11 .333 21.667 5.11 21.667 11zm-7.334 2V7.89c.658.414 1.486.692 2.5.76.276.019.5-.207.5-.483v-1a.539.539 0 00-.5-.523c-1.026-.097-1.606-.49-1.943-.881a2.348 2.348 0 01-.488-.936c-.07-.267-.292-.494-.569-.494h-1a.5.5 0 00-.5.5V13a2.333 2.333 0 11-2.83-2.28c.27-.059.497-.277.497-.553v-1c0-.276-.225-.503-.5-.472A4.334 4.334 0 1014.334 13z\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});