define("@additive-apps/ui/utils/country-utils", ["exports", "ember-concurrency", "@ember/object", "@ember/application"], function (_exports, _emberConcurrency, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchCountries = void 0;
  /**
   * It fetches all available countries for the organization and billing select
   *
   * @function fetchCountries
   * @return {Array} countries
   */
  const fetchCountries = _exports.fetchCountries = {};
  const fetchCountriesTask = (0, _emberConcurrency.task)(function* (self) {
    const ENV = (0, _application.getOwner)(self).resolveRegistration('config:environment');
    const intl = (0, _application.getOwner)(self).lookup('service:intl');
    const authenticatedFetch = (0, _application.getOwner)(self).lookup('service:authenticatedFetch');
    const response = yield authenticatedFetch.fetch(
    // TODO: Update to use new helper api (Currently missing CURL response-header so we cannot use it yet)
    // `${ENV.APP.helperApiBaseHost}/api/countries`,
    `${ENV.APP.accountApiHost}/api/countries`, {
      headers: {
        // set language to active locale for now
        'Accept-Language': intl.locale || 'de'
      }
    });
    const data = yield response.json();
    if (data && data.countries) {
      return data.countries;
    }
    return [];
  }).drop();
  (0, _object.defineProperty)(fetchCountries, 'task', fetchCountriesTask);
});