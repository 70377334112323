define("@additive-apps/utils/utils/viewport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inViewport = _exports.detectBestPositionInViewport = void 0;
  /**
   * Tests if element is fully in browsers viewport.
   *
   * @function inViewport
   * @param {Object} element The DOM element to test
   * @return {Boolean} Whether its in viewport or not
   */
  const inViewport = element => {
    if (!element || !element.getBoundingClientRect) {
      return;
    }
    const bounding = element.getBoundingClientRect();
    return bounding.top >= 0 && bounding.left >= 0 && bounding.bottom <= document.documentElement.clientHeight && bounding.right <= document.documentElement.clientWidth;
  };

  /**
   * Checks if given element is in viewport.
   * It will return an array where array
   *  index 0 is `bestVerticalPosition` and
   *  index 1 is `bestHorizontalPosition`
   * which means the best position where this container would be visible if positioned there.
   *
   * @function detectBestPositionInViewport
   * @param {Object} element The DOM element to test
   * @param {Array?} desiredPosition The desired position if in viewport
   * @return {Array|Boolean}
   */
  _exports.inViewport = inViewport;
  const detectBestPositionInViewport = _exports.detectBestPositionInViewport = function detectBestPositionInViewport(element) {
    let desiredPosition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['top', 'left'];
    if (!element || !element.getBoundingClientRect) {
      return;
    }
    if (inViewport(element)) {
      return desiredPosition;
    }
    const bounding = element.getBoundingClientRect();
    const windowWidth = document.documentElement.clientWidth;
    const windowHeight = document.documentElement.clientHeight;

    /* Element to big to fit, so return false */
    if (bounding.width > windowWidth || bounding.height > windowHeight) {
      return false;
    }
    let bestVerticalPosition = null;
    let bestHorizontalPosition = null;
    const overflowsTop = bounding.top < 0;
    const overflowsBottom = bounding.bottom > windowHeight;
    const overflowsLeft = bounding.left < 0;
    const overflowsRight = bounding.right > windowWidth;
    bestVerticalPosition = overflowsTop ? 'bottom' : overflowsBottom ? 'top' : 'bottom';
    bestHorizontalPosition = overflowsLeft ? 'right' : overflowsRight ? 'left' : 'right';
    return [bestVerticalPosition, bestHorizontalPosition];
  };
});