define("@additive-apps/ui/templates/components/ui-tabs-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "oZCGXy1e",
    "block": "[[[10,0],[14,0,\"ui-routable-tabs__header-scroll-container flex gap1\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"tab\"],[[50,\"ui-tab-item\",0,null,[[\"activeTab\",\"selectItem\"],[[30,0,[\"_activeTab\"]],[28,[37,3],[[30,0],\"selectItem\"],null]]]]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-tabs-header.hbs",
    "isStrictMode": false
  });
});