define("@additive-apps/ui/templates/components/ui-selection-container/actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vs5Hrbje",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[41,[30,0,[\"destinationElement\"]],[[[40,[[[1,\"      \"],[8,[39,2],null,[[\"@toggleSelectionMode\",\"@isAllSelected\",\"@itemsCount\",\"@isVisible\",\"@isFixed\",\"@isLoading\",\"@loadingText\",\"@disableSelectAll\",\"@isInvertedSelection\"],[[30,0,[\"toggleSelectionMode\"]],[30,0,[\"isAllSelected\"]],[30,0,[\"itemsCount\"]],[30,0,[\"isVisible\"]],[30,0,[\"isFixed\"]],[30,0,[\"isLoading\"]],[30,0,[\"loadingText\"]],[30,0,[\"disableSelectAll\"]],[30,0,[\"isInvertedSelection\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[18,1,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"destinationElement\"]],null]],[]],[[[1,\"    \"],[8,[39,2],null,[[\"@toggleSelectionMode\",\"@isAllSelected\",\"@itemsCount\",\"@isVisible\",\"@isFixed\",\"@isLoading\",\"@loadingText\",\"@disableSelectAll\",\"@isInvertedSelection\"],[[30,0,[\"toggleSelectionMode\"]],[30,0,[\"isAllSelected\"]],[30,0,[\"itemsCount\"]],[30,0,[\"isVisible\"]],[30,0,[\"isFixed\"]],[30,0,[\"isLoading\"]],[30,0,[\"loadingText\"]],[30,0,[\"disableSelectAll\"]],[30,0,[\"isInvertedSelection\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[]],null]],[\"&default\"],false,[\"if\",\"in-element\",\"ui-selection-container/action-bar\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-selection-container/actions.hbs",
    "isStrictMode": false
  });
});