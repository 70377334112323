define("ember-svg-jar/inlined/past", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.083 5.665A8 8 0 0119.97 12a8.45 8.45 0 01-.156 1.604c-.615 3.166-3.196 5.697-6.37 6.263a8.458 8.458 0 01-1.109.123l-.343.009h-.022a.75.75 0 010-1.5l.233-.004A6.5 6.5 0 0018.47 12c0-3.598-3.098-6.604-6.693-6.498l-.301.016A6.466 6.466 0 007.949 6.89l-.065.054.017-.014 1.215 1.215.058.07A.5.5 0 018.762 9H5.47l-.09-.008a.5.5 0 01-.41-.492V5.207l.008-.09a.5.5 0 01.846-.263l1.011 1.011.25-.2zM9.081 19.46a.75.75 0 11.538-1.4.75.75 0 01-.538 1.4zM6.583 17.915A.75.75 0 117.587 16.8a.75.75 0 01-1.004 1.115zM4.805 15.56a.75.75 0 111.348-.657.75.75 0 01-1.348.657zM4.685 11.922l.102-.002.099.011a.75.75 0 11-.201-.01z\"/><path d=\"M12.25 8a.5.5 0 01.5.5v3.067l2.655 1.534a.5.5 0 01.183.682l-.25.434a.5.5 0 01-.683.182L11.5 12.577a.5.5 0 01-.25-.433V8.5a.5.5 0 01.5-.5h.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});