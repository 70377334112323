define("ember-svg-jar/inlined/org-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 19.5v-5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v4H3.5V9.75L10 4.875l6.132 4.599a.5.5 0 00.674-.068l.333-.375a.5.5 0 00-.074-.732L10.3 3.225a.5.5 0 00-.6 0L2.2 8.85a.5.5 0 00-.2.4V19.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5zM18 14.5a1 1 0 110 2 1 1 0 010-2z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.755 11.138a.5.5 0 01.49 0l3.5 1.969a.5.5 0 01.255.436v3.915a.5.5 0 01-.255.435l-3.5 1.97a.5.5 0 01-.49 0l-3.5-1.97a.5.5 0 01-.255-.435v-3.915a.5.5 0 01.255-.436l3.5-1.97zM18 18.279l-2.5-1.406v-2.746l2.5-1.406 2.5 1.406v2.746L18 18.279z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});