define("ember-svg-jar/inlined/rename", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.73 6.141c.137.148.27.396.27.859v4.25h-1.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5H13V17c0 .463-.133.71-.27.858-.15.163-.374.28-.662.352-.17.042-.344.067-.52.077-.294.017-.548.242-.548.537v.487c0 .264.205.485.468.479a4.41 4.41 0 00.964-.125c.432-.108.92-.313 1.318-.704.399.39.886.596 1.318.704.347.087.68.118.964.125a.467.467 0 00.468-.479v-.487c0-.295-.254-.52-.548-.537a2.794 2.794 0 01-.52-.077c-.288-.072-.512-.19-.662-.352-.137-.147-.27-.395-.27-.858v-4.25H16a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-1.5V7c0-.463.133-.71.27-.859.15-.162.374-.28.662-.351.17-.042.344-.067.52-.077.294-.017.548-.243.548-.537v-.487a.467.467 0 00-.468-.479 4.413 4.413 0 00-.964.125c-.432.108-.92.313-1.318.704-.399-.391-.886-.596-1.318-.704a4.412 4.412 0 00-.964-.125.467.467 0 00-.468.479v.487c0 .294.254.52.548.537.176.01.35.035.52.077.288.072.512.189.662.351zM5.5 14.5v-5H11a.5.5 0 00.5-.5v-.5A.5.5 0 0011 8H4.5a.5.5 0 00-.5.5v7a.5.5 0 00.5.5H11a.5.5 0 00.5-.5V15a.5.5 0 00-.5-.5H5.5zm11 1.5a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h2v-5h-2A.5.5 0 0116 9v-.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});