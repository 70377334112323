define("ember-svg-jar/inlined/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.354 4.293l3.823 3.823a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0L12.75 6.81v7.69a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V6.81L8.884 9.177a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707l3.823-3.823a.5.5 0 01.708 0zM6.5 18.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V19a.5.5 0 00-.5-.5h-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});