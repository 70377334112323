define("ember-svg-jar/inlined/vip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.697 16.5h8.606L17.8 9.908l-3.947 2.153L12 8.354l-1.854 3.707L6.2 9.908 7.697 16.5zM14.5 10l4.527-2.47a.5.5 0 01.727.55l-2.166 9.53a.5.5 0 01-.487.39H6.899a.5.5 0 01-.487-.39L4.245 8.08a.5.5 0 01.727-.55L9.5 10l2.053-4.106a.5.5 0 01.894 0L14.5 10z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});