define("ember-svg-jar/inlined/share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.5 4.5a2.5 2.5 0 11-1.624 4.4l-3.538 2.211a2.495 2.495 0 010 1.777l3.538 2.211a2.5 2.5 0 11-.796 1.273l-3.68-2.3a2.5 2.5 0 110-4.144l3.68-2.3A2.5 2.5 0 0115.5 4.5zm0 11.5a1 1 0 100 2 1 1 0 000-2zM8 11a1 1 0 100 2 1 1 0 000-2zm7.5-5a1 1 0 100 2 1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});