define("ember-svg-jar/inlined/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#00172f\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M49.72 33.777a8.272 8.272 0 00-4.825 1.545c-.63-3.591-3.78-6.322-7.572-6.322-4.247 0-7.69 3.422-7.69 7.643 0 .396.031.785.09 1.165a6.383 6.383 0 00-1.393-.155c-3.496 0-6.33 2.814-6.33 6.289s2.834 6.289 6.33 6.289h21.39c4.573 0 8.28-3.682 8.28-8.227s-3.707-8.227-8.28-8.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80"
    }
  };
});