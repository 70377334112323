define("@additive-apps/ui/utils/chart-defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chartStackedBarDefaults = _exports.chartSankeyDefaults = _exports.chartHorizontalBarDefaults = _exports.chartDefaults = void 0;
  const CANVAS_SPACING = {
    left: 0,
    right: 24,
    top: 0,
    bottom: 24
  };
  const chartDefaults = _exports.chartDefaults = {
    responsive: true,
    type: 'line',
    data: {
      // needed for sankey chart
      datasets: [],
      font: {
        family: 'Barlow'
      }
    },
    options: {
      layout: {
        padding: CANVAS_SPACING
      },
      scales: {
        y: {
          ticks: {
            /* display: false, */
            autoSkip: false,
            font: {
              family: 'Barlow'
            },
            callback: function callback(value) {
              if (isNaN(value)) {
                return value;
              }
              return Number(value).toLocaleString('de-DE');
            }
          }
        },
        x: {
          autoSkip: false,
          display: true,
          ticks: {
            font: {
              family: 'Barlow'
            }
          },
          gridLines: {
            display: false
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      },
      elements: {
        line: {
          fill: false,
          borderWidth: 2,
          tension: 0
        },
        point: {
          borderColor: '#fff',
          hoverBorderColor: '#fff',
          borderWidth: 2,
          radius: 4,
          hoverRadius: 6
        }
      }
    }
  };
  const chartHorizontalBarDefaults = _exports.chartHorizontalBarDefaults = {
    options: {
      indexAxis: 'y',
      maintainAspectRatio: false,
      layout: {
        padding: CANVAS_SPACING
      },
      scales: {
        x: {
          type: 'linear',
          ticks: {
            beginAtZero: true,
            font: {
              family: 'Barlow'
            },
            callback: function callback(value) {
              if (isNaN(value)) {
                return value;
              }
              return Number(value).toLocaleString('de-DE');
            }
          },
          gridLines: {
            display: true
          }
        },
        y: {
          type: 'category',
          offset: true,
          ticks: {
            font: {
              family: 'Barlow',
              size: 14,
              weight: 500
            },
            textAlign: 'left',
            padding: 40,
            color: '#00172F',
            callback: function callback(index) {
              if (isNaN(this.chart.data.labels[index])) {
                return this.chart.data.labels[index];
              }
              return Number(this.chart.data.labels[index]).toLocaleString('de-DE');
            }
          },
          gridLines: {
            display: false
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    }
  };
  const chartStackedBarDefaults = _exports.chartStackedBarDefaults = {
    type: 'bar',
    options: {
      scales: {
        x: {
          stacked: true
        }
      }
    }
  };
  const chartSankeyDefaults = _exports.chartSankeyDefaults = {
    type: 'sankey',
    options: {}
  };
});