define("ember-svg-jar/inlined/publish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.75 13.81v5.69a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-5.69l-1.366 1.367a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707l2.823-2.823a.5.5 0 01.708 0l2.823 2.823a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0zm5.1-.647l-.325-.391a.487.487 0 01.03-.647c.376-.4.843-1.08.57-2-.307-1.037-1.541-1.378-2.253-1.43a.282.282 0 01-.264-.258c-.08-.87-.627-2.741-2.326-2.92-2.022-.217-2.634 1.496-2.849 2.725a.292.292 0 01-.423.21c-1.039-.531-2.751-1.013-3.917.302-1.139 1.284-.344 2.727.121 3.364.146.2.115.476-.065.648l-.367.349a.5.5 0 01-.742-.052c-.904-1.198-1.767-3.391-.069-5.305 1.315-1.482 3.126-1.492 4.352-1.173.518-1.282 1.772-2.8 4.116-2.56 2.132.226 3.14 2.062 3.478 3.345.79.209 2.19.789 2.647 2.329.466 1.574-.41 2.863-.964 3.485a.497.497 0 01-.75-.021z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});