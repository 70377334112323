define("ember-svg-jar/inlined/empty-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g stroke=\"#8f9ea4\" transform=\"translate(116 74)\"><path d=\"M109.001 66H16.315L0 0h148\"/><path d=\"M24.672 90.928h76.308L130.626 0\"/><circle cx=\"44\" cy=\"107\" r=\"8\"/><circle cx=\"84\" cy=\"107\" r=\"8\"/></g><g stroke=\"#cad0d3\" stroke-linecap=\"round\" stroke-width=\"1.5\"><path d=\"M165.858 93.358l28.284 28.284M165.858 121.642l28.284-28.284\"/></g></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});