define("ember-svg-jar/inlined/earth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-9.068 6.413c.234-.599-.02-1.2-.761-1.805-.58-.472-.327-.81-.026-1.215.375-.504.826-1.11-.177-2.205-.637-.696-2.033-1.724-4.187-3.085a6.502 6.502 0 005.15 8.31zm2.882-12.657a6.503 6.503 0 013.006 10.605c-.3-.553-.839-1.054-1.617-1.502a1.335 1.335 0 01-.658-1.16v-2.49a.65.65 0 00-.636-.663H8.888a.252.252 0 01-.207-.11.272.272 0 01.058-.37 8.26 8.26 0 011.431-.84c.652-.307.199-.689-.249-1.065-.379-.319-.753-.634-.452-.898.305-.266.787-.08 1.342.136.602.234 1.29.501 1.93.258.47-.178.665-.751.862-1.329.067-.195.133-.39.21-.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});