define("ember-svg-jar/inlined/arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.164 11.25h11.69a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H7.164l4.366 4.366a.5.5 0 010 .707l-.353.354a.5.5 0 01-.707 0l-5.824-5.823a.5.5 0 010-.707l5.824-5.824a.5.5 0 01.707 0l.353.354a.5.5 0 010 .707L7.164 11.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});