define("ember-svg-jar/inlined/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 12.75c1.398 0 2.572.956 2.905 2.25H19.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5l-10.095.001A3.001 3.001 0 013.5 15.75a3 3 0 013-3zm0 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm7.5-9c1.398 0 2.572.956 2.905 2.25H19.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5l-2.595.001a3.001 3.001 0 01-5.81 0L4.5 9a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h6.595A3.001 3.001 0 0114 5.25zm0 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});