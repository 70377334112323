define("@additive-apps/ui/templates/components/ui-radio-button-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "6YauxTAx",
    "block": "[[[44,[[28,[37,1],null,[[\"option\"],[[50,\"ui-radio-button\",0,null,[[\"selected\"],[[30,0,[\"selected\"]]]]]]]]],[[[41,[30,1],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"    \"],[18,4,[[28,[37,1],null,[[\"option\"],[[30,1,[\"option\"]]]]],[30,2],[30,3]]],[1,\"\\n\"]],[2,3]],null]],[]],null]],[1]]]],[\"group\",\"option\",\"index\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-radio-button-group.hbs",
    "isStrictMode": false
  });
});