define("ember-changeset/utils/handle-multiple-validations", ["exports", "ember-changeset/utils/is-promise"], function (_exports, _isPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = handleMultipleValidations;
  /**
   * Rejects `true` values from an array of validations. Returns `true` when there
   * are no errors, or the error object if there are errors.
   *
   * @private
   * @param  {Array} validations
   * @return {Promise<Boolean|Any>}
   */
  async function handleValidationsAsync(validations) {
    try {
      const result = await Promise.all(validations);
      const maybeFailed = result.filter(val => typeof val !== 'boolean' && val);
      return maybeFailed.length === 0 || maybeFailed;
    } catch (e) {
      return e;
    }
  }
  /**
   * Rejects `true` values from an array of validations. Returns `true` when there
   * are no errors, or the error object if there are errors.
   *
   * @private
   * @param  {Array} validations
   * @return {Boolean|Any}
   */
  function handleValidationsSync(validations) {
    const maybeFailed = validations.filter(val => typeof val !== 'boolean' && val);
    return maybeFailed.length === 0 || maybeFailed;
  }
  /**
   * Handles an array of validators and returns Promise.all if any value is a
   * Promise.
   *
   * @public
   * @param  {Array} validators Array of validator functions
   * @param  {String} options.key
   * @param  {Any} options.newValue
   * @param  {Any} options.oldValue
   * @param  {Object} options.changes
   * @param  {Object} options.content
   * @return {Promise|Boolean|Any}
   */
  function handleMultipleValidations(validators, _ref) {
    let {
      key,
      newValue,
      oldValue,
      changes,
      content
    } = _ref;
    let validations = Array.from(validators.map(validator => validator(key, newValue, oldValue, changes, content)));
    if (validations.some(_isPromise.default)) {
      return Promise.all(validations).then(handleValidationsAsync);
    }
    return handleValidationsSync(validations);
  }
});