define("ember-svg-jar/inlined/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.18 9.43l-.354-.354a.5.5 0 00-.707 0l-4.116 4.116-2.116-2.116a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l2.824 2.823a.5.5 0 00.707 0l4.823-4.823a.5.5 0 000-.707z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 12a8 8 0 11-16 0 8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});