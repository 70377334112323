define("ember-arg-types/-private/throw-console-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = throwConsoleError;
  function throwConsoleError(fn) {
    let enabled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    const original = console.error;
    console.error = enabled ? msg => {
      const errorMsg = msg.replace(/^Warning: /, '');
      throw new Error(errorMsg);
    } : original;
    try {
      fn();
    } catch (e) {
      throw e;
    } finally {
      console.error = original;
    }
  }
});