define("ember-svg-jar/inlined/direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.354 3.353l8.292 8.293a.5.5 0 010 .707l-8.293 8.293a.5.5 0 01-.707 0l-8.292-8.293a.5.5 0 010-.707l8.292-8.293a.5.5 0 01.707 0zM12 5.121L5.121 12 12 18.879l6.879-6.88L12 5.122zm1.5 4.586a.5.5 0 01.354.146l1.792 1.793a.5.5 0 010 .707l-1.793 1.793a.5.5 0 01-.853-.353v-1.044H8.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5H13v-1.042a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});