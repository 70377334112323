define("ember-svg-jar/inlined/placeholder-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.538 19H5.461a.5.5 0 01-.499-.468C4.636 13.511 6.982 11 12 11c5.018 0 7.364 2.51 7.037 7.532a.5.5 0 01-.499.468zM12 9a2.5 2.5 0 110-5 2.5 2.5 0 010 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});