define("ember-svg-jar/inlined/bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19 5a.5.5 0 01.5.5v13a.5.5 0 01-.5.5H4.5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h3v-2.5A.5.5 0 018 11h3V8.5a.5.5 0 01.5-.5h3V5.5A.5.5 0 0115 5h4zM7.5 15.5h-2v2h2v-2zm3.5-3H9v5h2v-5zm3.5-3h-2v8h2v-8zm3.5-3h-2v11h2v-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});