define("@additive-apps/ui/utils/app-utils", ["exports", "@additive-apps/ui/constants", "ember-concurrency", "@ember/application", "@ember/object"], function (_exports, _constants, _emberConcurrency, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchAccessibleAppsTask = _exports.fetchAccessibleApps = _exports.LANDINGPAGE_LINK = _exports.APPS_WITHOUT_ENTERPRISE_PLAN = void 0;
  _exports.getAppKeyById = getAppKeyById;
  _exports.getAppUrlById = getAppUrlById;
  _exports.getRouteByContentType = getRouteByContentType;
  _exports.getTotalAppsCount = void 0;
  _exports.getUrlByContentType = getUrlByContentType;
  _exports.isSaasBuyable = isSaasBuyable;
  const LANDINGPAGE_LINK = _exports.LANDINGPAGE_LINK = 'https://www.additive-apps.eu';
  const APP_DATA_BY_ID = {
    [_constants.APP_ID_ACCOUNT]: {
      key: 'account',
      url: 'https://account.additive-apps.eu',
      staging_url: 'https://staging.account.additive-apps.eu',
      production_v2_url: 'https://v2.account.additive-apps.eu'
    },
    [_constants.APP_ID_VOUCHER]: {
      key: 'voucher',
      url: 'https://gutscheine.additive-apps.eu',
      staging_url: 'https://staging.gutscheine.additive-apps.eu',
      production_v2_url: 'https://v2.gutscheine.additive-apps.eu',
      isSaasBuyable: true
    },
    [_constants.APP_ID_CONTENT]: {
      key: 'content',
      url: 'https://inhalte.additive-apps.eu',
      staging_url: 'https://staging.inhalte.additive-apps.eu',
      production_v2_url: 'https://v2.inhalte.additive-apps.eu'
    },
    [_constants.APP_ID_MULTIMEDIA]: {
      key: 'media'
    },
    [_constants.APP_ID_MAILER]: {
      key: 'mailer'
    },
    [_constants.APP_ID_NEWSLETTER]: {
      key: 'newsletter',
      url: 'https://newsletter.additive-apps.eu',
      staging_url: 'https://staging.newsletter.additive-apps.eu',
      production_v2_url: 'https://v2.newsletter.additive-apps.eu'
    },
    [_constants.APP_ID_GUEST_INTRANET]: {
      key: 'guest-intranet',
      url: 'https://gaeste-intranet.additive-apps.eu',
      staging_url: 'https://staging.gaeste-intranet.additive-apps.eu',
      production_v2_url: 'https://v2.gaeste-intranet.additive-apps.eu'
    },
    [_constants.APP_ID_JOURNAL]: {
      key: 'journal',
      url: 'https://journal.additive-apps.eu',
      staging_url: 'https://staging.journal.additive-apps.eu',
      production_v2_url: 'https://v2.journal.additive-apps.eu'
    },
    [_constants.APP_ID_ENQUIRY]: {
      key: 'enquiry',
      url: 'https://anfragen.additive-apps.eu',
      staging_url: 'https://staging.anfragen.additive-apps.eu',
      production_v2_url: 'https://v2.anfragen.additive-apps.eu'
    },
    [_constants.APP_ID_CRM]: {
      key: 'crm',
      url: 'https://crm.additive-apps.eu',
      staging_url: 'https://staging.crm.additive-apps.eu',
      production_v2_url: 'https://v2.crm.additive-apps.eu'
    },
    [_constants.APP_ID_MARKETING_CLOUD]: {
      key: 'marketing-cloud',
      url: 'https://marketing-cloud.additive-apps.eu',
      staging_url: 'https://staging.marketing-cloud.additive-apps.eu',
      production_v2_url: 'https://v2.marketing-cloud.additive-apps.eu'
    },
    [_constants.APP_ID_MARKETING_INSIGHTS]: {
      key: 'marketing-insights',
      url: 'https://marketing-insights.additive-apps.eu',
      staging_url: 'https://staging.marketing-insights.additive-apps.eu',
      production_v2_url: 'https://v2.marketing-insights.additive-apps.eu'
    },
    [_constants.APP_ID_CMS]: {
      key: 'cms'
    }
  };

  /* Temporary: Apps which currently do not have a enterprise plan */
  const APPS_WITHOUT_ENTERPRISE_PLAN = _exports.APPS_WITHOUT_ENTERPRISE_PLAN = [_constants.APP_ID_CRM];

  /**
   * Returns the key of a given app id
   *
   * @param {Int} appId
   * @function getAppKeyById
   * @return {String}
   */
  function getAppKeyById() {
    let appId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    if (!appId || !APP_DATA_BY_ID[appId]) {
      return '';
    }
    return APP_DATA_BY_ID[appId].key;
  }

  /**
   * Returns the url of a given app id
   *
   * @param {Int} appId
   * @param {Boolean} isStaging
   * @function getAppUrlById
   * @return {String}
   */
  function getAppUrlById() {
    let appId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let environment = arguments.length > 1 ? arguments[1] : undefined;
    if (!appId || !APP_DATA_BY_ID[appId] || !APP_DATA_BY_ID[appId].url) {
      return '';
    }
    if (environment === 'staging') {
      return APP_DATA_BY_ID[appId].staging_url;
    }
    if (environment === 'productionV2') {
      return APP_DATA_BY_ID[appId].production_v2_url;
    }
    return APP_DATA_BY_ID[appId].url;
  }

  /*
   * WIP: will be extended by routes that are relevant by other apps
   * provided external urls and internal routes for app-specific content types and settings
   */
  const appContentTypeRoutes = {
    4: {
      landingPages: {
        url: 'landing-pages',
        route: 'instance.landing-pages.landing-page'
      },
      posts: {
        url: 'posts',
        route: 'instance.contents.posts.index.posts'
      },
      comments: {
        url: 'posts',
        route: 'instance.contents.posts.index.posts.comment'
      },
      surveys: {
        url: 'surveys',
        route: 'instance.contents.surveys.index.survey'
      },
      usps: {
        url: 'pages',
        route: 'instance.contents.pages.index.page'
      }
    },
    7: {
      campaigns: {
        url: 'campaigns',
        route: 'instance.campaigns.index.campaign'
      },
      widgets: {
        url: 'styles/widgets',
        route: 'instance.styles.widgets.widget'
      },
      pmsIntegrations: {
        url: 'settings/integrations',
        route: 'instance.settings.integrations'
      },
      appIntegrations: {
        url: 'settings/integrations',
        route: 'instance.settings.integrations'
      },
      leadIntegrations: {
        url: 'settings/integrations',
        route: 'instance.settings.integrations'
      },
      thirdPartyTokens: {
        url: 'settings/integrations',
        route: 'instance.settings.integrations'
      }
    }
  };

  /**
   * Returns the route url of a given content type in an app
   *
   * @param {Int} appId
   * @param {String} contentType
   * @param {String} contentId
   * @function getUrlByContentType
   * @return {String}
   */
  function getUrlByContentType() {
    var _appContentTypeRoutes;
    let appId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let contentType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let contentId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    if (!appId || !contentType || !((_appContentTypeRoutes = appContentTypeRoutes[appId]) !== null && _appContentTypeRoutes !== void 0 && _appContentTypeRoutes[contentType])) {
      return '';
    }
    let route = appContentTypeRoutes[appId][contentType].url;
    if (contentId) {
      route += `/${contentId}`;
    }
    return route;
  }

  /**
   * Returns the internal route of a given content type in an app
   *
   * @param {Int} appId
   * @param {String} contentType
   * @function getRouteByContentType
   * @return {String}
   */
  function getRouteByContentType() {
    var _appContentTypeRoutes2;
    let appId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    let contentType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    if (!appId || !contentType || !((_appContentTypeRoutes2 = appContentTypeRoutes[appId]) !== null && _appContentTypeRoutes2 !== void 0 && _appContentTypeRoutes2[contentType])) {
      return '';
    }
    return appContentTypeRoutes[appId][contentType].route;
  }

  /**
   * Returns the isSaasBuyable boolean of a given app id
   *
   * @param {Int} appId
   * @function isSaasBuyable
   * @return {Boolean}
   */
  function isSaasBuyable() {
    let appId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    if (!appId || !APP_DATA_BY_ID[appId] || !APP_DATA_BY_ID[appId].isSaasBuyable) {
      return false;
    }
    return APP_DATA_BY_ID[appId].isSaasBuyable;
  }
  const getTotalAppsCount = () => Object.keys(APP_DATA_BY_ID).length;

  /**
   * It fetches all accessible apps for the selected organization
   *
   * @function fetchAccessibleApps
   * @return {Array} apps
   */
  _exports.getTotalAppsCount = getTotalAppsCount;
  const fetchAccessibleApps = _exports.fetchAccessibleApps = {};
  const fetchAccessibleAppsTask = _exports.fetchAccessibleAppsTask = (0, _emberConcurrency.task)(function* (self, organizationId) {
    var _ENV$APP, _currentUser$currentO;
    const ENV = (0, _application.getOwner)(self).resolveRegistration('config:environment');
    const accountApiUrl = ENV === null || ENV === void 0 || (_ENV$APP = ENV.APP) === null || _ENV$APP === void 0 ? void 0 : _ENV$APP.accountApiHost;
    const authenticatedFetch = (0, _application.getOwner)(self).lookup('service:authenticatedFetch');
    const currentUser = (0, _application.getOwner)(self).lookup('service:currentUser');
    // return previously fetched apps
    if (currentUser !== null && currentUser !== void 0 && (_currentUser$currentO = currentUser.currentOrganization) !== null && _currentUser$currentO !== void 0 && (_currentUser$currentO = _currentUser$currentO.accessibleApps) !== null && _currentUser$currentO !== void 0 && _currentUser$currentO.length) {
      return currentUser.currentOrganization.accessibleApps;
    }
    const response = yield authenticatedFetch.fetch(`${accountApiUrl}/api/organizations/${organizationId}/apps?accessible=1`);
    const data = yield response.json();
    if (data && data.apps) {
      if (currentUser !== null && currentUser !== void 0 && currentUser.currentOrganization) {
        currentUser.currentOrganization.set('accessibleApps', data.apps);
      }
      return data.apps;
    }
    return [];
  });
  (0, _object.defineProperty)(fetchAccessibleApps, 'task', fetchAccessibleAppsTask);
});