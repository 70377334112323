define("ember-svg-jar/inlined/portal-score", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.694 16.937L12 15.198l3.307 1.739-.631-3.682 2.675-2.608-3.697-.537L12 6.76l-1.653 3.35-3.697.537 2.675 2.608-.631 3.682zM12 16.893l-4.402 2.315a.5.5 0 01-.725-.527l.84-4.903-3.561-3.471a.5.5 0 01.277-.853l4.922-.715 2.201-4.46a.5.5 0 01.897 0l2.2 4.46 4.923.715a.5.5 0 01.277.853l-3.562 3.471.841 4.902a.5.5 0 01-.725.528L12 16.893z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});