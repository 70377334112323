define("ember-svg-jar/inlined/marketing-insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#00172f\" d=\"M7.692 0h32.616c2.675 0 3.645.278 4.623.801s1.745 1.29 2.268 2.268S48 5.017 48 7.692v32.616c0 2.675-.278 3.645-.801 4.623s-1.29 1.745-2.268 2.268-1.948.801-4.623.801H7.692c-2.675 0-3.645-.278-4.623-.801S1.324 45.909.801 44.93 0 42.983 0 40.308V7.692c0-2.675.278-3.645.801-4.623S2.091 1.324 3.07.801 5.017 0 7.692 0z\"/><path fill=\"#fff\" d=\"M34.142 15.882l-5.12 5.262a.5.5 0 01-.528.122l-2.486-.896a.5.5 0 00-.492.088l-6.092 5.125a.5.5 0 01-.57.052l-3.328-1.906a.5.5 0 00-.554.038l-1.777 1.369a.5.5 0 00-.195.396v4.884s0 .584.572.584h20.857S35 31 35 30.416V16.23a.5.5 0 00-.858-.349z\"/></g>",
    "attrs": {
      "width": "80",
      "height": "80",
      "viewBox": "0 0 48 48"
    }
  };
});