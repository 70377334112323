define("ember-svg-jar/inlined/unsplash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.5 5.5A.5.5 0 019 5h6a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-4zm1.5 3v-2h4v2h-4zM18.5 11a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V14h4v-2.5a.5.5 0 01.5-.5h4zm-10 4.5h7v-3h2v5h-11v-5h2v3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});