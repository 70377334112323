define("ember-svg-jar/inlined/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 20a.5.5 0 01-.5-.5v-12A.5.5 0 014 7h3V4.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5V7h3a.5.5 0 01.5.5v12a.5.5 0 01-.5.5H4zM15.5 5.5h-7v13H10v-2a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v2h1v-2a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v2h1.5v-13zm3.5 3h-2v10h2v-10zm-12 0H5v10h2v-10zm4 4.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.5zm2.5 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.5zM11 10a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.5zm2.5 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.5zM11 7a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h.5zm2.5 0a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H13a.5.5 0 01-.5-.5v-1A.5.5 0 0113 7h.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});