define("ember-svg-jar/inlined/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.921 6.5l1.25 5h9.658l1.25-5zm13.704 0l-2.28 9.121a.5.5 0 01-.485.379H6.5a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h8.579l.375-1.5H5.39a.5.5 0 01-.485-.379l-1.75-7A.5.5 0 013.64 5H20.5a.5.5 0 01.5.5V6a.5.5 0 01-.5.5zM8.5 20a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});