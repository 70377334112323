define("@additive-apps/utils/helpers/equal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.equal = equal;
  /**
   * This helper provides a way to compare two values. It returns true if
   * the provided values are equal and false otherwise.
   *
   * ```hbs
   *   {{if (equal value1 value2) 'then' 'else'}}
   * ```
   *
   * @param {Any} params[0], the first value to be compared
   * @param {Any} params[1], the second value to be compared
   * @function equal
   */
  function equal(params) {
    return params[0] === params[1];
  }
  var _default = _exports.default = (0, _helper.helper)(equal);
});