define("@additive-apps/utils/validators/html-length", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-validators"], function (_exports, _validationErrors, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateHTMLLength = validateHTMLLength;
  /**
   *  @module Validators
   */

  /**
   * @function validateHTMLLength
   *
   * @param {Any} value
   * @param {Object} options
   *
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Number} options.is The exact length the value can be
   * @param {Number} options.min The minimum length the value can be
   * @param {Number} options.max The maximum length the value can be
   *
   * @return {Boolean|ValidationErr}
   */
  function validateHTMLLength() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (key, value) => {
      const textContent = _HTML2Text(value);
      let result = (0, _emberValidators.validate)('length', textContent, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }

  /**
   * Strips all valid html-tags from a string
   *
   * @function _HTML2Text
   *
   * @param {String} htmlContent
   */
  const _HTML2Text = htmlContent => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = htmlContent;
    return tmp.textContent || tmp.innerText || '';
  };
});