define("ember-svg-jar/inlined/placeholder-org", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.5 19v-4.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5V19h-5a.5.5 0 01-.5-.5V9.257a.5.5 0 01.21-.407L12 4l6.79 4.85a.5.5 0 01.21.407V18.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});