define("ember-svg-jar/inlined/big-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.591 10.227c.14-.42.21-.63.325-.672a.25.25 0 01.169 0c.114.041.184.252.325.672.448 1.346.673 2.02 1.088 2.556.208.27.45.512.72.72.536.415 1.21.64 2.555 1.088.42.14.631.21.672.325a.25.25 0 010 .168c-.04.115-.251.185-.672.325-1.346.449-2.019.673-2.556 1.088-.27.208-.511.45-.72.72-.414.537-.639 1.21-1.087 2.556-.14.42-.21.63-.325.672a.25.25 0 01-.169 0c-.114-.041-.184-.252-.325-.672-.448-1.346-.673-2.02-1.088-2.556a4 4 0 00-.72-.72c-.536-.415-1.21-.64-2.555-1.088-.421-.14-.631-.21-.672-.325a.25.25 0 010-.168c.04-.115.251-.185.672-.325 1.346-.449 2.019-.673 2.556-1.088.27-.208.511-.45.72-.72.414-.537.639-1.21 1.087-2.556z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});