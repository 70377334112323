define("ember-svg-jar/inlined/pointer-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.435 14.21a.5.5 0 01-.407.79H7.972a.5.5 0 01-.407-.79l4.028-5.64a.5.5 0 01.814 0l4.028 5.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});