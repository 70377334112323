define("ember-svg-jar/inlined/vpos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M45.225 24c0 5.272-1.942 10.266-5.271 13.873-1.665-1.665-7.075-6.798-11.515-11.098l1.804-1.804c.555-.694.693-1.248.693-1.665 0-.277 0-2.358.14-3.607.138-1.526 1.248-2.635 2.08-2.497.416.139.694.694.139 1.388-.278.416-.555 1.11-.694 1.526-.139.97.694 1.664 1.249 1.664.416 0 .832-.277.97-.416.14-.139 2.637-2.22 3.746-3.19 1.11-.972 2.081-.556 2.081-.556s-.97-3.19-2.08-4.716c-.417-.555-1.25-1.388-2.636-1.804-1.249-.416-2.914 0-5.133 3.191-1.665 2.497-3.885 4.856-5.133 6.243L24 22.197l-1.665-1.665c-1.387-1.387-3.468-3.746-5.271-6.243-2.22-3.19-3.885-3.468-5.133-3.19-1.526.554-2.22 1.387-2.775 1.941-1.11 1.526-1.942 4.717-1.942 4.717s.971-.277 2.08.555c1.11.971 3.608 3.052 3.746 3.19.14.14.555.417.972.417.554 0 1.387-.555 1.248-1.665a5.128 5.128 0 00-.694-1.526c-.554-.832-.277-1.387.14-1.387.832-.139 1.941.971 2.08 2.497.139 1.387.139 3.33.139 3.607 0 .416 0 .971.693 1.665l1.804 1.803c-4.3 4.162-9.572 9.295-11.376 10.96C4.856 34.127 2.913 29.272 2.913 24 2.636 12.208 12.208 2.775 23.861 2.775c11.792 0 21.364 9.433 21.364 21.225zM13.18 42.312l10.544-10.82 10.682 10.82c-3.052 1.804-6.798 2.775-10.544 2.775-3.884.138-7.491-.833-10.682-2.775zM47.861 24c0-13.18-10.682-24-24-24S0 10.82 0 24s10.682 24 23.861 24c13.18 0 24-10.82 24-24z\" fill=\"#007a2d\"/>",
    "attrs": {
      "height": "48",
      "viewBox": "0 0 48 48",
      "width": "48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});