define("@additive-apps/ui/utils/editor-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setCurrentSelectionByNode = _exports.restoreSelection = _exports.queryValue = _exports.queryState = _exports.getCurrentSelection = _exports.getActiveBlockTag = _exports.getActionSelector = _exports.exec = _exports.cleanValue = _exports.cleanHtml = void 0;
  /**
   *  Takes a value and/or command and returns the selector for the action button
   *
   * @param {String} command exec command string
   * @param {String} value exec value wrapped inside <>
   * @function getActionSelector
   */
  const getActionSelector = (command, value) => {
    const valueString = value ? value.replace(/<|>/g, '') : null;
    if (value && command === 'formatBlock') {
      return `[value=${valueString}]`;
    }
    return `[command=${command}]`;
  };
  /**
   * gets the current selection inside the document
   * @function getCurrentSelection
   */
  _exports.getActionSelector = getActionSelector;
  const getCurrentSelection = () => {
    if (document && document.getSelection) {
      const selection = document.getSelection();
      if (selection.rangeCount > 0) {
        return selection.getRangeAt(0);
      }
    }
    return null;
  };

  /**
   * sets the current selection to a given HTML node
   */
  _exports.getCurrentSelection = getCurrentSelection;
  const setCurrentSelectionByNode = node => {
    if (node && document && document.getSelection && document.createRange) {
      const range = document.createRange();
      range.selectNode(node);
      const selection = document.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      return range;
    }
    return null;
  };

  /**
   * restores a given selection
   *
   * @param {String} selection
   * @type SelectionRange
   * @function restoreSelection
   */
  _exports.setCurrentSelectionByNode = setCurrentSelectionByNode;
  const restoreSelection = selection => {
    if (selection && document && document.getSelection) {
      const sel = document.getSelection();
      sel.removeAllRanges();
      sel.addRange(selection);
    }
  };
  _exports.restoreSelection = restoreSelection;
  const exec = _exports.exec = function exec(command) {
    let value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return document.execCommand(command, false, value);
  };
  const queryState = command => document.queryCommandState(command);
  _exports.queryState = queryState;
  const queryValue = command => document.queryCommandValue(command);
  _exports.queryValue = queryValue;
  const getActiveBlockTag = () => `<${queryValue('formatBlock')}>`;

  /**
   * removes values that lead to an unusable editor state
   *
   * @function cleanValue
   * @param {String} value
   */
  _exports.getActiveBlockTag = getActiveBlockTag;
  const cleanValue = value => {
    let val = value || '';
    const INVALID_VALUES = ['<br>', '<p></p>', '<p><br></p>'];
    val = val.replace(/(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g, '').replace(/<[/]?(html|head|body|code|meta|div|b style="font-weight:normal;")[^>]*>/gm, '').trim();
    if (INVALID_VALUES.indexOf(val) >= 0) {
      return '';
    }
    return val;
  };

  /**
   * takes an html string and removes all unsupported attributes
   *
   * @function cleanHtml
   * @param {String} html
   * @return {String} the clean html string
   */
  _exports.cleanValue = cleanValue;
  const cleanHtml = html => {
    // remove html comments
    html = html.replace(/(.*<!--StartFragment-->|<!--.*-->)/g, '').trim();

    // remove some html tags
    html = html.replace(/<[/]?(html|head|body|script|code|meta|div|b style="font-weight:normal;")[^>]*>/gm, '');
    // replace windows newline with break-tag
    html = html.replace(/(\r\n)/g, ' ');

    // replace remaining newline
    html = html.replace(/(\n)/g, '<br>');
    // remove multiple breaks on start
    while (html.startsWith('<br /><br />')) {
      html = html.substring(6);
    }
    const parser = new DOMParser();
    const body = parser.parseFromString(html, 'text/html').body;
    cleanHtmlElement(body);
    return body.innerHTML;
  };
  _exports.cleanHtml = cleanHtml;
  const KEEP_ATTRIBUTES = ['href', 'target'];

  /**
   * cleans a DOM node and its children recursively by removing all
   * unsupported attributes
   *
   * @function cleanHtmlElement
   * @param {Element} element
   */
  const cleanHtmlElement = element => {
    if (element.tagName) {
      element.attributes && [...element.attributes].forEach(attr => KEEP_ATTRIBUTES.indexOf(attr.name) < 0 && element.removeAttribute(attr.name));
      return element.childNodes.forEach(el => cleanHtmlElement(el));
    }
  };
});