define("ember-svg-jar/inlined/deactivate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 12a3 3 0 11-6 0 3 3 0 016 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 12a6 6 0 016-6h6a6 6 0 010 12H9a6 6 0 01-6-6zm6-4.5h6a4.5 4.5 0 110 9H9a4.5 4.5 0 110-9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});