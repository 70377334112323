define("@additive-apps/ui/templates/components/ui-table", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "k/775F7C",
    "block": "[[[10,\"table\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"head\",\"body\"],[[52,[51,[30,0,[\"isLoading\"]]],[50,\"ui-table/head\",0,null,[[\"columns\",\"rows\",\"isDisabled\",\"initialSortKey\",\"initialSortDirection\",\"onSort\"],[[30,0,[\"columns\"]],[30,0,[\"_formattedRows\"]],[30,0,[\"_hideBody\"]],[30,0,[\"initialSortKey\"]],[30,0,[\"initialSortDirection\"]],[28,[37,4],[[30,0],\"onSort\"],null]]]]],[52,[51,[30,0,[\"_hideBody\"]]],[50,\"ui-table/body\",0,null,[[\"rows\",\"columns\",\"hasSelectedItems\",\"showMoreEnabled\",\"maxRowsShown\",\"hasSummary\"],[[52,[30,0,[\"_isSorted\"]],[30,0,[\"_sortedRows\"]],[30,0,[\"_formattedRows\"]]],[30,0,[\"columns\"]],[30,0,[\"hasSelectedItems\"]],[30,0,[\"showMoreEnabled\"]],[30,0,[\"maxRowsShown\"]],[30,0,[\"hasSummary\"]]]]]]]]],[30,0,[\"columns\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"isLoading\"]],[[[1,\"  \"],[8,[39,6],null,[[\"@itemsPerRow\",\"@items\",\"@cardHeight\",\"@gap\",\"@classNames\"],[1,[30,0,[\"loadingCount\"]],40,4,\"pt1\"]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isError\"]],[[[1,\"  \"],[10,0],[14,0,\"flex flex-column items-center justify-center pv4 w-100\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-sm secondary pb2\"],[12],[1,\"\\n      \"],[1,[28,[35,7],[\"uiTable.error\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,8],null,[[\"@label\",\"@onClick\"],[[28,[37,7],[\"uiTable.retry\"],null],[28,[37,4],[[30,0],[30,0,[\"onReload\"]]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isEmpty\"]],[[[1,\"  \"],[10,0],[14,0,\"flex flex-column items-center justify-center pa4 w-100\"],[12],[1,\"\\n    \"],[18,1,[[28,[37,1],null,[[\"empty\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[\"&default\"],false,[\"yield\",\"hash\",\"unless\",\"component\",\"action\",\"if\",\"ui-skeleton\",\"t\",\"ui-button\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-table.hbs",
    "isStrictMode": false
  });
});