define("@additive-apps/utils/utils/currency-util", ["exports", "@additive-apps/utils/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCurrencySymbol = _exports.default = void 0;
  /**
   * Returns the symbol for the given currency code
   *
   * @function getCurrencySymbol
   * @param {String} currencyCode the ISO-formatted currency code
   * @return {String}
   */
  const getCurrencySymbol = currencyCode => {
    return _constants.CURRENCY_SYMBOLS[currencyCode];
  };
  _exports.getCurrencySymbol = getCurrencySymbol;
  var _default = _exports.default = getCurrencySymbol;
});