define("@additive-apps/ui/utils/parse-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseUrl = void 0;
  /**
   * Parses a given URL and returns its parts.
   *``` js
    {
      protocol, // Protocol like `https`
      host, // Host like `additive.eu` (includes port if other than 80|443)
      hostname, // Hosts name same as host, but without port
      port, // Port
      pathname, // Url Path like `/my/url.html`
      search, // Query string (unparsed) like `?page=1`
      hash // Anchor
    }
    ```
   * @param {String} url
   * @function parseUrl
   * @return {Object} Object with url parts
   */
  const parseUrl = url => {
    const aTag = document.createElement('a');
    aTag.href = url;
    return {
      protocol: aTag.protocol,
      host: aTag.host,
      hostname: aTag.hostname,
      port: aTag.port,
      pathname: aTag.pathname,
      search: aTag.search,
      hash: aTag.hash
    };
  };
  _exports.parseUrl = parseUrl;
});