define("ember-svg-jar/inlined/segments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 6.155l3.25-1.877a.5.5 0 01.5 0l3.714 2.145a.5.5 0 01.25.433v4.288a.5.5 0 01-.25.434l-3.25 1.876v3.69a.5.5 0 01-.25.434l-3.714 2.144a.5.5 0 01-.5 0l-3.714-2.145a.5.5 0 01-.25-.433v-3.69l-3.25-1.877a.5.5 0 01-.25-.433V6.856a.5.5 0 01.25-.433L8.25 4.278a.5.5 0 01.5 0L12 6.155zm-3.5-.289L5.786 7.433v3.134L8.5 12.134l2.714-1.567V7.433L8.5 5.866zm.786 7.567v3.134L12 18.134l2.714-1.567v-3.134L12 11.866l-2.714 1.567zm3.5-6v3.134l2.714 1.567 2.714-1.567V7.433L15.5 5.866l-2.714 1.567z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});