define("@additive-apps/ui/templates/components/ui-selectable/content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "K36wK+nj",
    "block": "[[[41,[30,0,[\"isOpen\"]],[[[40,[[[1,\"    \"],[10,0],[15,1,[28,[37,2],[\"options-container-\",[30,0,[\"selectElementId\"]]],null]],[15,0,[29,[\"options-container fixed bg-white shadow-2 br1\\n\\n      \",[30,0,[\"containerClass\"]],\"\\n\\n       \",[52,[30,0,[\"isEntering\"]],\"options-container--entering\",\"\"]]]],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"isSearchable\"]],[[[1,\"        \"],[10,0],[14,0,\"ui-selectable-search-wrapper w-100 pt2 pl2 pb1 pr4\"],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@class\",\"@placeholder\",\"@value\",\"@onSearch\"],[\"ui-selectable-search-bar br1 ph1 pv05 font-md h-auto bg-near-white ba b--secondary-10\",[28,[37,4],[\"select.searchPlaceholder\"],null],[30,0,[\"searchValue\"]],[30,0,[\"onSearch\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[30,1,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"searchValue\"]],[[[1,\"                \"],[8,[39,5],null,[[\"@icon\",\"@isSecondary\",\"@onClick\"],[\"close\",true,[30,0,[\"onSearchClear\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"            \"]],[]]]]],[1,\"\\n          \"]],[1]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"options-container__scroller relative\"],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"destinationEl\"]],null]],[]],null]],[\"searchbar\",\"&default\"],false,[\"if\",\"in-element\",\"concat\",\"ui-searchbar\",\"t\",\"ui-icon-button\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-selectable/content.hbs",
    "isStrictMode": false
  });
});