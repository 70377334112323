define("@additive-apps/utils/services/ui-locale", ["exports", "@ember/service", "@ember/object/computed", "date-fns/locale", "@additive-apps/utils/utils/locale-util"], function (_exports, _service, _computed, _locale, _localeUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2; // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DATE_FNS_LOCALES = {
    de: _locale.de,
    en: _locale.enUS
  };
  const DATE_FORMATS = {
    de: 'dd.MM.yyyy',
    en: 'yyyy-MM-dd'
  };

  /**
   * Handles the currently active locale by fetching the translations
   * asynchronously.
   *
   * @class UiLocaleService
   */
  let UiLocaleService = _exports.default = (_dec = (0, _computed.alias)('intl.primaryLocale'), _class = class UiLocaleService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      /**
       * the currently active locale
       *
       * @property locale
       * @type {String}
       * @default 'de'
       */
      _initializerDefineProperty(this, "locale", _descriptor2, this);
      this.intl.setLocale('de');
    }
    /**
     * the currently active date-fns-locale
     *
     * @computed dateFnsLocale
     * @type {Object}
     */
    get dateFnsLocale() {
      return DATE_FNS_LOCALES[this.locale];
    }

    /**
     * the default date format of the current locale
     *
     * @computed defaultDateFormat
     * @type {String}
     */
    get defaultDateFormat() {
      return DATE_FORMATS[this.locale];
    }

    /**
     * sets up the locale service with either the browser locale or the
     * locale that is stored in the local storage if available
     *
     * @function setup
     */
    setup() {
      const locale = (0, _localeUtil.getLocale)();
      return this.setLocale(locale);
    }

    /**
     * Sets the currently active locale and fetches the translation files if needed.
     *
     * To enable asynchronous translation loading, the `publicOnly` property in the
     * `config/ember-intl.js` file must be set to true. If this property is enabled, the translations
     *  won't be bundled into the application code and will be stored in the dist folder instead.
     *
     * @function setLocale
     * @param {String} locale
     */
    async setLocale(locale) {
      // if the translation files have not been loaded yet they are loaded asynchronously
      if (this.intl.locales.indexOf(locale) < 0) {
        const translation = await fetch(`/translations/${locale}.json`);
        const json = await translation.json();
        this.intl.addTranslations(locale, json);
      }
      this.intl.setLocale(locale);
      window && window.localStorage.setItem('locale', locale);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});