define("ember-intl/utils/links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GUIDE_URL = 'https://ember-intl.github.io/ember-intl/docs/guide/';
  var _default = _exports.default = {
    unsetLocale: `${GUIDE_URL}ember-service-api#locale`,
    asyncTranslations: `${GUIDE_URL}asynchronously-loading-translations`,
    polyfill: `${GUIDE_URL}intljs-polyfill`
  };
});