define("ember-animated/components/animated-value", ["exports", "@ember/object", "@ember/component", "@ember/array", "ember-animated/templates/components/animated-value"], function (_exports, _object, _component, _array, _animatedValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _AnimatedValueComponent; // @ts-ignore: templates don't have types
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
    A component that animates when a single value changes.
    Animated-value uses the same arguments as animated-each.
    ```hbs
      <AnimatedContainer>
        {{#animated-value counter rules=rules duration=100 as |v|}}
            <span class="numbers">{{v}}</span>
        {{/animated-value}}
      </AnimatedContainer>
  
      <button {{action "increment"}}>+</button>
      <button {{action "decrement"}}>-</button>
    ```
    ```js
    import Component from '@ember/component';
    import { toLeft, toRight } from 'ember-animated/transitions/move-over';
  
    export default Component.extend({
      rules({ oldItems, newItems }) {
        if (oldItems[0] < newItems[0]) {
          return toLeft;
        } else {
          return toRight;
        }
      },
  
      counter: 10,
  
      actions: {
        increment() {
          this.set('counter', this.get('counter') + 1);
        },
        decrement() {
          this.set('counter', this.get('counter') - 1);
        }
      }
    });
    ```
    @class animated-value
    @public
  */
  let AnimatedValueComponent = _exports.default = (_dec = (0, _object.computed)('value'), _class = (_AnimatedValueComponent = class AnimatedValueComponent extends _component.default {
    constructor() {
      super(...arguments);
      /**
       * The data you are trying to render.
        @argument items
        @type unknown
      */
      _defineProperty(this, "value", void 0);
      _defineProperty(this, "tagName", '');
      _defineProperty(this, "layout", _animatedValue.default);
    }
    get items() {
      return (0, _array.A)([this.value]);
    }
  }, _defineProperty(_AnimatedValueComponent, "positionalParams", ['value']), _AnimatedValueComponent), _applyDecoratedDescriptor(_class.prototype, "items", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _class);
});