define("ember-svg-jar/inlined/empty-content-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path d=\"M233.25 203V53a.25.25 0 00-.25-.25h-61.608a.25.25 0 00-.17.067L130.83 90.253a.25.25 0 00-.08.184V203c0 .138.112.25.25.25h102a.25.25 0 00.25-.25z\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"/><path d=\"M171.25 90.25V53.296a.25.25 0 00-.42-.183l-39.457 36.704a.25.25 0 00.17.433z\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"/><g fill=\"#8f9ea4\" fill-opacity=\".3\" transform=\"translate(130 52)\"><rect height=\"1.5\" rx=\".5\" width=\"64\" x=\"16\" y=\"55\"/><rect height=\"1.5\" rx=\".5\" width=\"56\" x=\"16\" y=\"65\"/><rect height=\"1.5\" rx=\".5\" width=\"40\" x=\"16\" y=\"75\"/></g><g transform=\"translate(207 142)\"><circle cx=\"24\" cy=\"24\" fill=\"#f2f2f2\" r=\"23.25\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"/><path d=\"M24.75 24.75v5.75a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-5.75H17.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h5.75V17.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v5.75h5.75a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5z\" fill=\"#8f9ea4\"/></g></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});