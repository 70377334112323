define("ember-svg-jar/inlined/webcam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 3.5a7 7 0 01.75 13.96v1.04h2.75a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h2.75v-1.04A7.001 7.001 0 0112 3.5zM12 5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm0 3a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0 1.5a1 1 0 100 2 1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});