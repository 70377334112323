define("ember-svg-jar/inlined/mail-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M184 67.465L112.907 96.1a.25.25 0 00-.157.232v100.325c0 .138.112.25.25.25h142a.25.25 0 00.25-.25V96.332a.25.25 0 00-.157-.232z\" stroke=\"#00172F\" stroke-width=\"1.5\"/><g fill-rule=\"nonzero\"><path d=\"M233.25 185V59a.25.25 0 00-.25-.25H131a.25.25 0 00-.25.25v126c0 .138.112.25.25.25h102a.25.25 0 00.25-.25z\" fill=\"#fff\" stroke=\"#00172F\" stroke-width=\"1.5\"/><g fill=\"#c6ab8d\" transform=\"translate(130 58)\"><rect height=\"1.5\" rx=\".5\" width=\"64\" x=\"16\" y=\"23\"/><rect height=\"1.5\" rx=\".5\" width=\"56\" x=\"16\" y=\"33\"/><rect height=\"1.5\" rx=\".5\" width=\"40\" x=\"16\" y=\"43\"/></g></g><g stroke-width=\"1.5\"><path d=\"M113.173 96.163a.25.25 0 00-.423.18V197c0 .138.112.25.25.25h142a.25.25 0 00.25-.25V96.343a.25.25 0 00-.423-.18L184 164.039z\" fill=\"#00172F\" stroke=\"#fff\"/><path d=\"M113.173 96.163a.25.25 0 00-.423.18V197c0 .138.112.25.25.25h142a.25.25 0 00.25-.25V96.343a.25.25 0 00-.423-.18L184 164.039z\" fill=\"#fff\" stroke=\"#00172F\"/><path d=\"M184.15 144.052a.25.25 0 00-.3 0L113.839 196.8a.25.25 0 00.15.45h140.022a.25.25 0 00.15-.45z\" fill=\"#fff\" stroke=\"#00172F\"/><circle cx=\"242\" cy=\"100\" fill=\"#fff\" r=\"23.25\" stroke=\"#c6ab8d\"/></g><path d=\"M242.278 108V95.861h-.156l-3.584 2.598v-1.855l3.75-2.696h1.748V108z\" fill=\"#c6ab8d\"/></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});