define("ember-svg-jar/inlined/advertising", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.845 16.934a.5.5 0 01-.486.62H9.31a.5.5 0 01-.447-.275c-.525-1.454-1.01-2.397-1.453-2.83-.665-.65-1.385-.808-1.944-.856A.505.505 0 015 13.094v-3.079c0-.261.205-.476.465-.498 5.684-.489 9.944-1.635 12.779-3.44A.478.478 0 0118.503 6a.5.5 0 01.497.502v10.105a.5.5 0 01-.497.502.485.485 0 01-.259-.076c-1.991-1.268-4.685-2.21-8.082-2.829l.683 2.73zM17.5 8.209c-2.767 1.361-6.392 2.255-11 2.718v1.256c4.608.462 8.233 1.357 11 2.717V8.209z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});