define("ember-svg-jar/inlined/bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 10.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2A.5.5 0 015 11v-.5zM9.5 10a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-2zM13 10.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-.5z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.95 16h2.55a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5h-17a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h2.55a2.5 2.5 0 004.9 0h2.1a2.5 2.5 0 004.9 0zM4.5 8.5v6h1.708a2.5 2.5 0 014.584 0h2.416a2.5 2.5 0 014.584 0H19.5v-3h-2a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h2V8.5h-15zm11 8a1 1 0 100-2 1 1 0 000 2zm-6-1a1 1 0 11-2 0 1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});