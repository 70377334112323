define("@additive-apps/ui/templates/components/ui-selectable", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "VNu8kcIH",
    "block": "[[[18,1,[[28,[37,1],null,[[\"trigger\",\"content\",\"isOpen\",\"toggleDropdown\",\"isAllSelected\",\"hasSelectedOptions\",\"selectedItem\",\"selected\",\"checked\",\"selectedText\",\"selectAll\",\"deselectAll\",\"toggleAll\",\"selectOption\"],[[50,\"ui-selectable/trigger\",0,null,[[\"onClick\"],[[28,[37,3],[[30,0],\"toggle\"],null]]]],[50,\"ui-selectable/content\",0,null,[[\"isOpen\",\"destinationEl\",\"selectElementId\",\"style\",\"isEntering\",\"isSearchable\",\"searchValue\",\"onSearch\",\"onSearchClear\"],[[30,0,[\"isOpen\"]],[30,0,[\"destinationEl\"]],[30,0,[\"elementId\"]],[30,0,[\"style\"]],[30,0,[\"isEntering\"]],[30,0,[\"isSearchable\"]],[30,0,[\"searchValue\"]],[28,[37,3],[[30,0],\"_onSearch\"],null],[28,[37,3],[[30,0],\"_onSearchClear\"],null]]]],[30,0,[\"isOpen\"]],[28,[37,3],[[30,0],\"toggle\"],null],[30,0,[\"isAllSelected\"]],[30,0,[\"hasSelectedOptions\"]],[30,0,[\"_value\"]],[30,0,[\"selected\"]],[30,0,[\"checked\"]],[30,0,[\"selectedText\"]],[30,0,[\"selectAll\"]],[30,0,[\"deselectAll\"]],[28,[37,3],[[30,0],\"toggleAll\"],null],[28,[37,3],[[30,0],\"selectOption\"],null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-selectable.hbs",
    "isStrictMode": false
  });
});