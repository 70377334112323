define("ember-svg-jar/inlined/marketing-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#00172f\" d=\"M12.82 0h54.36c4.458 0 6.075.464 7.704 1.336s2.909 2.15 3.78 3.78C79.536 6.746 80 8.362 80 12.819v54.362c0 4.457-.464 6.074-1.336 7.703a9.086 9.086 0 01-3.78 3.78c-1.63.872-3.246 1.336-7.703 1.336H12.819c-4.457 0-6.074-.464-7.703-1.336-1.63-.871-2.909-2.15-3.78-3.78S0 71.638 0 67.181V12.819c0-4.457.464-6.074 1.336-7.703s2.15-2.909 3.78-3.78S8.362 0 12.819 0z\"/><path fill=\"#fff\" d=\"M40.385 20c-.25.004-.245 2.843-1.404 4-1.16 1.157-7.606 8.421-7.606 15.839 0 1.45.14 2.699.406 3.855-2.6 1.454-4.068 2.643-3.734 5.76.41 3.839 3.11 12.907 3.55 9.97.462-3.107 1.23-6.561 3.758-8.288.515.811 1.074 1.679 1.678 2.634h6.696c.604-.955 1.163-1.823 1.678-2.634 2.527 1.727 3.296 5.18 3.759 8.289.438 2.936 3.138-6.132 3.549-9.972.334-3.116-1.135-4.305-3.734-5.759.265-1.156.406-2.405.406-3.855 0-7.418-6.447-14.682-7.606-15.84-1.15-1.156-1.147-3.991-1.396-3.999zm3.805 25.238a3.333 3.333 0 11-6.667 0 3.333 3.333 0 016.667 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80"
    }
  };
});