define("@additive-apps/ui/utils/filter-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dynamicFiltersToQueryParams = _exports.buildQueryParams = _exports.DYNAMIC_FILTER_MULTIPLE_TYPES = void 0;
  const DYNAMIC_FILTER_MULTIPLE_TYPES = _exports.DYNAMIC_FILTER_MULTIPLE_TYPES = ['multi'];
  const buildQueryParams = (filterOptions, activeFilter, key, value, isSet) => {
    // return the same filter if passed key is not and accepted filter
    if (filterOptions.indexOf(key) === -1) return;

    // determine if the filter has to be applied or removed
    // add filter
    const newActiveFilter = Object.assign({}, activeFilter);
    if (isSet) {
      // TODO: Handle multi-value filter (needs API)
      // treat as immutable
      newActiveFilter[key] = value && value.value || value || null;
    } else {
      // unset qp
      newActiveFilter[key] = null;
    }
    return newActiveFilter;
  };

  /**
   * Transforms api dynamic filters response into ui filter logic expected queryParams
   *
   * @function dynamicFiltersToQueryParams
   * @param {Array} filters
   */
  _exports.buildQueryParams = buildQueryParams;
  const dynamicFiltersToQueryParams = filters => {
    if (!Array.isArray(filters) || filters.length === 0) {
      return {};
    }
    let queryParams = {};
    filters.forEach(filter => {
      // add nested filters if query or grouplabel
      if ((filter.type === 'query' || filter.type === 'group_label') && filter.components) {
        Object.values(filter.components).forEach(component => {
          const isMulti = DYNAMIC_FILTER_MULTIPLE_TYPES.indexOf(component.type) >= 0;
          queryParams[component.key] = {
            defaultValue: isMulti ? [] : null,
            multiple: isMulti
          };
        });
      } else {
        const isMulti = DYNAMIC_FILTER_MULTIPLE_TYPES.indexOf(filter.type) >= 0;
        queryParams[filter.key] = {
          defaultValue: isMulti ? [] : null,
          multiple: isMulti
        };
      }
    });
    return queryParams;
  };
  _exports.dynamicFiltersToQueryParams = dynamicFiltersToQueryParams;
});