define("ember-svg-jar/inlined/portrait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 4h11a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5zm1 1.5v13h9v-13h-9zm4.498 9.174l1.116-1.116a.5.5 0 01.707 0l.354.354a.5.5 0 010 .707l-1.823 1.823a.5.5 0 01-.707 0L9.82 14.619a.5.5 0 010-.707l.354-.354a.5.5 0 01.707 0l1.116 1.116zm-1.823-4.232l-.354-.354a.5.5 0 010-.707l1.824-1.823a.5.5 0 01.707 0l1.823 1.823a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0l-1.116-1.116-1.116 1.116a.5.5 0 01-.707 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});