define("ember-svg-jar/inlined/empty-search-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(114 52)\"><path d=\"M103.25 151V1a.25.25 0 00-.25-.25H41.392a.25.25 0 00-.17.067L.83 38.253a.25.25 0 00-.08.184V151c0 .138.112.25.25.25h102a.25.25 0 00.25-.25z\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"/><path d=\"M17 55.75h64M17 65.75h56M17 75.75h40\" stroke=\"#8f9ea4\" stroke-linecap=\"round\" stroke-opacity=\".4\" stroke-width=\"1.5\"/><path d=\"M131.562 117.562l28.285 28.285\" stroke=\"#8f9ea4\" stroke-linecap=\"round\" stroke-width=\"1.5\"/><circle cx=\"98\" cy=\"84\" fill=\"#f2f2f2\" fill-rule=\"nonzero\" r=\"48\"/><g stroke=\"#8f9ea4\" stroke-width=\"1.5\"><path d=\"M41.25 38.25V1.296a.25.25 0 00-.42-.183L1.373 37.817a.25.25 0 00.17.433z\"/><path d=\"M53 71.233c16.03-4.836 32.91-3.58 50.637 3.767M58.31 59.885c13.301-3.394 26.833-2.574 40.595 2.459M65.483 49.838c7.733-1.89 16.546-2.088 28.44 1.058\" stroke-linecap=\"round\" stroke-opacity=\".4\"/><path d=\"M112.742 39c4.468 14.94 6.702 29.878 6.702 44.817s-2.234 29.879-6.702 44.818\" stroke-opacity=\".4\"/><circle cx=\"98\" cy=\"84\" r=\"47.25\"/></g></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});