define("@additive-apps/ui/services/ui-dialog", ["exports", "@ember/service", "@ember/application", "@ember/object", "@additive-apps/ui/utils/app-utils"], function (_exports, _service, _application, _object, _appUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * Service that opens and closes dialogs.
   *
   * To open a dialog call one of the show functions:
   *
   * ```javascript
   * this.uiDialog.showAlert('title', 'foo');
   * ```
   *
   * To close dialogs call close():
   *
   * ```javascript
   * this.uiDialog.close();
   * ```
   *
   * Dialog is shown in dialog service container
   *
   * ```hbs
   * {{! templates/application.hbs }}
   * {{ui-dialog-service}}
   * ```
   *
   * @extends Ember.Service
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    /**
     * Defines the title of the dialog
     *
     * @property title
     * @type {String}
     * @default null
     */
    title: null,
    /**
     * Defines the content of the dialog
     *
     * @property content
     * @type {String}
     * @default null
     */
    content: null,
    /**
     * Defines the text of the confirm button
     *
     * @property confirmText
     * @type {String}
     * @default null
     */
    confirmText: null,
    /**
     * Whether the dialog is displayed
     *
     * @property isOpen
     * @type {Boolean}
     * @default false
     */
    isOpen: false,
    /**
     * Whether it is a confirm dialog
     *
     * @property isConfirm
     * @type {Boolean}
     * @default false
     */
    isConfirm: false,
    /**
     * Whether the passed onConfirm action is a promise
     *
     * @property isPromise
     * @type {Boolean}
     * @default false
     */
    isPromise: false,
    /**
     * Dependencies that led to an error
     *
     * @property _conflictDependencies
     * @type {Object}
     * @default null
     * @private
     */
    _conflictDependencies: null,
    /**
     * the headers of the conflict dependencies table
     *
     * @property _conflictDependenciesTableHeaders
     * @type {Array}
     * @default null
     * @private
     */
    _conflictDependenciesTableHeaders: null,
    /**
     * Whether confirm-promise is currently running
     *
     * @property _isConfirming
     * @type {Boolean}
     * @default false
     * @private
     */
    _isConfirming: false,
    /**
     * the size of the dialog
     *
     * @property _size
     * @type {String}
     * @default 'sm'
     * @private
     */
    _size: 'sm',
    /**
     * Callback for confirm action. Executed when confirm dialog is confirmed.
     *
     * @function onConfirm
     */
    onConfirm() {},
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_conflictDependenciesTableHeaders', [{
        name: this.intl.t('dialogService.conflictError.dependencies.headers.app'),
        key: 'app'
      }, {
        name: this.intl.t('dialogService.conflictError.dependencies.headers.contentType'),
        key: 'contentType'
      }, {
        name: this.intl.t('dialogService.conflictError.dependencies.headers.content'),
        key: 'content'
      }]);
    },
    /**
     * Open an alert dialog with custom title and content.
     * This dialog does not have a confirm button.
     *
     * @param {String} title
     * @param {String} content
     * @function showAlert
     */
    showAlert(title, content) {
      (0, _object.set)(this, 'title', title);
      (0, _object.set)(this, 'content', content);
      (0, _object.set)(this, 'isOpen', true);
    },
    /**
     * Open an error dialog with standard error-message
     *
     * @param {String} title
     * @param {String} content
     * @function showError
     */
    showError() {
      let title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let content = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      const intl = this.intl;
      const errorTitle = typeof title === 'string' ? title : intl.t('dialogService.error.title');
      const errorContent = typeof content === 'string' ? content : intl.t('dialogService.error.message');
      this.setProperties({
        title: errorTitle,
        content: errorContent,
        isOpen: true,
        isError: true
      });
    },
    /**
     * Open an error dialog with conflicts
     *
     * @param {String} title
     * @param {String} content
     * @function showConflictError
     */
    showConflictError() {
      let title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let content = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let dependencies = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      const {
        intl
      } = this;
      const errorTitle = typeof title === 'string' ? title : intl.t('dialogService.error.conflictTitle');
      const errorContent = typeof content === 'string' ? content : intl.t('dialogService.error.conflictMessage');
      let conflictDependencies = [];
      if (dependencies) {
        const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
        const conflictApps = Object.keys(dependencies);
        conflictApps.forEach(conflictApp => {
          const conflictContentTypes = Object.keys(dependencies[conflictApp]);
          conflictContentTypes.forEach((conflictContentType, index) => {
            const dependency = {
              app: !index ? intl.t(`appNames.${conflictApp}.name`) : '',
              contentType: intl.exists(`dialogService.conflictError.dependencies.contentTypes.${conflictContentType}`) ? intl.t(`dialogService.conflictError.dependencies.contentTypes.${conflictContentType}`) : conflictContentType,
              contents: []
            };
            dependencies[conflictApp][conflictContentType].forEach(conflictDependency => {
              const url = `${(0, _appUtils.getAppUrlById)(conflictApp, this)}/${this.currentUser.currentOrganization.id}/${(0, _appUtils.getUrlByContentType)(conflictApp, conflictContentType, conflictDependency.id)}`;
              let route = {};
              if (parseInt(ENV.APP.appId) === parseInt(conflictApp)) {
                route.path = (0, _appUtils.getRouteByContentType)(parseInt(conflictApp), conflictContentType);
                route.id = conflictDependency.id;
                route.key = `${conflictContentType}.${conflictDependency.key}`;
              }
              dependency.contents.push({
                name: conflictDependency.name,
                url,
                route
              });
            });
            conflictDependencies.push(dependency);
          });
        });
      }
      this.setProperties({
        title: errorTitle,
        content: errorContent,
        isOpen: true,
        isError: true,
        _conflictDependencies: conflictDependencies,
        _size: 'md'
      });
    },
    /**
     * Open an error dialog with an error message telling the users he is missing permissions
     * to perform the desired action
     *
     * @function showError
     */
    showMissingPermissions() {
      const intl = this.intl;
      this.setProperties({
        title: intl.t('dialogService.noPermission.title'),
        content: intl.t('dialogService.noPermission.message'),
        isOpen: true
      });
    },
    /**
     * Open a confirm dialog with custom title and content and set a callback action.
     * Optional: custom confirm button text
     * Optional: onConfirmCallback
     *
     * @param {String} title
     * @param {String} content
     * @param {Function} [onConfirmCallback={}]
     * @param {String} [confirmText=OK]
     * @param {Boolean} isPromise
     * @function showConfirm
     */
    showConfirm(title, content) {
      let onConfirmCallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => {};
      let confirmText = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'OK';
      let isPromise = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
      let isError = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
      this.setProperties({
        title: title,
        content: content,
        confirmText: confirmText,
        onConfirm: onConfirmCallback,
        isPromise: isPromise,
        isError: isError,
        isConfirm: true,
        isOpen: true
      });
    },
    /**
     * Open a confirm dialog for "discard-changes" with standard title and content.
     *
     * @function showDiscardChangesConfirm
     * @param {onConfirm} [onConfirmCallback={}]
     */
    showDiscardChangesConfirm() {
      let onConfirmCallback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => {};
      const intl = this.intl;
      this.setProperties({
        title: intl.t('dialogService.discardChanges.title'),
        content: intl.t('dialogService.discardChanges.message'),
        confirmText: intl.t('dialogService.discardChanges.discardAction'),
        onConfirm: onConfirmCallback,
        isConfirm: true,
        isOpen: true
      });
    },
    /**
     * Closes the dialog.
     *
     * @function close
     */
    close() {
      this._isConfirming = false;
      this.setProperties({
        isOpen: false,
        isConfirm: false,
        _conflictDependencies: null,
        _size: 'sm'
      });
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      close() {
        this.close();
      },
      async confirm() {
        try {
          if (this.isPromise) {
            await this.onConfirm();
          } else {
            this.onConfirm();
          }
        } finally {
          this.close();
        }
      }
    }
  });
});