define("ember-animated/templates/components/animated-each", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "06+hr++s",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"renderedChildren\"]]],null]],null],\"id\",[[[1,\"  \"],[8,[39,2],null,[[\"@child\",\"@elementToChild\"],[[30,1],[30,0,[\"_elementToChild\"]]]],[[\"default\"],[[[[18,2,[[30,1,[\"value\"]],[30,1,[\"index\"]]]]],[]]]]],[1,\"\\n\"]],[1]],[[[18,3,null]],[]]]],[\"child\",\"&default\",\"&else\"],false,[\"each\",\"-track-array\",\"ea-list-element\",\"yield\"]]",
    "moduleName": "ember-animated/templates/components/animated-each.hbs",
    "isStrictMode": false
  });
});