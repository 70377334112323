define("ember-svg-jar/inlined/underline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 6a.5.5 0 01.5.5V11a2.5 2.5 0 005 0V6.5A.5.5 0 0115 6h.5a.5.5 0 01.5.5V11a4 4 0 01-8 0V6.5a.5.5 0 01.5-.5H9zM8.5 16.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V17a.5.5 0 00-.5-.5h-7z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});