define("ember-svg-jar/inlined/redo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.707 9.677a.5.5 0 000-.707l-3.824-3.824a.5.5 0 00-.707 0l-.353.354a.5.5 0 000 .707l2.366 2.366h-5.19c-3.313 0-6 2.113-6 5.427 0 3.314 2.687 5.573 6 5.573h1.117c.245 0 .445-.199.445-.445v-.698a.357.357 0 00-.357-.357H11c-2.485 0-4.5-1.588-4.5-4.073 0-2.485 2.014-3.927 4.5-3.927h5.189l-2.366 2.366a.5.5 0 000 .707l.353.354a.5.5 0 00.707 0l3.824-3.823z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});