define("ember-svg-jar/inlined/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.524 4.463a.5.5 0 01.952 0l.647 1.992h2.093a.5.5 0 01.294.904L13.816 8.59l.647 1.991a.5.5 0 01-.769.56L12 9.91l-1.694 1.23a.5.5 0 01-.77-.559l.648-1.99L8.49 7.358a.5.5 0 01.294-.904h2.093l.647-1.992zM16.524 11.463a.5.5 0 01.951 0l.648 1.992h2.093a.5.5 0 01.294.904l-1.694 1.231.647 1.991a.5.5 0 01-.769.56L17 16.91l-1.694 1.23a.5.5 0 01-.77-.559l.648-1.99-1.694-1.232a.5.5 0 01.294-.904h2.093l.647-1.992zM6.524 11.463a.5.5 0 01.952 0l.647 1.992h2.093a.5.5 0 01.294.904L8.816 15.59l.647 1.991a.5.5 0 01-.769.56L7 16.91l-1.694 1.23a.5.5 0 01-.77-.559l.648-1.99-1.694-1.232a.5.5 0 01.294-.904h2.093l.647-1.992z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});