define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.643 10.5l.727 8h5.26l.727-8zM7.548 9h8.904a.5.5 0 01.498.545l-.909 10a.5.5 0 01-.498.455H8.457a.5.5 0 01-.498-.455l-.91-10A.5.5 0 017.549 9zm2.285-3l.553-1.658A.5.5 0 0110.86 4h2.28a.5.5 0 01.474.342L14.167 6H17.5a.5.5 0 01.5.5V7a.5.5 0 01-.5.5h-11A.5.5 0 016 7v-.5a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});