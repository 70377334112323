define("ember-svg-jar/inlined/facebookCampaign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 .333C16.891.333 21.667 5.11 21.667 11S16.89 21.667 11 21.667.333 16.89.333 11 5.11.333 11 .333zm0 2a8.667 8.667 0 00-1.335 17.232L9.667 13H8.333a.667.667 0 01-.666-.667v-.666c0-.368.298-.667.666-.667h1.334V9.333A2.333 2.333 0 0112 7h1.667c.368 0 .666.298.666.667v.666a.667.667 0 01-.666.667H12a.333.333 0 00-.333.333V11H13c.368 0 .667.299.667.667v.666A.667.667 0 0113 13h-1.333l-.001 6.642A8.667 8.667 0 0011 2.332z\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});