define("ember-svg-jar/inlined/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.001 4l.316.006c.423.017.854.066 1.288.15l.27.058c3.039.705 5.443 3.229 5.993 6.312l.042.26a8.008 8.008 0 01-5.907 8.961l-.362.084a7.913 7.913 0 01-1.118.152l-.084-.002a.506.506 0 01-.439-.502v-.499l.008-.087a.51.51 0 01.471-.411l.292-.027c.29-.035.577-.088.858-.161l.242-.067A6.503 6.503 0 0018.5 12l-.004-.233A6.5 6.5 0 0012 5.5l-.229.004c-3.496.128-6.373 3.17-6.269 6.689l.016.302a6.466 6.466 0 001.56 3.753l-.148-.18 1.215-1.214a.5.5 0 01.854.353V18.5a.5.5 0 01-.5.5H5.207a.5.5 0 01-.353-.854l1.012-1.013-.08-.094a7.959 7.959 0 01-1.78-4.704L4 12a8 8 0 018.001-8zm.249 4a.5.5 0 01.5.5v3.067l2.655 1.534a.5.5 0 01.183.682l-.25.434a.5.5 0 01-.683.182L11.5 12.577a.5.5 0 01-.25-.433V8.5a.5.5 0 01.5-.5h.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});