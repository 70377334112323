define("ember-svg-jar/inlined/six_payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M451.133 1l-6.338-.003c-39.574-.035-69.541-.06-96.373 12.434-26.832 12.494-46.1 35.447-71.543 65.757l-4.076 4.853-102.119 121.44c-31.452 37.4-41.069 47.716-52.967 53.257-11.899 5.54-25.983 6.262-74.85 6.262H0v66h42.867l6.338.004c39.574.034 69.541.059 96.373-12.435 26.832-12.495 46.1-35.447 71.543-65.758l4.076-4.852 102.119-121.44c31.452-37.401 41.069-47.716 52.967-53.257 11.899-5.54 25.983-6.262 74.85-6.262H494V1h-42.867zm695.997 0l-6.34-.003c-39.57-.035-69.54-.06-96.37 12.434s-46.098 35.447-71.541 65.757l-4.076 4.853L943 114.725l-25.803-30.684-4.076-4.853c-25.443-30.31-44.711-53.263-71.543-65.757C814.746.937 784.779.962 745.205.997L738.867 1H696v66h42.867c48.867 0 62.951.721 74.85 6.262 11.898 5.541 21.515 15.856 52.967 53.257L899.883 166l-33.199 39.481c-31.452 37.4-41.069 47.716-52.967 53.257-11.899 5.54-25.983 6.262-74.85 6.262H696v66h42.867l6.338.004c39.574.034 69.541.059 96.373-12.435 26.832-12.495 46.1-35.447 71.543-65.758l4.076-4.852L943 217.274l25.803 30.685 4.076 4.852c25.443 30.311 44.711 53.263 71.541 65.758 26.83 12.494 56.8 12.469 96.37 12.435l6.34-.004H1190v-66h-42.87c-48.86 0-62.95-.722-74.85-6.262-11.9-5.541-21.51-15.857-52.96-53.257L986.117 166l33.203-39.481c31.45-37.401 41.06-47.716 52.96-53.257 11.9-5.54 25.99-6.262 74.85-6.262H1190V1h-42.87zM628 1h-66v330h66V1z\" fill=\"#E42313\"/>",
    "attrs": {
      "width": "100",
      "height": "28",
      "viewBox": "0 0 1190 332",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});