define("ember-svg-jar/inlined/merge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.38 5.981a.265.265 0 01.077-.378l.293-.186.357-.228a.212.212 0 01.285.057c.083.114.21.28.381.482.343.405.86.947 1.55 1.489C7.702 8.3 9.757 9.375 12.5 9.375h2.24l-2.745-2.746a.5.5 0 010-.707l.176-.177a.5.5 0 01.707 0l3.902 3.901a.5.5 0 010 .708l-3.902 3.901a.5.5 0 01-.707 0l-.176-.177a.5.5 0 010-.707l2.746-2.746H12.5c-2.743 0-4.798 1.075-6.177 2.158a10.802 10.802 0 00-1.55 1.489 8.292 8.292 0 00-.381.482.212.212 0 01-.285.057l-.357-.228-.293-.186a.268.268 0 01-.076-.378s.246-.328.439-.556c.386-.455.963-1.06 1.731-1.663A11.436 11.436 0 018.804 10 11.435 11.435 0 015.55 8.2 12.046 12.046 0 013.82 6.537a9.535 9.535 0 01-.44-.556z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor"
    }
  };
});