define("ember-svg-jar/inlined/youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.301 5.202c.352.046 1.119.049 1.804.842.539.604.715 1.976.715 1.976s.18 1.61.18 3.22v1.51c0 1.61-.18 3.22-.18 3.22s-.175 1.373-.715 1.978c-.685.793-1.452.797-1.805.843-2.518.202-6.3.208-6.3.208s-4.68-.047-6.12-.2c-.401-.083-1.3-.058-1.985-.85-.54-.604-.715-1.977-.715-1.977s-.165-1.479-.18-3.019V11.24c0-1.61.18-3.22.18-3.22s.175-1.372.715-1.976c.685-.792 1.452-.796 1.805-.842 2.158-.173 5.243-.198 6.08-.202h.442c.837.004 3.92.029 6.079.202zM12.265 6.5h-.528a111.541 111.541 0 00-1.048.01c-.983.013-1.966.036-2.881.073-.75.03-1.42.068-1.914.105l-.085.01c-.4.045-.488.07-.638.188a1.169 1.169 0 00-.158.157c-.02.021-.084.148-.149.336-.105.307-.172.64-.193.807-.02.184-.053.518-.085.95-.054.721-.086 1.44-.086 2.103v1.512c0 .663.032 1.382.086 2.102.032.433.064.767.082.928.024.19.09.523.196.83.065.189.13.315.166.356.064.074.13.125.206.164.14.072.227.09.69.155l.17.026c.494.048 1.44.091 2.677.125a194.895 194.895 0 002.965.058l.26.003c.063 0 .185 0 .356-.002a127.904 127.904 0 003.834-.087c.751-.03 1.423-.069 1.918-.107l.084-.01c.4-.044.488-.068.638-.187.046-.037.093-.082.158-.157.02-.022.085-.15.15-.337.105-.308.172-.641.193-.808.02-.184.053-.518.085-.95.054-.721.086-1.44.086-2.103V11.24c0-.663-.032-1.382-.086-2.102a28.395 28.395 0 00-.083-.938 4.633 4.633 0 00-.196-.82 1.319 1.319 0 00-.165-.356 1.062 1.062 0 00-.142-.137c-.149-.119-.236-.143-.635-.187l-.028-.004h-.001l-.275-.021a55.212 55.212 0 00-1.695-.091 109.548 109.548 0 00-3.832-.083h-.097zM10.5 9.383a.5.5 0 01.257.071l3.528 2.117a.5.5 0 010 .858l-3.528 2.117a.5.5 0 01-.757-.43V9.884a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});