define("@additive-apps/ui/utils/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportsLocalStorage = supportsLocalStorage;
  /**
    Checks if the localStorage is supported
    @function supportsLocalStorage
    @return {Boolean} true if supported
    @public
   */
  function supportsLocalStorage() {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }
});