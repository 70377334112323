define("ember-svg-jar/inlined/feedback-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.94 12L8.824 9.884a.5.5 0 010-.707l.353-.353a.5.5 0 01.707 0L12 10.94l2.116-2.116a.5.5 0 01.708 0l.353.353a.5.5 0 010 .707L13.061 12l2.116 2.116a.5.5 0 010 .708l-.353.353a.5.5 0 01-.708 0L12 13.061l-2.116 2.116a.5.5 0 01-.707 0l-.353-.353a.5.5 0 010-.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});