define("ember-svg-jar/inlined/lead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.769 10.269a1.769 1.769 0 10-3.538 0 1.769 1.769 0 003.538 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.25 3a.5.5 0 01.5.5v1.54a7.003 7.003 0 016.21 6.21h1.54a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-1.54a7.003 7.003 0 01-6.21 6.21v1.54a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1.54a7.003 7.003 0 01-6.21-6.21H3.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h1.54a7.003 7.003 0 016.21-6.21V3.5a.5.5 0 01.5-.5h.5zm3.378 13.134A5.479 5.479 0 0112 17.5a5.48 5.48 0 01-3.628-1.366c.25-.372.577-.664.984-.887.63-.345 1.496-.545 2.644-.545 1.132 0 1.99.194 2.618.531.42.225.755.522 1.01.901zm1.001-1.163a4.293 4.293 0 00-1.302-1.06c-.914-.49-2.038-.709-3.327-.709-1.307 0-2.444.225-3.365.73-.498.272-.92.62-1.264 1.039a5.5 5.5 0 119.259 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});