define("ember-svg-jar/inlined/hashtag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.333 5c.23 0 .417.187.417.417V7.5h2.5V5.417c0-.23.187-.417.417-.417h.416c.23 0 .417.187.417.417V7.5h2.083c.23 0 .417.187.417.417v.416c0 .23-.187.417-.417.417H12.5v2.5h2.083c.23 0 .417.187.417.417v.416c0 .23-.187.417-.417.417H12.5v2.083c0 .23-.187.417-.417.417h-.416a.417.417 0 01-.417-.417V12.5h-2.5v2.083c0 .23-.187.417-.417.417h-.416a.417.417 0 01-.417-.417V12.5H5.417A.417.417 0 015 12.083v-.416c0-.23.187-.417.417-.417H7.5v-2.5H5.417A.417.417 0 015 8.333v-.416c0-.23.187-.417.417-.417H7.5V5.417c0-.23.187-.417.417-.417h.416zm2.917 6.25v-2.5h-2.5v2.5h2.5z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});