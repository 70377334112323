define("@additive-apps/ui/templates/components/ui-table/head", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vnh3DxXi",
    "block": "[[[10,\"tr\"],[14,0,\"ui-table__head-row\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"columns\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@column\",\"@onClick\",\"@classNames\",\"@sorted\"],[[30,1],[28,[37,3],[[30,0],\"onSort\"],null],[30,1,[\"classNames\"]],[28,[37,4],[[30,0,[\"_sortState\"]],[30,1,[\"key\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[18,2,[[28,[37,6],null,[[\"headSuffix\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"column\",\"&default\"],false,[\"each\",\"-track-array\",\"ui-table/head-cell\",\"action\",\"get\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-table/head.hbs",
    "isStrictMode": false
  });
});