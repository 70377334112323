define("@additive-apps/ui/templates/components/ui-when", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "WNEXxHzh",
    "block": "[[[41,[30,0,[\"_showContent\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-when.hbs",
    "isStrictMode": false
  });
});