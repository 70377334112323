define("ember-svg-jar/inlined/thumb-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.492 4.008a.498.498 0 01.055.013l.92.276A2.093 2.093 0 0115.96 6.27l.043 2.732h1.497a2 2 0 011.951 2.44l-.979 4.343a3 3 0 01-2.72 2.334c-1.255.086-2.339.047-3.251-.117-1.42-.256-2.586-.59-3.5-1H5.5a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h4c1.196-1.054 2.03-1.997 2.5-2.83.405-.717.71-1.637.91-2.76a.5.5 0 01.582-.403zm.653 1.77c-.215.808-.492 1.519-.84 2.132-.563.998-1.498 2.056-2.804 3.208l-.002 4.854c.641.21 1.398.396 2.267.553.78.14 1.742.175 2.881.096a1.5 1.5 0 001.36-1.166l.98-4.344a.5.5 0 00-.488-.61h-2.974l-.066-4.208a.593.593 0 00-.314-.515zM9 15.5v-4H6.5v4H9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});