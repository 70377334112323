define("ember-svg-jar/inlined/recalculate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.66 4.167a.417.417 0 01.295.711l-.845.846a6.667 6.667 0 01-4.674 10.935.42.42 0 01-.437-.422v-.416a.42.42 0 01.4-.413 5.416 5.416 0 003.823-8.796l-1.01 1.01a.417.417 0 01-.712-.295V4.583c0-.23.186-.416.416-.416h2.745zm-6.097-.833a.42.42 0 01.437.422v.416a.42.42 0 01-.399.413 5.415 5.415 0 00-3.819 8.801l1.007-1.008a.417.417 0 01.71.295v2.744c0 .23-.186.416-.416.416H4.34a.417.417 0 01-.295-.711l.85-.848a6.667 6.667 0 014.67-10.94z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});