define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.56 13.56l.88.88L16.878 6 16 5.121zM6.5 17.5h9a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5V18a.5.5 0 01.5-.5zm9.146-14.146a.5.5 0 01.708 0l2.292 2.292a.5.5 0 010 .708l-9.5 9.5a.5.5 0 01-.353.146H6.5a.5.5 0 01-.5-.5v-2.293a.5.5 0 01.146-.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});