define("ember-modifier/-private/class/modifier-manager", ["exports", "@ember/modifier", "@ember/object", "@ember/destroyable"], function (_exports, _modifier, _object, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function destroyModifier(modifier) {
    modifier.willRemove();
    modifier.willDestroy();
  }
  class ClassBasedModifierManager {
    constructor(owner) {
      this.owner = owner;
      _defineProperty(this, "capabilities", (0, _modifier.capabilities)('3.13'));
    }
    createModifier(factory, args) {
      const Modifier = factory.class;
      const modifier = new Modifier(this.owner, args);
      (0, _destroyable.registerDestructor)(modifier, destroyModifier);
      return modifier;
    }
    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }
    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      (0, _object.set)(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }
    destroyModifier(instance) {
      (0, _destroyable.destroy)(instance);
    }
  }
  _exports.default = ClassBasedModifierManager;
});