define("@additive-apps/utils/services/localization", ["exports", "@ember/service", "@ember/application", "@ember/object", "ember-concurrency", "fetch", "rsvp"], function (_exports, _service, _application, _object, _emberConcurrency, _fetch, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Service for localization stuff:
   *
   *   - getCountries: will fetch and return countries from localization-api (aka helper-api)
   *
   * @module Services
   * @class localization
   */
  // eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    /**
     * Already fetched countries.
     * Cache-object.
     *
     * Will cache it by locale as key
     *
     * ```js
     * {
     *  'de': [{country1}, {country2}],
     *  'it': [{country1}, {country2}]
     * };
     *```
     * @property _countries
     * @type {Object}
     * @private
     */
    _countries: null,
    /**
     * When a fetch-task (by locale) is currently running,
     * it will hold the currently running task-instance.
     *
     * key -> locale
     * value -> task
     *
     * @property _runningTasks
     * @type {Object}
     * @private
     */
    _runningTasks: null,
    /**
     * The countries endpoint where to fetch from.
     *
     * @property _endPoint
     * @type {String}
     * @private
     */
    _endPoint: (0, _object.computed)({
      get() {
        const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
        if (!ENV || !ENV.APP || !ENV.APP.localizationApiBaseHost) {
          throw new ErrorEvent("[service:localization] Environment-var 'localizationApiBaseHost' missing");
        }
        return `${ENV.APP.localizationApiBaseHost}/api/countries`;
      }
    }),
    /**
     * Task to fetch countries with locale as accept-language header.
     *
     * @property _fetchCountriesTask
     * @type {Object}
     * @private
     */
    _fetchCountriesTask: (0, _emberConcurrency.task)(function* (locale) {
      const url = this._endPoint;
      try {
        const response = yield (0, _fetch.default)(url, {
          headers: {
            'Accept-Language': locale || 'de'
          }
        });
        const data = yield response.json();
        if (data && data.countries) {
          return data.countries;
        }
        return [];
      } catch (error) {
        throw new Error('[service:localization] Could not fetch countries', error);
      }
    }).drop(),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_countries', {});
      (0, _object.set)(this, '_runningTasks', {});
    },
    /**
     * Function to get countries from localization-api.
     *
     * @method getCountries
     * @param {String} locale  The locale to use for fetch header
     * @return {Promise} Returns a promise
     * @public
     */
    getCountries(locale) {
      /* Check and return cached countries if in given locale available */
      if (Object.keys(this._countries).indexOf(locale) >= 0) {
        return (0, _rsvp.resolve)(this._countries[locale]);
      }

      /* Return current running task instance if exists */
      if (this._runningTasks[locale]) {
        return this._runningTasks[locale];
      }

      /* Fetch countries and cache new fetched countries */
      const task = this._fetchCountriesTask.perform(locale);
      task.then(countries => {
        let newCountriesLocale = {};
        newCountriesLocale[locale] = countries;
        (0, _object.set)(this, '_countries', Object.assign({}, this._countries, newCountriesLocale));
        const currentRunningTasks = this._runningTasks;
        delete currentRunningTasks[locale];
        (0, _object.set)(this, '_runningTasks', Object.assign({}, currentRunningTasks));
      });

      /* Cache currently started tasks */
      let newTask = {};
      newTask[locale] = task;
      (0, _object.set)(this, '_runningTasks', Object.assign({}, this._runningTasks, newTask));
      return task;
    }
  });
});