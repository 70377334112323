define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.5 9a1.5 1.5 0 100 3 1.5 1.5 0 000-3z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.5 4a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h15zm-14 14.5v-11h13v11h-13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});