define("ember-svg-jar/inlined/off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.01 13.73c1.252-.894 2.252-1.952 2.997-2.884a.489.489 0 00-.093-.696l-.4-.3a.512.512 0 00-.706.093c-.789.983-1.865 2.084-3.207 2.927-1.296.813-2.831 1.38-4.601 1.38s-3.305-.567-4.601-1.38c-1.342-.843-2.418-1.944-3.207-2.927a.512.512 0 00-.707-.093l-.399.3a.489.489 0 00-.093.696c.744.932 1.745 1.99 2.997 2.884l-.875 1.455a.5.5 0 00.17.686l.43.258a.5.5 0 00.685-.171l.862-1.433a9.988 9.988 0 003.988 1.196v1.78a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-1.78a9.988 9.988 0 003.988-1.196l.862 1.433a.5.5 0 00.686.17l.428-.257a.5.5 0 00.171-.686l-.875-1.455z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});