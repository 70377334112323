define("@additive-apps/ui/templates/components/ui-skeleton", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "t3ekS/81",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[30,0,[\"itemsCount\"]]],null]],null]],null],null,[[[1,\"  \"],[8,[39,3],null,[[\"@index\",\"@cardHeight\"],[[30,2],[30,0,[\"itemsHeight\"]]]],null],[1,\"\\n\"]],[1,2]],null]],[\"a\",\"index\"],false,[\"each\",\"-track-array\",\"loop\",\"ui-skeleton-card\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-skeleton.hbs",
    "isStrictMode": false
  });
});