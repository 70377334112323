define("ember-svg-jar/inlined/shared-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 10.5a1.415 1.415 0 110 2.83 1.415 1.415 0 010-2.83z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 7h8.5a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h6a.5.5 0 01.5.5V7zM5.5 8.5v9h2.54c.243-2.025 1.562-3.038 3.96-3.038 2.398 0 3.717 1.013 3.96 3.038h2.54v-9h-13zm8.53 8.052c-.27-.29-.832-.59-2.03-.59s-1.76.3-2.03.59c-.166.178-.33.466-.413.938h4.886c-.084-.472-.247-.76-.413-.938z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});