define("ember-svg-jar/inlined/dna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.25 12c0-1.312.682-2.199 1.55-2.85.662-.496 1.505-.907 2.3-1.294.193-.093.382-.185.565-.277.992-.496 1.884-1.008 2.534-1.713.541-.586.934-1.327 1.029-2.366a.537.537 0 01.522-.5h.5c.276 0 .502.224.483.5-.102 1.44-.645 2.531-1.432 3.384-.85.92-1.958 1.533-2.966 2.037-.222.111-.438.216-.646.318-.78.38-1.453.71-1.989 1.111-.632.474-.95.962-.95 1.65s.318 1.176.95 1.65c.27.202.264.628-.025.802a12.3 12.3 0 00-.456.288.488.488 0 01-.577-.013 5.36 5.36 0 01-.142-.119c-.72-.625-1.25-1.451-1.25-2.608zM14.241 17.156c-.332.168-.379.629-.12.896.027.027.053.054.078.082.541.586.934 1.327 1.029 2.366.025.276.246.5.522.5h.5a.474.474 0 00.483-.5c-.102-1.44-.645-2.531-1.432-3.384a6.051 6.051 0 00-.081-.086.485.485 0 00-.579-.081c-.134.071-.268.14-.4.207zM9.878 5.948c.26.267.213.728-.12.896a22.99 22.99 0 00-.4.207.485.485 0 01-.578-.08 5.94 5.94 0 01-.081-.087C7.912 6.03 7.369 4.94 7.267 3.5A.474.474 0 017.75 3h.5c.276 0 .497.225.522.5.095 1.039.488 1.78 1.03 2.366.024.028.05.055.076.082zM14.325 9.548c-.289.174-.295.6-.025.802.632.474.95.962.95 1.65s-.318 1.176-.95 1.65c-.536.402-1.209.73-1.989 1.11v.001a70.87 70.87 0 00-.646.318c-1.008.504-2.116 1.117-2.966 2.037-.787.853-1.33 1.944-1.432 3.384-.02.276.207.5.483.5h.5a.537.537 0 00.522-.5c.095-1.039.488-1.78 1.03-2.366.65-.705 1.541-1.217 2.533-1.713.183-.091.371-.183.563-.276.796-.388 1.64-.799 2.302-1.295.868-.651 1.55-1.538 1.55-2.85 0-1.157-.53-1.983-1.25-2.608a5.188 5.188 0 00-.142-.12.488.488 0 00-.577-.012c-.153.1-.305.197-.456.288z\"/><path d=\"M12 11a1 1 0 100 2 1 1 0 000-2zM11 4a1 1 0 112 0 1 1 0 01-2 0zM12 19a1 1 0 100 2 1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});