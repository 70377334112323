define("ember-svg-jar/inlined/drop-down-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 11.057l-3.118 3.12a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707l3.826-3.827a.5.5 0 01.707 0l3.823 3.827a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0L12 11.056z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});