define("ember-svg-jar/inlined/meta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.395 6.572c.808-.78 1.791-1.322 2.99-1.322 1.598 0 2.831.958 3.677 2.204.846 1.244 1.375 2.863 1.584 4.448.21 1.58.112 3.215-.382 4.494-.5 1.292-1.482 2.354-3.04 2.354-1.872 0-3.141-1.54-4.099-3.143-.499-.835-.963-1.777-1.414-2.708l-.163-.336c-.183-.379-.365-.755-.548-1.123-.183.368-.365.744-.548 1.123l-.163.336c-.45.931-.915 1.873-1.414 2.708-.958 1.604-2.227 3.143-4.098 3.143-1.559 0-2.54-1.062-3.04-2.354-.495-1.279-.592-2.915-.383-4.494.209-1.585.738-3.204 1.583-4.448.847-1.246 2.08-2.204 3.678-2.204 1.199 0 2.182.543 2.99 1.322.515.496.975 1.102 1.395 1.76.42-.658.88-1.264 1.395-1.76zm-.54 3.245c.362.683.707 1.398 1.044 2.094l.161.334c.453.934.892 1.82 1.353 2.593.964 1.613 1.844 2.412 2.81 2.412.715 0 1.271-.438 1.642-1.396.376-.971.482-2.335.294-3.756-.187-1.415-.654-2.796-1.337-3.802-.682-1.004-1.51-1.546-2.438-1.546-.7 0-1.329.305-1.949.902-.558.538-1.075 1.282-1.58 2.165zm-1.71 0c-.506-.883-1.022-1.627-1.58-2.165-.62-.597-1.25-.902-1.95-.902-.927 0-1.755.543-2.437 1.547-.683 1.005-1.15 2.386-1.337 3.801-.188 1.42-.082 2.785.294 3.756.37.958.927 1.396 1.642 1.396.966 0 1.846-.799 2.81-2.412.461-.772.9-1.659 1.353-2.593l.161-.334c.336-.696.682-1.41 1.043-2.094z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});