define("ember-svg-jar/inlined/id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.5 6a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h15zm-1 1.5h-13v9h.543c.248-2 1.567-3 3.957-3 2.39 0 3.709 1 3.957 3H18.5v-9zM10 15c-1.528 0-2.257.408-2.449 1.5h4.898c-.192-1.092-.92-1.5-2.449-1.5zm6.5-2.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V13a.5.5 0 01.5-.5h2zM10 9a1.75 1.75 0 110 3.5A1.75 1.75 0 0110 9zm6.5 1a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});