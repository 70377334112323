define("ember-svg-jar/inlined/undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.146 9.677a.5.5 0 010-.707L8.97 5.146a.5.5 0 01.707 0l.354.354a.5.5 0 010 .707L7.664 8.573h5.19c3.313 0 5.999 2.113 5.999 5.427 0 3.314-2.686 5.573-6 5.573h-1.116a.445.445 0 01-.445-.445v-.698c0-.197.16-.357.356-.357h1.205c2.485 0 4.5-1.588 4.5-4.073 0-2.485-2.015-3.927-4.5-3.927h-5.19l2.367 2.366a.5.5 0 010 .707l-.354.354a.5.5 0 01-.707 0L5.146 9.677z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});