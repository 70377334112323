define("@additive-apps/utils/utils/plans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.is = _exports.gte = _exports.PLAN_STARTER = _exports.PLAN_PROFESSIONAL = _exports.PLAN_ENTERPRISE = void 0;
  const PLAN_ENTERPRISE = _exports.PLAN_ENTERPRISE = 'enterprise';
  const PLAN_PROFESSIONAL = _exports.PLAN_PROFESSIONAL = 'professional';
  const PLAN_STARTER = _exports.PLAN_STARTER = 'starter';
  const PLANS_MAP = {
    [PLAN_ENTERPRISE]: [PLAN_STARTER, PLAN_PROFESSIONAL, PLAN_ENTERPRISE],
    [PLAN_PROFESSIONAL]: [PLAN_STARTER, PLAN_PROFESSIONAL],
    [PLAN_STARTER]: [PLAN_STARTER]
  };

  /**
   * Checks if a plan is greater or equal the current plan.
   *
   * @function gte
   * @param {STRING}    plan         The plan to check
   * @param {STRING}    currentPlan  The current organizations plan
   */
  const gte = (plan, currentPlan) => {
    return PLANS_MAP[plan] ? PLANS_MAP[plan].indexOf(currentPlan) > -1 : false;
  };

  /**
   * Checks if a plan is the current plan.
   *
   * @function is
   * @param {STRING}    plan         The plan to check
   * @param {STRING}    currentPlan  The current organizations plan
   */
  _exports.gte = gte;
  const is = (plan, currentPlan) => {
    return plan === currentPlan;
  };
  _exports.is = is;
});