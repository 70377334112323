define("ember-svg-jar/inlined/additive-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M36.365 9.668a.437.437 0 01-.132.321.436.436 0 01-.321.132H30.83v5.066a.434.434 0 01-.132.321.434.434 0 01-.322.133h-3.665a.436.436 0 01-.321-.133.437.437 0 01-.132-.32V10.12h-5.081a.434.434 0 01-.321-.132.434.434 0 01-.133-.321V6.002c0-.125.044-.232.133-.32a.437.437 0 01.32-.133h5.082V.453a.44.44 0 01.132-.32.44.44 0 01.321-.133h3.665c.127 0 .234.044.322.132a.436.436 0 01.132.321V5.55h5.08a.44.44 0 01.322.132.44.44 0 01.132.321zM14.683 31.622l-4.421-18.931c-.026-.1-.063-.145-.114-.133-.05.014-.088.057-.113.133L5.5 31.622c-.075.252-.251.378-.529.378H.4c-.327 0-.453-.163-.378-.491L6.823 5.927c.075-.252.252-.378.529-.378h5.48c.277 0 .453.126.528.378l6.84 25.582.037.15c0 .228-.138.341-.415.341h-4.648c-.251 0-.416-.126-.491-.378z\"/>",
    "attrs": {
      "fill": "#00172F",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40"
    }
  };
});