define("@additive-apps/utils/validators/validate-if", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateIf;
  /**
   * Validator allows to define validations which only gets executed if the `if` option is true.
   *
   * `options.validate` can either be a single function or an array of functions
   * where each should return `true` when valid.
   *
   * `options.if` can be a object with keys and value,
   * where each key represent a models key,
   * and each value the value.
   * It supports a function as well where the user can use custom if logic, be sure to return `true` when it execute validations.
   * When each of the values equals the corresponding model value (or "if"-function returns true)
   * it will validate the passed `options.validate` functions.
   *
   * `options.firstErrorMessage` when this is set to true it will return the first error-message, rather than an object
   *
   *
   * @param {*} options
   * @param {Function|Array} options.validate (The validation-functions which gets executed when "if" matches)
   * @param {Object|Function} options.if (The matcher object|function)
   * @param {Boolean} options.firstErrorMessage (Returnes first error-message instead of an object of errors, when true)
   *
   * @function validateIf
   */
  function validateIf() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return (key, newValue, oldValue, changes, content) => {
      if (!options || !options.if || !options.validate) {
        throw new Error('[validate-if] Please provide all options');
      }
      let shouldValidate = true;
      if (typeof options.if === 'function') {
        const valid = options.if(key, newValue, oldValue, changes, content);
        if (valid !== true) {
          shouldValidate = false;
        }
      } else {
        Object.keys(options.if).forEach(_key => {
          let checkValue = (0, _object.get)(content, _key);
          /* When changeset has changes use changes instead of contents value */
          if (Object.keys(changes).indexOf(_key) >= 0) {
            checkValue = (0, _object.get)(changes, _key);
          }
          if ((0, _object.get)(options.if, _key) != checkValue) {
            shouldValidate = false;
          }
        });
      }

      /* If `if`-condition does not match we simply skip validation */
      if (!shouldValidate) {
        return true;
      }
      let errors = {};
      const validations = Array.isArray(options.validate) ? options.validate : [options.validate];
      validations.forEach((validation, index) => {
        const validationResult = validation(key, newValue, oldValue, changes, content);
        if (validationResult === true) {
          /* Valid */
          return;
        }
        errors[index] = validationResult;
      });
      const errorKeys = errors && Object.keys(errors) || [];
      if (errorKeys.length === 0) {
        return true;
      }
      if (options.firstErrorMessage === true) {
        return errors[errorKeys[0]];
      }
      return errors;
    };
  }
});