define("ember-deep-set/index", ["exports", "@ember/object", "@ember/utils", "@ember/debug"], function (_exports, _object, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = deepSet;
  /**
   * Check if argument is an object.
   *
   * @private
   * @param {Any} obj
   * @returns {Boolean}
   */
  function isObject(obj) {
    return (0, _utils.typeOf)(obj) === 'object' || (0, _utils.typeOf)(obj) === 'instance';
  }

  /**
   * Validate arguments.
   *
   * @private
   * @param {Any} obj
   * @param {Any} key
   * @returns {Void}
   */
  function validateArgs(obj, key) {
    (false && !(isObject(obj)) && (0, _debug.assert)('[ember-deep-set] non-object passed in', isObject(obj)));
    (false && !((0, _utils.typeOf)(key) === 'string') && (0, _debug.assert)('[ember-deep-set] `key` must be a string', (0, _utils.typeOf)(key) === 'string'));
  }

  /**
   * Deeply set a value on an Ember Object or POJO. Mutates the object.
   *
   * @export
   * @public
   * @param {Object} obj
   * @param {String} key
   * @param {Any} value
   * @returns {Object}
   */
  function deepSet(obj, key, value) {
    (0, _debug.runInDebug)(() => validateArgs(obj, key));
    key.split('.').reduce((acc, currentKey, i, allKeys) => {
      let currentValue = (0, _object.getWithDefault)(acc, currentKey, {});
      let valueToSet = allKeys[i + 1] && isObject(currentValue) ? currentValue : allKeys[i + 1] && !isObject(currentValue) ? {} : value;
      if (valueToSet === undefined) {
        // ember's set method does not handle undefined values correctly in older versions
        // https://github.com/emberjs/ember.js/issues/14270
        if (acc.hasOwnProperty(currentKey) || typeof acc.setUnknownProperty !== 'function') {
          acc[currentKey] = valueToSet;
          if (typeof acc.notifyPropertyChange === 'function') {
            acc.notifyPropertyChange(currentKey);
          }
        } else {
          acc.setUnknownProperty(currentKey, valueToSet);
        }
        return valueToSet;
      } else {
        return (0, _object.set)(acc, currentKey, valueToSet);
      }
    }, obj);
    return value;
  }
});