define("ember-intl/-private/empty-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const proto = Object.create(null, {
    constructor: {
      value: undefined,
      enumerable: false,
      writable: true
    }
  });
  function EmptyObject() {}
  EmptyObject.prototype = proto;

  /**
   * @private
   * @hide
   */
  var _default = _exports.default = EmptyObject;
});