define("ember-svg-jar/inlined/blockquote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 15a1.5 1.5 0 001.5-1.5c0-1-.5-1.5-1.5-1.5h-.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v5a3 3 0 01-3 3h-.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h.5zm-6 0a1.5 1.5 0 001.5-1.5c0-1-.5-1.5-1.5-1.5h-.5a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v5a3 3 0 01-3 3h-.5A.5.5 0 017 16v-.5a.5.5 0 01.5-.5H8z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});