define("ember-svg-jar/inlined/align-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.5 15a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-7A.5.5 0 014 16v-.5a.5.5 0 01.5-.5h7zm0-4a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-7A.5.5 0 014 12v-.5a.5.5 0 01.5-.5h7zm8-4a.5.5 0 01.5.5V8a.5.5 0 01-.5.5h-15A.5.5 0 014 8v-.5a.5.5 0 01.5-.5h15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});