define("ember-svg-jar/inlined/cake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 6a.5.5 0 01.5.5L8.499 8h2.75l.001-1.5a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5L12.749 8h2.75l.001-1.5A.5.5 0 0116 6h.5a.5.5 0 01.5.5L16.999 8h.501a2 2 0 012 2v7.5a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V10a2 2 0 012-2h.499L7 6.5a.5.5 0 01.5-.5H8zm6.25 6.984l-.131.14A2.99 2.99 0 0112 14l-.205-.007a2.993 2.993 0 01-2.045-1.009l-.131.14A2.99 2.99 0 017.5 14l-.176-.005A2.982 2.982 0 016 13.599V16.5h12v-2.902a2.986 2.986 0 01-1.5.402l-.205-.007a2.993 2.993 0 01-2.045-1.009zM17.5 9.5h-11a.5.5 0 00-.5.5v1l.007.145A1.5 1.5 0 007.5 12.5l.138-.006a1.5 1.5 0 001.316-1.124l.027-.079A.491.491 0 019.432 11h.637l.083.007a.49.49 0 01.394.363l.038.128A1.5 1.5 0 0012 12.5l.138-.006a1.5 1.5 0 001.316-1.124l.027-.079a.491.491 0 01.451-.291h.637l.083.007a.49.49 0 01.394.363l.038.128A1.5 1.5 0 0016.5 12.5l.145-.007A1.5 1.5 0 0018 11v-1a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});