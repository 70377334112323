define("ember-svg-jar/inlined/duplicate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5 8v7.5h6v-10H10v2a.5.5 0 01-.5.5zM15 7h2.5a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5V17H6.5a.5.5 0 01-.5-.5V7.207a.5.5 0 01.146-.353l2.708-2.708A.5.5 0 019.207 4H14.5a.5.5 0 01.5.5zm0 1.5v8a.5.5 0 01-.5.5h-4v1.5h6v-10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});