define("ember-svg-jar/inlined/arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.743 7.314v11.69a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V7.313L6.877 11.68a.5.5 0 01-.707 0l-.354-.353a.5.5 0 010-.707l5.824-5.824a.5.5 0 01.707 0l5.823 5.824a.5.5 0 010 .707l-.354.353a.5.5 0 01-.707 0l-4.366-4.366z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});