define("ember-svg-jar/inlined/people-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 11.506a.51.51 0 00-.522-.506c-4.851.19-7.142 3.066-6.64 8.311l.022.227a.5.5 0 00.497.452H10.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5H5.282c-.19-3.932 1.488-5.84 5.227-5.99a.503.503 0 00.49-.495v-.499z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14 7a3 3 0 10-6 0 3 3 0 006 0zM9.5 7a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z\"/><path d=\"M20.177 17.03l-2.823 2.824a.5.5 0 01-.707 0l-2.824-2.824a.5.5 0 010-.707l.354-.353a.5.5 0 01.707 0l1.365 1.367.001-5.69a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v5.69l1.366-1.367a.5.5 0 01.707 0l.354.353a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});