define("ember-svg-jar/inlined/audit-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.5 6.5A.5.5 0 005 7v.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5V7a.5.5 0 00-.5-.5h-13z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.723 11.591a3.75 3.75 0 00-5.781 4.72l-1.998 1.997a.5.5 0 000 .708l.354.353a.5.5 0 00.707 0l1.997-1.997a3.752 3.752 0 004.72-5.781zm-4.243 1.06a2.25 2.25 0 113.182 3.183 2.25 2.25 0 01-3.182-3.182z\"/><path d=\"M5 11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5V11zM5.5 14.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V15a.5.5 0 00-.5-.5h-4z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor"
    }
  };
});