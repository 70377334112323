define("ember-svg-jar/inlined/compass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.745 6.728a.417.417 0 01.527.527l-1.438 4.315a.417.417 0 01-.264.264l-4.315 1.438a.417.417 0 01-.527-.527L8.166 8.43a.417.417 0 01.264-.264l4.315-1.438zM10.833 10a.833.833 0 11-1.666 0 .833.833 0 011.666 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.667 10a6.667 6.667 0 11-13.334 0 6.667 6.667 0 0113.334 0zm-1.25 0a5.417 5.417 0 11-10.834 0 5.417 5.417 0 0110.834 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor"
    }
  };
});