define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 4a6 6 0 016 6c0 2.913-1.886 6.14-5.658 9.683a.5.5 0 01-.684 0C7.886 16.14 6 12.913 6 10a6 6 0 016-6zm0 1.5A4.5 4.5 0 007.5 10c0 2.255 1.465 4.915 4.5 7.928 3.035-3.013 4.5-5.673 4.5-7.928A4.5 4.5 0 0012 5.5zm0 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});