define("@additive-apps/utils/helpers/array-find", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayFind = arrayFind;
  _exports.default = void 0;
  /**
   * Returns the first element of an array where a given property has a given value
   *
   * @function arrayFind
   * @param {Any} params[0] the value
   * @param {Array} params[1] the array
   * @param {String} params[2] the name of the property, default 'value'
   * @return {Any} the found element
   */
  function arrayFind(params) {
    const value = params[0];
    const array = params[1];
    const property = params[2] || 'value';
    if (array && array.length && value) {
      return array.find(entry => entry[property] === value);
    }
    return null;
  }
  var _default = _exports.default = (0, _helper.helper)(arrayFind);
});