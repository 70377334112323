define("@additive-apps/utils/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CURRENCY_SYMBOLS = void 0;
  const CURRENCY_SYMBOLS = _exports.CURRENCY_SYMBOLS = {
    EUR: '€',
    CHF: 'CHF',
    USD: '$'
  };
});