define("ember-svg-jar/inlined/last-minute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 8.496c-1.135-1.702-2.592-3.405-3.64-4.56l-.013-.013a68.134 68.134 0 00-.588-.637c-.293-.313-.817-.126-.868.3a31.161 31.161 0 01-.11.834l-.003.014c-.304 2.071-.97 5.062-2.278 5.062-.894 0-1.061-.657-1.185-1.146-.141-.555-.227-.894-1.265.197-.054.054-.108.11-.16.166l-.024.025a7 7 0 0010.084 9.708c2.733-2.734 2.05-6.95.05-9.95zm-8.89 8.89a5.501 5.501 0 01-.521-7.176c.466.502 1.124.786 1.911.786.963 0 1.636-.564 2.034-1.06.399-.497.682-1.11.89-1.679.275-.752.481-1.603.635-2.4.883 1.02 1.881 2.254 2.693 3.471 1.826 2.74 2.127 6.068.137 8.057a5.483 5.483 0 01-1.743 1.177s-1.237-5.278-1.887-5.854a.385.385 0 00-.518 0c-.65.576-1.887 5.854-1.887 5.854a5.485 5.485 0 01-1.743-1.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});