define("@additive-apps/ui/templates/components/ui-toast-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "tNKPgozm",
    "block": "[[[18,2,null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"_activeToasts\"]]],null]],null],null,[[[1,\"  \"],[8,[39,3],null,[[\"@class\",\"@duration\",\"@title\",\"@description\",\"@progress\",\"@type\",\"@icon\",\"@image\",\"@imageClass\",\"@label\",\"@actionIcon\",\"@entering\",\"@isStacked\",\"@onClose\",\"@onClick\",\"@onAbort\",\"@parent\",\"@hasCustomParent\"],[[30,1,[\"toastClass\"]],[30,1,[\"duration\"]],[30,1,[\"title\"]],[30,1,[\"description\"]],[30,1,[\"progress\"]],[30,1,[\"type\"]],[30,1,[\"icon\"]],[30,1,[\"image\"]],[30,1,[\"imageClass\"]],[30,1,[\"actionLabel\"]],[30,1,[\"actionIcon\"]],[30,1,[\"entering\"]],[30,1,[\"isStacked\"]],[28,[37,4],[[30,0],[30,0,[\"onClose\"]],[30,1]],null],[30,1,[\"onClick\"]],[30,1,[\"onAbort\"]],[52,[30,1,[\"parent\"]],[30,1,[\"parent\"]],[30,0,[\"parent\"]]],[52,[30,1,[\"parent\"]],true,false]]],null],[1,\"\\n\"]],[1]],null]],[\"toast\",\"&default\"],false,[\"yield\",\"each\",\"-track-array\",\"ui-toast\",\"action\",\"if\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-toast-container.hbs",
    "isStrictMode": false
  });
});