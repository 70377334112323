define("ember-svg-jar/inlined/food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 5a.5.5 0 01.5.5v4.75h1V5.5a.5.5 0 01.5-.5H9a.5.5 0 01.5.5v4.75h1V5.5A.5.5 0 0111 5h.5a.5.5 0 01.5.5v5c0 .69-.56 1.25-1.25 1.25H9.5v6.75a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-6.75H6.75c-.69 0-1.25-.56-1.25-1.25v-5A.5.5 0 016 5h.5zm8-.45l.089.008c.058.01.114.032.165.062l.073.052.277.243c2.17 1.942 3.3 3.884 3.39 5.828l.006.254-.004.203c-.07 1.775-1.068 2.724-2.995 2.847L15.5 18.5a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V5.051a.5.5 0 01.5-.501zm1 2.922v5.072c.671-.056.994-.238 1.144-.372.236-.211.356-.607.356-1.175 0-1.132-.504-2.313-1.5-3.525z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});