define("ember-svg-jar/inlined/small-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.175 12l-2.119-2.117a.5.5 0 010-.707l.353-.354a.5.5 0 01.707 0l2.828 2.825a.5.5 0 010 .707l-2.828 2.824a.5.5 0 01-.707 0l-.353-.354a.5.5 0 010-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});