define("ember-engines/components/link-to-component", ["exports", "@ember/routing/link-component", "@ember/application", "@ember/object", "@ember/utils", "@ember/debug"], function (_exports, _linkComponent, _application, _object, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _linkComponent.default.extend({
    didReceiveAttrs() {
      this._super(...arguments);
      let owner = (0, _application.getOwner)(this);
      (false && !(owner.mountPoint !== undefined) && (0, _debug.assert)(`You attempted to use {{link-to}} within a routeless engine, this is not supported. Use {{link-to-external}} to construct links within a routeless engine. See http://ember-engines.com/guide/linking-and-external-links for more info.`, owner.mountPoint !== undefined));
      if (owner.mountPoint) {
        // https://emberjs.github.io/rfcs/0459-angle-bracket-built-in-components.html
        const routeKey = 'targetRouteName' in this ? 'targetRouteName' : 'route';

        // Prepend engine mount point to targetRouteName
        this._prefixProperty(owner.mountPoint, routeKey);

        // Prepend engine mount point to current-when if set
        if ((0, _object.get)(this, 'current-when') !== null) {
          this._prefixProperty(owner.mountPoint, 'current-when');
        }
      }
    },
    _prefixProperty(prefix, prop) {
      let propValue = (0, _object.get)(this, prop);

      // Sometimes `targetRouteName` will be a class
      if ((0, _utils.typeOf)(propValue) !== 'string') {
        return;
      }
      let namespacedPropValue;
      if (prop === 'current-when') {
        // `current-when` is a space-separated list of routes
        namespacedPropValue = propValue.split(' ');
        namespacedPropValue = namespacedPropValue.map(propValue => this._namespacePropertyValue(prefix, propValue));
        namespacedPropValue = namespacedPropValue.join(' ');
      } else {
        namespacedPropValue = this._namespacePropertyValue(prefix, propValue);
      }
      (0, _object.set)(this, prop, namespacedPropValue);
    },
    _namespacePropertyValue(prefix, propValue) {
      if (propValue === 'application') {
        return prefix;
      } else {
        return prefix + '.' + propValue;
      }
    }
  });
});