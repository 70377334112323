define("@additive-apps/utils/validators/validate-each", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateEach;
  /**
   * Validationhelper to validate sub-elements of an array:
   *
   *```js
   * import validateEach from '@additive-apps/utils/validators/validate-each';
   *
   * {
   *   myArray: validateEach({
   *    givenName: validatePresence({ presence: true, message: presenceMessage }),
   *    familyName: [
   *      validateNumber({ positive: true, message: numberMessage }),
   *      validatePresence({ presence: true, message: presenceMessage })
   *    ]
   *  })
   * }
   *```
   * Each array element will be validated and it returns true if everything is valid
   * otherwise an errors array:
   *
   *```js
   * {
   *   myArray: {
   *     givenName: {
   *       __ARRAYINDEX__: [ERROR_MESSAGE],
   *       __ARRAYINDEX__: [ERROR_MESSAGE],
   *     },
   *     familyName: {
   *       __ARRAYINDEX__: [ERROR_MESSAGE]
   *     }
   *   }
   * }
   *```
   * __ARRAYINDEX__ is then the index inside the array, where the error happend.
   * It will return all indexe which errored
   *
   * @param {*} deepValidations
   *
   * @function validateEach
   */
  function validateEach(deepValidations) {
    /* Will be ['givenName','familyName'] with the example scenario from comments */
    const deepValidationsKeys = Object.keys(deepValidations);
    return (_, array) => {
      let errors = {};

      /* Iterate through value-array */
      (Array.isArray(array) ? array : []).map((arrayItem, arrayIndex) => {
        // Iterate through all defined sub-element-validations
        deepValidationsKeys.map(key => {
          /* Get all validations-rules from sub-element */
          let rules = deepValidations[key];
          if (!Array.isArray(rules)) {
            rules = [rules];
          }

          /* Iterate through defined validation*/
          rules.map(rule => {
            //Call defined-validation for sub-element against value
            const validationResponse = rule(key, arrayItem[key]);

            /* If not true it has an error */
            if (validationResponse !== true) {
              if (!errors[key]) {
                errors[key] = {};
              }
              if (!errors[key][arrayIndex]) {
                errors[key][arrayIndex] = [];
              }
              errors[key][arrayIndex].push(validationResponse);
            }
          });
        });
      });
      return Object.keys(errors).length === 0 ? true : errors;
    };
  }
});