define("ember-svg-jar/inlined/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 18.5a6.5 6.5 0 006.481-6c.021-.275.243-.5.519-.5h.5c.276 0 .502.225.485.5A8 8 0 1111.5 4.015c.275-.017.5.209.5.485V5a.531.531 0 01-.5.519A6.5 6.5 0 0012 18.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});