define("@additive-apps/ui/templates/components/ui-tab-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "XHHje8jN",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0,[\"tabName\"]],[30,0,[\"routePath\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"ui-tab-item__link relative flex items-center font-sm secondary gap1\"],[12],[1,\"\\n\"],[41,[30,0,[\"icon\"]],[[[1,\"      \"],[1,[28,[35,3],[[30,0,[\"icon\"]]],[[\"class\"],[\"ui-tab-item__icon\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"ui-tab-item__button flex items-center\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@label\",\"@isTertiary\"],[[30,0,[\"tabName\"]],[28,[37,5],[[30,0,[\"isActive\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"contentCount\"]],[[[1,\"      \"],[10,0],[14,0,\"ui-tab-item__count ph05 ml1 br1 font-xs\"],[12],[1,\"\\n        \"],[1,[30,0,[\"contentCount\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"badge\"]],[[[1,\"      \"],[10,0],[14,0,\"blue bg-blue--10 ph05 ml1 br1 font-xs\"],[12],[1,\"\\n        \"],[1,[30,0,[\"badge\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"svg-jar\",\"ui-icon-button\",\"not\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-tab-item.hbs",
    "isStrictMode": false
  });
});