define("ember-engines/components/link-to-external-component", ["exports", "@ember/routing/link-component", "@ember/application", "@ember/object"], function (_exports, _linkComponent, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _linkComponent.default.extend({
    didReceiveAttrs() {
      this._super(...arguments);
      const owner = (0, _application.getOwner)(this);
      if (owner.mountPoint) {
        // https://emberjs.github.io/rfcs/0459-angle-bracket-built-in-components.html
        const routeKey = 'targetRouteName' in this ? 'targetRouteName' : 'route';
        const routeName = (0, _object.get)(this, routeKey);
        const externalRoute = owner._getExternalRoute(routeName);
        (0, _object.set)(this, routeKey, externalRoute);
      }
    }
  });
});