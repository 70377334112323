define("@additive-apps/ui/utils/model-action", ["exports", "@ember/debug", "@ember/string"], function (_exports, _debug, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = modelAction;
  /**
   * This decorator allows to define an action in a model.
   *
   * By default it makes a PUT request to the endpoint defined
   * by the decorated property.
   * Request method and callback can be optionally defined.
   *
   * Usage:
   * ```js
   * // makes a PUT request to /publish and returns the response
   * @modelAction() publish;
   *
   * // makes a GET request to /meta
   * @modelAction('GET', (response) => {
   *   // do something with the response
   *   return response
   * }) meta;
   * ```
   *
   * @function modelAction
   * @param {String} method, the request method
   * @param {Function} callback, a callback function that takes as input the response object
   * @return {Promise}
   */
  function modelAction() {
    let method = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'PUT';
    let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : data => data;
    return (_, property) => {
      return {
        value() {
          let adapterOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          let headers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
          (false && !(this.authenticatedFetch) && (0, _debug.assert)('Authenticated Fetch service must be defined', this.authenticatedFetch));
          (false && !(this.store) && (0, _debug.assert)('Store service must be defined', this.store));
          const {
            modelName
          } = this.constructor;
          const adapter = this.store.adapterFor(modelName);
          const url = adapter.urlForFindRecord(this.id, modelName, {
            adapterOptions
          });
          return this.authenticatedFetch.fetch(`${url}/${(0, _string.dasherize)(property)}`, {
            method,
            headers
          }).then(callback);
        }
      };
    };
  }
});