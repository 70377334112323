define("ember-animated/motions/resize", ["exports", "ember-animated"], function (_exports, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Resize = void 0;
  _exports.default = resize;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
    Smoothly resizes _sprite_ from its the initial size to its final size.
  
    _sprite_ must have both `initialBounds` and `finalBounds` set.
  
    Consider using `scale` instead because scale uses CSS transforms and will not trigger reflow.
  
    ```js
    for (let sprite of insertedSprites) {
      sprite.startAtSprite(beacons['source']);
      resize(sprite)
    }
    ```
  
    @function resize
    @export default
    @param {Sprite} sprite
    @return {Motion}
  */
  function resize(sprite) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new Resize(sprite, opts).run();
  }
  class Resize extends _emberAnimated.Motion {
    constructor() {
      super(...arguments);
      _defineProperty(this, "prior", null);
      _defineProperty(this, "widthTween", null);
      _defineProperty(this, "heightTween", null);
    }
    interrupted(motions) {
      let prior = motions.find(m => m instanceof this.constructor);
      if (prior) {
        this.prior = prior;
      }
    }
    *animate() {
      let sprite = this.sprite;
      let duration = this.duration;
      let widthTween;
      let heightTween;
      sprite.assertHasInitialBounds();
      sprite.assertHasFinalBounds();
      if (!this.prior) {
        widthTween = this.widthTween = new _emberAnimated.Tween(sprite.initialBounds.width / sprite.initialCumulativeTransform.a, sprite.finalBounds.width / sprite.finalCumulativeTransform.a, duration, this.opts.easing);
        heightTween = this.heightTween = new _emberAnimated.Tween(sprite.initialBounds.height / sprite.initialCumulativeTransform.d, sprite.finalBounds.height / sprite.finalCumulativeTransform.d, duration, this.opts.easing);
      } else {
        widthTween = this.widthTween = new _emberAnimated.Tween(0, sprite.finalBounds.width / sprite.finalCumulativeTransform.a - this.prior.sprite.finalBounds.width, duration, this.opts.easing).plus(this.prior.widthTween);
        heightTween = this.heightTween = new _emberAnimated.Tween(0, sprite.finalBounds.height / sprite.finalCumulativeTransform.d - this.prior.sprite.finalBounds.height, duration, this.opts.easing).plus(this.prior.heightTween);
      }
      while (!widthTween.done || !heightTween.done) {
        sprite.applyStyles({
          width: `${widthTween.currentValue}px`,
          height: `${heightTween.currentValue}px`
        });
        yield (0, _emberAnimated.rAF)();
      }
    }
  }
  _exports.Resize = Resize;
});