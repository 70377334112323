define("ember-engines/-private/engine-ext", ["@ember/application", "ember-engines/components/link-to-component", "ember-engines/components/link-to-external-component", "@ember/engine"], function (_application, _linkToComponent, _linkToExternalComponent, _engine) {
  "use strict";

  _engine.default.reopen({
    buildRegistry() {
      let registry = this._super(...arguments);
      if (!(this instanceof _application.default)) {
        registry.register('component:link-to', _linkToComponent.default);
        registry.register('component:link-to-external', _linkToExternalComponent.default);
      }
      return registry;
    }
  });
});