define("@sentry/ember/index", ["exports", "@ember/debug", "@ember/runloop", "@sentry/browser", "@sentry/core", "@sentry/utils", "ember"], function (_exports, _debug, _runloop, _browser, _core, _utils, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    init: true,
    getActiveTransaction: true,
    instrumentRoutePerformance: true,
    InitSentryForEmber: true
  };
  _exports.getActiveTransaction = _exports.InitSentryForEmber = void 0;
  _exports.init = init;
  _exports.instrumentRoutePerformance = void 0;
  var Sentry = _browser;
  Object.keys(_browser).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _browser[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _browser[key];
      }
    });
  });
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _getSentryInitConfig() {
    var _global$__sentryEmber;
    const _global = _utils.GLOBAL_OBJ;
    _global.__sentryEmberConfig = (_global$__sentryEmber = _global.__sentryEmberConfig) !== null && _global$__sentryEmber !== void 0 ? _global$__sentryEmber : {};
    return _global.__sentryEmberConfig;
  }

  /**
   * Initialize the Sentry SDK for Ember.
   */
  function init(_runtimeConfig) {
    const environmentConfig = {};
    (false && !(environmentConfig) && (0, _debug.assert)('Missing configuration.', environmentConfig));
    (false && !(environmentConfig.sentry || _runtimeConfig) && (0, _debug.assert)('Missing configuration for Sentry.', environmentConfig.sentry || _runtimeConfig));
    if (!environmentConfig.sentry) {
      // If environment config is not specified but the above assertion passes, use runtime config.
      environmentConfig.sentry = _objectSpread({}, _runtimeConfig);
    }

    // Merge runtime config into environment config, preferring runtime.
    Object.assign(environmentConfig.sentry, _runtimeConfig || {});
    const initConfig = Object.assign({}, environmentConfig.sentry);
    (0, _core.applySdkMetadata)(initConfig, 'ember');

    // Persist Sentry init options so they are identical when performance initializers call init again.
    const sentryInitConfig = _getSentryInitConfig();
    Object.assign(sentryInitConfig, initConfig);
    Sentry.init(initConfig);
  }

  /**
   * Grabs active transaction off scope.
   *
   * @deprecated You should not rely on the transaction, but just use `startSpan()` APIs instead.
   */
  const getActiveTransaction = () => {
    // eslint-disable-next-line deprecation/deprecation
    return Sentry.getCurrentScope().getTransaction();
  };
  _exports.getActiveTransaction = getActiveTransaction;
  const instrumentRoutePerformance = BaseRoute => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const instrumentFunction = async (op, description, fn, args) => {
      return (0, _browser.startSpan)({
        attributes: {
          [_core.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'custom',
          [_core.SEMANTIC_ATTRIBUTE_SENTRY_ORIGIN]: 'auto.ui.ember'
        },
        op,
        name: description,
        onlyIfParent: true
      }, () => {
        return fn(...args);
      });
    };
    const routeName = BaseRoute.name;
    return {
      // @ts-expect-error TS2545 We do not need to redefine a constructor here
      [routeName]: class extends BaseRoute {
        beforeModel() {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }
          return instrumentFunction('ui.ember.route.before_model', this.fullRouteName, super.beforeModel.bind(this), args);
        }
        async model() {
          for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
          }
          return instrumentFunction('ui.ember.route.model', this.fullRouteName, super.model.bind(this), args);
        }
        afterModel() {
          for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
            args[_key3] = arguments[_key3];
          }
          return instrumentFunction('ui.ember.route.after_model', this.fullRouteName, super.afterModel.bind(this), args);
        }
        setupController() {
          for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
            args[_key4] = arguments[_key4];
          }
          return instrumentFunction('ui.ember.route.setup_controller', this.fullRouteName, super.setupController.bind(this), args);
        }
      }
    }[routeName];
  };
  _exports.instrumentRoutePerformance = instrumentRoutePerformance;
  /**
   * @deprecated Use `Sentry.init()` instead.
   */
  const InitSentryForEmber = _exports.InitSentryForEmber = init;
});