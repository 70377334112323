define("ember-svg-jar/inlined/dragdrop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.708 8.958a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM7.708 13.542a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM13.542 7.708a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM12.292 13.542a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor"
    }
  };
});