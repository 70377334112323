define("ember-animated/color", ["exports", "ember-animated", "ember-animated/easings/linear", "ember-animated/element-remove"], function (_exports, _emberAnimated, _linear, _elementRemove) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ColorTween = _exports.Color = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // We use Element.remove in our color parser below.
  class Color {
    static fromComputedStyle(colorString) {
      let channels = parseComputedColor(colorString);
      return new Color(channels, channels.m[0]);
    }
    static fromUserProvidedColor(colorString) {
      return new Color(parseUserProvidedColor(colorString), colorString);
    }
    toString() {
      return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
    }
    constructor(_ref, sourceString) {
      let {
        r,
        g,
        b,
        a
      } = _ref;
      this.sourceString = sourceString;
      _defineProperty(this, "r", void 0);
      _defineProperty(this, "g", void 0);
      _defineProperty(this, "b", void 0);
      _defineProperty(this, "a", void 0);
      this.r = r;
      this.g = g;
      this.b = b;
      this.a = a;
    }
  }
  _exports.Color = Color;
  class ColorTween {
    constructor(initialColor, finalColor, duration) {
      let easing = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _linear.default;
      _defineProperty(this, "rTween", void 0);
      _defineProperty(this, "gTween", void 0);
      _defineProperty(this, "bTween", void 0);
      _defineProperty(this, "aTween", void 0);
      this.rTween = new _emberAnimated.Tween(initialColor.r * initialColor.a, finalColor.r * finalColor.a, duration, easing);
      this.gTween = new _emberAnimated.Tween(initialColor.g * initialColor.a, finalColor.g * finalColor.a, duration, easing);
      this.bTween = new _emberAnimated.Tween(initialColor.b * initialColor.a, finalColor.b * finalColor.a, duration, easing);
      this.aTween = new _emberAnimated.Tween(initialColor.a, finalColor.a, duration, easing);
    }
    get currentValue() {
      let nonZeroAlpha = this.aTween.currentValue || 1;
      return new Color({
        r: Math.floor(this.rTween.currentValue / nonZeroAlpha),
        g: Math.floor(this.gTween.currentValue / nonZeroAlpha),
        b: Math.floor(this.bTween.currentValue / nonZeroAlpha),
        a: this.aTween.currentValue
      }, '');
    }
    get done() {
      return [this.rTween, this.gTween, this.bTween, this.aTween].every(tween => tween.done);
    }
  }
  _exports.ColorTween = ColorTween;
  function parseComputedColor(c) {
    let m = /^rgb\((\d+), (\d+), (\d+)\)/.exec(c);
    if (m) {
      return {
        r: parseInt(m[1]),
        g: parseInt(m[2]),
        b: parseInt(m[3]),
        a: 1,
        m
      };
    }
    m = /^rgba\((\d+), (\d+), (\d+), (\d+(?:\.\d+)?)\)/.exec(c);
    if (m) {
      return {
        r: parseInt(m[1]),
        g: parseInt(m[2]),
        b: parseInt(m[3]),
        a: parseFloat(m[4]),
        m
      };
    }
    throw new Error(`unable to parse color ${c}`);
  }
  function parseUserProvidedColor(c) {
    let testElement = document.createElement('div');
    testElement.style.display = 'none';
    testElement.style.color = c;
    document.body.appendChild(testElement);
    let result = parseComputedColor(getComputedStyle(testElement).color);
    testElement.remove();
    return result;
  }
});