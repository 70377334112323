define("@additive-apps/ui/templates/components/ui-pusher", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "l+jEmYkD",
    "block": "[[[41,[30,0,[\"destinationElement\"]],[[[40,[[[1,\"    \"],[18,1,[[28,[37,3],null,[[\"data\"],[[30,0,[\"data\"]]]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"destinationElement\"]],null]],[]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"data\"],[[30,0,[\"data\"]]]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"in-element\",\"yield\",\"hash\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-pusher.hbs",
    "isStrictMode": false
  });
});