define("@additive-apps/utils/helpers/loop", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loop = loop;
  /**
   * Helper that creates an empty array in order to be looped over
   * inside templates. If length is not numeric, the value is only printed once.
   *
   * ```hbs
   *   {{loop length=1 value=value}}
   * ```
   *
   * @param {Int} length, the desired length for the array
   * @function loop
   * @public
   */
  function loop(_ref) {
    let [length, value] = _ref;
    if ((0, _utils.typeOf)(length) !== 'number') {
      return [value];
    }
    return Array.apply(null, {
      length
    }).map((_, i) => i + 1);
  }
  var _default = _exports.default = (0, _helper.helper)(loop);
});