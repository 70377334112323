define("@additive-apps/utils/utils/fonts", ["exports", "@additive-apps/utils/utils/constants-fonts"], function (_exports, _constantsFonts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadFont = _exports.getFontOptions = void 0;
  /**
   * loads a font into the dom
   *
   * @function loadFont
   * @param {String} fontFamily, the name of the fontface
   * @param {String} url, the url to load from
   */
  const loadFont = (fontFamily, url) => {
    // check for properties and dom features, if a font is called Barlow it is not loaded to avoid
    // interfering with the font of the application
    if (!fontFamily || !url || !document.fonts || fontFamily === 'Barlow') return;
    try {
      const newFont = new FontFace(fontFamily, `url(${url})`);
      return newFont.load().then(loadedFont => document.fonts.add(loadedFont));
    } catch (e) {
      // ignore errors
      return;
    }
  };

  /**
   * Util that extracts all custom font variants from a given font-object,
   * merges them with all available serif and sans-serif fonts, with their fallbacks
   * and transforms them into an array which are supported by our select-component
   *
   *
   * The input param should always have the correct format as defined by the api.
   *
   * ```js
   * fonts: {
   *  key: {
   *    light: {
   *      fontFamily: 'dummy font light',
   *      ...
   *    }
   *    ...
   *  }
   * }
   * ```
   * Available font variants are currently `light`, `normal` and `bold`
   * @function getFontOptions
   * @param {Object} fonts
   * @return {Array} fontOptions
   */
  _exports.loadFont = loadFont;
  const getFontOptions = fonts => {
    const customFonts = [];
    Object.keys(fonts || {}).forEach(key => {
      _constantsFonts.FONT_TYPES.forEach(type => {
        if (fonts[key] && fonts[key][type]) {
          const isFirstOfCustom = customFonts.length === 0;
          customFonts.push({
            // the value displayed in the select is the name of the font family
            name: fonts[key][type].fontFamily,
            // the internal value is the key.type. e.g.: `primary.normal`
            value: `${key}.${type}`,
            url: fonts[key][type].url,
            serif: fonts[key].serif,
            isCustom: true,
            isFirstOfCustom
          });
        }
      });
    });
    const fontOptions = [...customFonts, {
      name: 'Standard',
      value: _constantsFonts.sansSerifFontsFallbacks,
      serif: false,
      isFirstOfGroup: true
    }, ..._constantsFonts.sansSerifFonts, {
      name: 'Standard',
      value: _constantsFonts.serifFontsFallbacks,
      serif: true,
      isFirstOfGroup: true
    }, ..._constantsFonts.serifFonts];
    return fontOptions;
  };
  _exports.getFontOptions = getFontOptions;
});