define("ember-svg-jar/inlined/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11 6.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.926 8.201a.5.5 0 00-.854 0l-3.12 5.125-1.03-1.648a.5.5 0 00-.847 0l-4.098 6.557A.5.5 0 004.4 19h15.208a.5.5 0 00.427-.76L13.926 8.2zm-.427 2.184L9.168 17.5h8.662l-4.331-7.115z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});