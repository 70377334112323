define("ember-svg-jar/inlined/modal-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 4.5a.5.5 0 00-.5-.5h-4.406a.5.5 0 00-.5.5V5a.5.5 0 00.5.5h2.346l-4.084 4.084a.5.5 0 000 .707l.354.353a.5.5 0 00.707 0l4.084-4.083v2.346a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5V4.5z\"/><path d=\"M8.5 16a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V5a.5.5 0 01-.5.5h-2v9h9v-2a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-11z\"/><path d=\"M5 8a.5.5 0 01.5.5v10h10a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H5z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});