define("ember-svg-jar/inlined/ascending-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.493 8.447l-7.68 7.68a.5.5 0 000 .707l.353.353a.5.5 0 00.707 0l7.68-7.68v6.003a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5V7.447a.5.5 0 00-.5-.5H8.49a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h6.003z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});