define("ember-svg-jar/inlined/no-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.298 3.458C13.432 6.658 15 9.336 15 11.491c0 .54-.086 1.06-.244 1.548L7.57 5.854a30.59 30.59 0 012.131-2.396l.006-.006a.417.417 0 01.59.006zM6.859 6.807l7.363 7.363v.001l1.14 1.141a.417.417 0 010 .59l-.294.294a.417.417 0 01-.59 0l-1.057-1.058A5 5 0 015 11.49c-.001-1.073.388-2.276 1.165-3.608L4.46 6.18a.417.417 0 010-.59l.295-.294a.417.417 0 01.589 0l1.513 1.513v-.001z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});