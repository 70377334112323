define("ember-svg-jar/inlined/arrow-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.5 7a.5.5 0 01.5.5v3.793a.5.5 0 01-.854.353L17.78 10.28l-4.427 4.427a.5.5 0 01-.707 0L10 12.061l-5.116 5.116a.5.5 0 01-.707 0l-.354-.354a.5.5 0 010-.707l5.823-5.823a.5.5 0 01.707 0L13 12.939l3.72-3.719-1.366-1.366A.5.5 0 0115.707 7H19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});