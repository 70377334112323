define("ember-svg-jar/inlined/success-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 20a8 8 0 100-16 8 8 0 000 16zm4.18-10.57l-.354-.354a.5.5 0 00-.707 0l-4.116 4.116-2.116-2.116a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l2.824 2.823a.5.5 0 00.707 0l4.823-4.823a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});