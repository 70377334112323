define("ember-svg-jar/inlined/reload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.793 5a.5.5 0 01.353.854l-1.014 1.014A7.968 7.968 0 0120 12.008a8 8 0 01-7.476 7.982.504.504 0 01-.524-.506v-.499c0-.268.212-.476.479-.496a6.499 6.499 0 004.587-10.555l-1.212 1.212A.5.5 0 0115 8.793V5.5a.5.5 0 01.5-.5h3.293zm-7.317-.999a.504.504 0 01.524.506v.499a.503.503 0 01-.479.496 6.498 6.498 0 00-6.02 6.482c0 1.513.518 2.94 1.438 4.08l1.207-1.21a.5.5 0 01.854.353V18.5a.5.5 0 01-.5.5H5.207a.5.5 0 01-.353-.854l1.02-1.017A7.968 7.968 0 014 11.984a8 8 0 017.476-7.983z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});