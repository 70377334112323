define("@additive-apps/ui/templates/components/ui-table/head-cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1tvv82uR",
    "block": "[[[10,1],[14,0,\"dif items-center\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\\n\"],[41,[30,0,[\"column\",\"description\"]],[[[1,\"    \"],[8,[39,2],[[16,\"data-test-column-description-popover\",true]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"trigger\"]],[[24,0,\"ph05\"]],[[\"@tagName\"],[\"div\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[\"info\"],[[\"width\",\"height\"],[20,20]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"column\",\"description\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"_isSorted\"]],[[[1,\"    \"],[1,[28,[35,3],[\"drop-down\"],[[\"class\"],[[52,[30,0,[\"_isAscSorted\"]],\"ascending\",\"descending\"]]]]],[1,\"\\n\"]],[]],null],[13]],[\"Pop\",\"&default\"],false,[\"yield\",\"if\",\"ui-popover\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-table/head-cell.hbs",
    "isStrictMode": false
  });
});