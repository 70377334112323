define("ember-svg-jar/inlined/person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.538 19H5.461a.5.5 0 01-.499-.468C4.636 13.511 6.982 11 12 11c5.018 0 7.364 2.51 7.037 7.532a.5.5 0 01-.499.468zM12 12.5c-3.825 0-5.535 1.51-5.568 5h11.136c-.033-3.49-1.743-5-5.568-5zm0-2.5a3 3 0 110-6 3 3 0 010 6zm0-1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});