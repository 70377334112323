define("@additive-apps/ui/utils/sort-util", ["exports", "@additive-apps/ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toggleSortDirection = _exports.sortRows = _exports.encodeSortString = _exports.decodeSortString = void 0;
  /**
   * toggle the sort direction
   *  - from no sort to ascending
   *  - from ascending to descending
   *  - from descending to no sort
   *
   * @function toggleSortDirection
   * @param {String} direction
   * @return {String}
   */
  const toggleSortDirection = direction => {
    switch (direction) {
      case _constants.SORT_DIRECTIONS.ASCENDING:
        return _constants.SORT_DIRECTIONS.DESCENDING;
      case _constants.SORT_DIRECTIONS.DESCENDING:
        return null;
      default:
        return _constants.SORT_DIRECTIONS.ASCENDING;
    }
  };

  /**
   * sorts the rows by a given column and direction
   *
   * @function sortRows
   * @param {Array} rows
   * @param {Object} column
   * @param {String} direction
   * @return {Array}
   */
  _exports.toggleSortDirection = toggleSortDirection;
  const sortRows = (rows, column, direction) => {
    const compare = column.compare || _compare;
    return rows.sort((a, b) => {
      const valueA = a[column.key].value || a[column.key];
      const valueB = b[column.key].value || b[column.key];
      if (direction === _constants.SORT_DIRECTIONS.ASCENDING) {
        return compare(valueA, valueB, column.sort);
      } else if (direction === _constants.SORT_DIRECTIONS.DESCENDING) {
        return compare(valueB, valueA, column.sort);
      }
      return rows;
    });
  };

  /**
   * compares two numbers or string
   *
   * @function _compare
   * @param {Any} a
   * @param {Any} b
   * @param {String} type
   * @return {Number}
   * @private
   */
  _exports.sortRows = sortRows;
  const _compare = (a, b, type) => {
    if (type === 'String') {
      return a.localeCompare(b);
    } else if (type === 'Number') {
      return parseInt(a) - parseInt(b);
    } else if (type === 'Date') {
      return new Date(a) - new Date(b);
    } else {
      return a - b;
    }
  };

  /**
   * encodes a string with the ascending minus
   *
   * @function econdeSortString
   * @param {String} key
   * @param {String} direction
   * @return {String} encodedKey
   */
  const encodeSortString = (key, direction) => {
    if (direction === _constants.SORT_DIRECTIONS.DESCENDING) {
      return `-${key}`;
    }
    return key;
  };

  /**
   * decodes a string with its direction
   *
   * @function econdeSortString
   * @param {String} key
   * @return {Object}
   */
  _exports.encodeSortString = encodeSortString;
  const decodeSortString = key => {
    if (key.startsWith('-')) {
      return {
        key: key.replace('-', ''),
        direction: _constants.SORT_DIRECTIONS.DESCENDING
      };
    }
    return {
      key,
      direction: _constants.SORT_DIRECTIONS.ASCENDING
    };
  };
  _exports.decodeSortString = decodeSortString;
});