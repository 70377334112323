define("ember-svg-jar/inlined/empty-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#8f9ea4\" stroke-width=\"1.5\"><path d=\"M119.084 87.75h-.02a.25.25 0 00-.23.27l7.84 97c.01.13.118.23.248.23h122.156c.13 0 .238-.1.249-.23l7.838-97V88a.25.25 0 00-.25-.25z\" stroke-opacity=\".4\"/><g fill=\"#f2f2f2\"><path d=\"M145 79.75a.25.25 0 00-.25.25v20c0 .138.112.25.25.25h49.632a.25.25 0 00.239-.326l-6.364-20a.25.25 0 00-.238-.174z\" stroke-opacity=\".4\"/><path d=\"M241.084 95.75h-73.631l-7.945-24.826a.25.25 0 00-.238-.174h-48.216a.25.25 0 00-.232.267l7.862 114a.25.25 0 00.25.233h129.974a.25.25 0 00.25-.272l-7.825-89a.25.25 0 00-.249-.228z\"/></g></g>",
    "attrs": {
      "height": "256",
      "viewBox": "0 0 368 256",
      "width": "368",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});