define("ember-svg-jar/inlined/grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.487 16.986C12.843 18.274 12.23 18.5 12 18.5c-.23 0-.843-.226-1.487-1.514a8.952 8.952 0 01-.685-1.972 25.105 25.105 0 014.363-.086 9.04 9.04 0 01-.704 2.058zm.941-3.546a26.564 26.564 0 00-4.847.092 14.322 14.322 0 010-3.064 26.562 26.562 0 004.848.091 14.34 14.34 0 010 2.882zm1.265 1.636c-.227 1.09-.571 2.06-1.002 2.842a6.527 6.527 0 002.85-2.518c-.54-.12-1.159-.233-1.848-.324zm2.49-1.068c-.646-.153-1.404-.3-2.262-.415a15.847 15.847 0 000-3.186 22.932 22.932 0 002.263-.415c.205.632.316 1.307.316 2.008 0 .7-.11 1.376-.316 2.008zM17.542 8.6a6.527 6.527 0 00-2.85-2.518c.43.783.775 1.751 1.003 2.842.688-.09 1.306-.204 1.847-.324zm-3.35.473a9.048 9.048 0 00-.704-2.059C12.843 5.726 12.23 5.5 12 5.5c-.23 0-.843.226-1.487 1.514a8.958 8.958 0 00-.685 1.973 25.123 25.123 0 004.363.086zm-5.853-.298c.227-1.03.56-1.946.97-2.693a6.527 6.527 0 00-2.713 2.307c.489.132 1.072.267 1.743.386zM5.9 9.752c-.258.7-.399 1.458-.399 2.248 0 .79.141 1.548.4 2.248a21.23 21.23 0 012.195-.503 15.805 15.805 0 010-3.49A21.228 21.228 0 015.9 9.752zm2.44 5.473c-.672.119-1.255.254-1.744.386.67 1 1.609 1.804 2.714 2.307-.41-.747-.743-1.663-.97-2.693zM20 12a8 8 0 10-16 0 8 8 0 0016 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});