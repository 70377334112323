define("@additive-apps/ui/components/ui-metric-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/string", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _string, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-metric-item {{@class}} {{@classNames}}" size={{this.size}} ...attributes>
    <div class="font-xl primary">
      {{concat this.intro this.title}}
      {{#if this.appendix}}
        <span class="font-lg secondary">
          {{this.appendix}}
        </span>
      {{else}}
        {{yield}}
      {{/if}}
    </div>
    {{#if this.description}}
      <div class="font-sm secondary flex items-center pt05">
        <span>
          {{this.description}}
        </span>
        {{#if this._descriptionHelpText}}
          <UiPopover as |pop|>
            <pop.trigger>
              {{svg-jar "help" class="ml05" width="20" height="20"}}
            </pop.trigger>
            <pop.content>
              {{this._descriptionHelpText}}
            </pop.content>
          </UiPopover>
        {{/if}}
        {{#if this.hasInfoButton}}
          {{svg-jar "info" class="ml05" width="20" height="20"}}
        {{/if}}
      </div>
    {{/if}}
    {{#if this._hasCompareValue}}
      <UiComparison
        @class="pt2"
        @value={{this._compare}}
        @placeholder={{this.comparePlaceholder}}
        @comparePeriod={{this.comparePeriod}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "pP4YqW/h",
    "block": "[[[11,0],[16,0,[29,[\"ui-metric-item \",[30,1],\" \",[30,2]]]],[16,\"size\",[30,0,[\"size\"]]],[17,3],[12],[1,\"\\n  \"],[10,0],[14,0,\"font-xl primary\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,0,[\"intro\"]],[30,0,[\"title\"]]],null]],[1,\"\\n\"],[41,[30,0,[\"appendix\"]],[[[1,\"      \"],[10,1],[14,0,\"font-lg secondary\"],[12],[1,\"\\n        \"],[1,[30,0,[\"appendix\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,5,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"description\"]],[[[1,\"    \"],[10,0],[14,0,\"font-sm secondary flex items-center pt05\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[30,0,[\"description\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"_descriptionHelpText\"]],[[[1,\"        \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,4,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,4],[\"help\"],[[\"class\",\"width\",\"height\"],[\"ml05\",\"20\",\"20\"]]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n          \"],[8,[30,4,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"_descriptionHelpText\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[4]]]]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasInfoButton\"]],[[[1,\"        \"],[1,[28,[35,4],[\"info\"],[[\"class\",\"width\",\"height\"],[\"ml05\",\"20\",\"20\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"_hasCompareValue\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@class\",\"@value\",\"@placeholder\",\"@comparePeriod\"],[\"pt2\",[30,0,[\"_compare\"]],[30,0,[\"comparePlaceholder\"]],[30,0,[\"comparePeriod\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@class\",\"@classNames\",\"&attrs\",\"pop\",\"&default\"],false,[\"concat\",\"if\",\"yield\",\"ui-popover\",\"svg-jar\",\"ui-comparison\"]]",
    "moduleName": "@additive-apps/ui/components/ui-metric-item.hbs",
    "isStrictMode": false
  });
  /**
   * The component is used primarily to display metrics in dashboards and reports.
   * It can be used to display a simple value with a description or in most cases,
   * with a comparison of the data to an other period.
   *
   * ```hbs
   * <UiMetricItem
   *  @title="Metric Value"
   *  @description="An important metric"
   *  @compare="compare-value"
   *  @comparePlaceholder="Not enough data"
   *  @comparePeriod="last year"
   * />
   *  ```
   *
   * @class ui-metric-item
   */
  let UiMetricItem = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.number | _propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.number | _propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec7 = (0, _emberArgTypes.arg)(_propTypes.string), _dec8 = (0, _emberArgTypes.arg)(_propTypes.number | _propTypes.string), _dec9 = (0, _emberArgTypes.arg)(_propTypes.string), _dec10 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class UiMetricItem extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The size of the component
       *
       * @argument size
       * @type {String}
       * @default 'sm'
       */
      _initializerDefineProperty(this, "size", _descriptor, this);
      /**
       * The items title
       *
       * @argument title
       * @type {Number | String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor2, this);
      /**
       * The items intro
       *
       * @argument intro
       * @type {Number | String}
       * @default null
       */
      _initializerDefineProperty(this, "intro", _descriptor3, this);
      /**
       * The items appendix
       *
       * @argument appendix
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "appendix", _descriptor4, this);
      /**
       * The items description
       *
       * @argument description
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "description", _descriptor5, this);
      /**
       * Whether the item has an info button
       *
       * @argument hasInfoButton
       * @type {Boolean}
       * @default null
       */
      _initializerDefineProperty(this, "hasInfoButton", _descriptor6, this);
      /**
       * The items help text for the description
       *
       * @argument descriptionHelpText
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "descriptionHelpText", _descriptor7, this);
      /**
       * The items compare-value, most of the time it'll be a percentual comparison
       * on a certain period of time.
       *
       * @argument compare
       * @type {Number | String}
       * @default null
       */
      _initializerDefineProperty(this, "compare", _descriptor8, this);
      /**
       * The compare-values placeholder that gets displayed if
       * the compare-value is equal zero.
       *
       * @argument comparePlaceholder
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "comparePlaceholder", _descriptor9, this);
      /**
       * The compare-values period
       *
       * @argument comparePeriod
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "comparePeriod", _descriptor10, this);
    }
    /**
     * Whether compare property is not empty
     *
     * @computed _hasCompareValue
     * @type {Boolean}
     */
    get _hasCompareValue() {
      return this.compare !== null;
    }
    get _compare() {
      const compareNumber = Number(this.compare);
      return isNaN(compareNumber) ? 0 : compareNumber;
    }
    get _descriptionHelpText() {
      return this.descriptionHelpText && (0, _string.htmlSafe)(this.descriptionHelpText);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "size", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'sm';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intro", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "appendix", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasInfoButton", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "descriptionHelpText", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "compare", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "comparePlaceholder", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "comparePeriod", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiMetricItem);
});