define("ember-svg-jar/inlined/target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.25 3a.5.5 0 01.5.5v1.54a7.003 7.003 0 016.21 6.21h1.54a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-1.54a7.003 7.003 0 01-6.21 6.21v1.54a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5v-1.54a7.003 7.003 0 01-6.21-6.21H3.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h1.54a7.003 7.003 0 016.21-6.21V3.5a.5.5 0 01.5-.5h.5zM12 6.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm0 4a1.5 1.5 0 110 3 1.5 1.5 0 010-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});