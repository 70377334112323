define("ember-svg-jar/inlined/at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.62 17.487a5.49 5.49 0 002.8-.546.504.504 0 01.662.179l.26.43a.497.497 0 01-.186.7 6.978 6.978 0 01-3.12.75H12a7.104 7.104 0 01-1.159-.093v-.003A7.002 7.002 0 0112 5a7 7 0 017 7c0 1.75-1.045 3-2.75 3-.87 0-1.57-.386-2.044-.966A3 3 0 1115 12c0 .864.539 1.5 1.25 1.5.8 0 1.25-.503 1.25-1.5a5.5 5.5 0 10-5.88 5.487zM12 13.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});