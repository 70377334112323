define("@additive-apps/utils/helpers/includes", ["exports", "@ember/component/helper", "@ember/array"], function (_exports, _helper, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Helper providing a way to test if an array includes an item of any type.
   *
   * ```hbs
   *   {{if (includes array needle) 'then' 'else'}}
   * ```
   *
   * @param {Array} params[0], the array
   * @param {Any} param[1], the item
   * @function includes
   */
  class _default extends _helper.default {
    compute(_ref) {
      let [array, item] = _ref;
      if (!(0, _array.isArray)(array)) {
        return false;
      }
      const emberArray = (0, _array.A)(array);
      return emberArray.includes(item);
    }
  }
  _exports.default = _default;
});