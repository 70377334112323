define("ember-svg-jar/inlined/diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.793 5a.5.5 0 01.353.146l4.519 4.519a.5.5 0 01.018.688l-8.311 9.234a.5.5 0 01-.744 0l-8.31-9.234a.5.5 0 01.017-.688l4.519-4.519A.5.5 0 018.207 5h7.586zm-2.186 6h-3.215L12 16.053 13.607 11zm4.475 0h-2.9l-1.587 4.985L18.082 11zm-9.264 0h-2.9l4.486 4.984L8.818 11zm.907-4.5H8.621l-3.001 3h3.055l1.05-3zm2.96 0h-1.37l-1.051 3h3.471l-1.05-3zm2.694 0h-1.104l1.05 3h3.054l-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});