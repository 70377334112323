define("ember-svg-jar/inlined/security", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 3.2c.14 0 .28.053.392.157 1.99 1.876 3.981 2.886 5.972 3.03.282.02.52.231.549.511.568 5.738-1.64 10.088-6.622 13.05a.575.575 0 01-.584 0c-4.982-2.962-7.189-7.313-6.62-13.05a.583.583 0 01.548-.512c1.99-.143 3.982-1.153 5.973-3.028A.564.564 0 0112 3.2zm0 1.822c-1.78 1.525-3.608 2.46-5.477 2.768-.251 4.6 1.544 8.066 5.476 10.577 3.937-2.515 5.73-5.982 5.479-10.577-1.872-.309-3.7-1.243-5.478-2.768zm2.823 4.301l.354.354a.5.5 0 010 .707l-3.323 3.323a.5.5 0 01-.708 0l-1.823-1.823a.5.5 0 010-.707l.354-.354a.5.5 0 01.707 0L11.5 11.94l2.616-2.616a.5.5 0 01.707 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor"
    }
  };
});